import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

import EventItem from "./EventItem";
import { CELL_BORDER_RADIUS } from "../../constants";
import { filterDataBySlotType, sortArrayByStartDateTime } from "../../helpers/generals";

const Events = ({ date, resourceParent, eventsData, type }) => {
  const filterEventForEachDay = filterDataBySlotType({
    eventsData,
    date,
  });

  const sortedArray = sortArrayByStartDateTime(filterEventForEachDay);
  return (
    <>
      {!filterEventForEachDay?.length ? (
        <Box
          className="cellPlusIcon"
          elevation={6}
          sx={{
            width: "0%",
            height: "0%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0,
            transition: "ease-out 0.2s",
            background: "none",
            border: 2,
            borderColor: grey[400],
            borderRadius: CELL_BORDER_RADIUS,
          }}
        >
          <AddCircleOutlineIcon sx={{ color: (theme) => theme.palette.grey[500] }} />
        </Box>
      ) : (
        <Stack sx={{ width: "100%", height: "100%" }} spacing={1}>
          {sortedArray.map((event, i) => {
            return <EventItem event={event} resourceParent={resourceParent} index={i} key={event?.event_id || i * Math.random()} type={type} />;
          })}
        </Stack>
      )}
    </>
  );
};

export default Events;
