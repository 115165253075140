import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <Container
      maxWidth="md"
      sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ textAlign: "center", position: "relative" }}>
        <Box sx={{ height: { sm: "240px", xs: "150px" }, textAlign: "center" }}>
          <Typography
            variant="h1"
            sx={{
              background: "#121FCF",
              background: "linear-gradient(to right, #121FCF 0%, #CF1512 100%);",
              fontSize: { sm: "12rem", xs: "8rem" },
              textAlign: "center",
              backgroundClip: "text",
              textFillColor: "transparent",
              lineHeight: { sm: "240px", xs: "150px" },
            }}>
            Oops!
          </Typography>
        </Box>
        <Typography variant="h2">404 - Page not found</Typography>
        <Typography variant="subtitle2">
          The page you are looking for might have been removed had its name changed or is
          temporarily unavailable.
        </Typography>
        <Box component={Link} to={"/"}>
          <Button
            variant="contained"
            size="large"
            sx={{ mt: 2, borderRadius: "100vw" }}>
            Go To Homepage
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Error404;
