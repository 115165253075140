import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Fab, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import AddIcon from "@mui/icons-material/Add";
import { PrimaryLayout, SaloonLayout } from "../../../layouts";
import { ContainerWithTopHeading, InputField, Header, LeftDrawer } from "@components";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import apiManager from "../../../services/api-manger";
import { setLoader } from "src/store/reducer";
import { useDispatch } from "react-redux";
import { KeyboardBackspace } from "@mui/icons-material";

const AddProduct = () => {
  const { saloonID, productID } = useParams();
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    saloonId: saloonID,
    gender: "neutral",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    dispatch(setLoader(true));
    setIsLoading(true);
    try {
      const method = productID ? "put" : "post";
      const path = method === "put" ? `product/update/${productID}` : "product/create";
      const { data } = await apiManager({ method, path, params: formData });
      enqueueSnackbar(data?.message, { variant: "success" });
      navigate(`/saloon/${saloonID}/products`);
    } catch (error) {
      setFormErrors(error?.response?.data?.data);
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    } finally {
      dispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  const getSingleProduct = async () => {
    setIsLoading(true);
    try {
      const { data } = await apiManager({ method: "get", path: `product/single/${productID}` });
      enqueueSnackbar(data?.message, { variant: "success" });
      setFormData(data?.data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (productID) {
      getSingleProduct();
    }
  }, [productID]);

  return (
    <>
      <SaloonLayout
        navToLocation={(id) => {
          navigate(`/saloon/${id}/products`);
        }}
      >
        <Header headerTitle="Add Products" headerTitleIcon={<ContentPasteIcon />} />
        <PrimaryLayout>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Stack sx={{ position: 'relative' }}>
                  <Fab
                    size="small"
                    color="primary"
                    sx={{ position: 'absolute', left: 0, top: -10 }}
                    onClick={() => navigate(-1)}
                  >
                    <KeyboardBackspace />
                  </Fab>
                  <Typography
                    variant="h4"
                    align="center"
                    children={`${productID ? 'Update' : 'Add'} Product`}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <ContainerWithTopHeading title="Product Details">
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <InputField
                        label="Brand name"
                        required
                        name="brandName"
                        value={formData.brandName}
                        error={formErrors?.brandName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <RadioGroup value={formData?.gender} row onChange={handleChange} name="gender">
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="neutral" control={<Radio />} label="Neutral" />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputField label="Product Name" required name="name" value={formData.name} error={formErrors?.name} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputField
                        label="Product Price"
                        type="number"
                        required
                        name="price"
                        value={formData.price}
                        error={formErrors?.price}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputField
                        required
                        label="Stock"
                        type="number"
                        name="stock"
                        value={formData.stock}
                        error={formErrors?.stock}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <InputField
                        required
                        label="Tax Rate"
                        type="number"
                        name="taxRate"
                        value={formData.taxRate}
                        error={formErrors?.taxRate}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        label="Add Description"
                        multiline
                        rows={5}
                        fullWidth
                        placeholder="Type the description here"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="submit">
                  {productID ? `Update Product` : `Add Product`}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </PrimaryLayout>
      </SaloonLayout>
    </>
  );
};

export default AddProduct;
