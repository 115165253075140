import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TableCell, TableRow, Typography } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CreateIcon from "@mui/icons-material/Create";
import { ContainerWithTopHeading, ImagePicker, InputField, Header, TableContainer, PasswordInputField, SelectBox, CenterSubmitBtn, SevenDayTimeSelector } from "@components";
import { useNavigate, useParams } from "react-router-dom";
import { MainGridRowSpacing, inputGridSpacing, weekTimings } from "../../../constant";
import { PrimaryLayout, EmployeeLayout } from "../../../layouts";
import apiManager from "../../../services/api-manger";
import { useSnackbar } from "notistack";
import { closeDrawer, setLoader } from "src/store/reducer";
import { useDispatch } from "react-redux";

const Employee = () => {
  const { employeeID, saloonID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [employee, setEmployee] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const dummyData = [];
  const totalPages = Math.ceil(dummyData.length / perPage);
  const [isLoading, setIsLoading] = useState(false);
  const thLabels = ["Name", "Fee Charged", "Time Spent", "Action"];
  const initialState = {
    saloonId: saloonID,
    timings: weekTimings,
  };
  const [showBtn, setShowBtn] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setShowBtn(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(setLoader(true));
    setFormErrors({});
    const method = employeeID ? "put" : "post";
    const path = method === "put" ? `employees/update/${employeeID}` : `employees/create`;
    try {
      const { data } = await apiManager({ method, path, params: formData });
      enqueueSnackbar(data?.message, { variant: "success" });
      navigate(`/saloon/${saloonID}/staff_members/${data?.data?._id}`);
      setShowBtn(false);
    } catch (error) {
      setFormErrors(error?.response?.data?.data);
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      setIsLoading(false);
    } finally {
      dispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  const getSingleEmployee = async () => {
    setIsLoading(true);
    dispatch(setLoader(true));
    dispatch(closeDrawer("right"));
    try {
      const { data } = await apiManager({ method: "get", path: `employees/get-single/${employeeID}` });
      // setFormData(data?.data);
      setFormData((prevData) => ({
        ...prevData,
        ...data?.data,
        timings: data?.data.timings.length ? data?.data.timings : initialState.timings,
      }));
      setEmployee(data?.data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    setShowBtn(false);
    if (employeeID) {
      getSingleEmployee();
    }
  }, [employeeID]);

  useEffect(() => {
    setShowBtn(true);
  }, [formData]);

  const renderRow = (item, index) => {
    return (
      <TableRow key={index}>
        <TableCell>Name</TableCell>
        <TableCell>Fee Charged</TableCell>
        <TableCell>Time Spent</TableCell>
        <TableCell>Action</TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <EmployeeLayout>
        <Header
          headerTitle={employee?.firstName && employee?.lastName ? `${employee?.firstName} ${employee?.lastName}` : "Add Employee"}
          headerTitleIcon={<PersonAddIcon />}
        />
        <PrimaryLayout>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid container spacing={MainGridRowSpacing}>
              {/* Logo and Social Accounts */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title={"Personal Information"} icon={<CreateIcon />} centerItems={false} RightBox={true}>
                  <Grid container sx={{ height: "100%" }} spacing={MainGridRowSpacing}>
                    <Grid item lg={3} xs={12} display="flex" justifyContent="center" alignItems="center">
                      <Stack sx={{ width: "100%" }}>
                        <Typography children="Upload Photo" variant="h6" sx={{ my: 2 }} />
                        <ImagePicker width="100%" />
                      </Stack>
                    </Grid>
                    <Grid item lg={9} xs={12} spacing={inputGridSpacing} container justifyContent="center">
                      <Grid item xs={6}>
                        <InputField
                          label="FIRST NAME"
                          fullWidth={true}
                          required
                          size="large"
                          type="text"
                          name={"firstName"}
                          value={formData.firstName}
                          error={formErrors?.firstName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputField
                          label="LAST NAME"
                          fullWidth={true}
                          required
                          size="large"
                          type="text"
                          name={"lastName"}
                          value={formData.lastName}
                          error={formErrors?.lastName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          label="PHONE NUMBER"
                          fullWidth={true}
                          required
                          size="large"
                          type="number"
                          name={"mobile"}
                          value={formData.mobile}
                          error={formErrors?.mobile}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          label="EMAIL ADDRESS"
                          fullWidth={true}
                          required
                          size="large"
                          type="email"
                          name={"email"}
                          value={formData.email}
                          error={formErrors?.email}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PasswordInputField
                          label="PASSWORD"
                          fullWidth={true}
                          size="large"
                          name={"password"}
                          value={formData.password}
                          error={formErrors?.password}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectBox
                          label="ROLE"
                          size="large"
                          required
                          name="role"
                          value={formData.role}
                          error={formErrors?.role}
                          onChange={handleChange}
                          items={[
                            { label: "Employee", value: "employee" },
                            { label: "Owner", value: "owner" },
                            { label: "Receptionist", value: "receptionist" },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>

              {/* Salon Operating Timing */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title="Salon Operating Timing" icon={<ScheduleIcon />} type="subHeading">
                  <SevenDayTimeSelector
                    data={formData.timings}
                    requestDisableButton={setDisableBtn}
                    // onChange={(value) => {
                    //   console.log("🚀 ~ file: Employee.js:237 ~ Employee ~ value:", value)
                    //   setFormData((prev) => ({ ...prev, timings: value }));
                    //   setShowBtn(true);
                    //   setDisableBtn(false);
                    // }}
                    setFormData={setFormData}
                  />
                </ContainerWithTopHeading>
              </Grid>

              {/* Logo and Social Accounts */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title={"Clients"} icon={<CreateIcon />}>
                  <TableContainer
                    defaultColor
                    thContent={thLabels.map((value, i) => (
                      <TableCell key={i} children={value} />
                    ))}
                    spanTd={thLabels.length}
                    page={page}
                    totalPages={totalPages}
                    callBack={setPage}
                    isContent={records?.length}
                  >
                    {records?.map((item, i) => {
                      return <Fragment key={i} children={renderRow(item, i)} />;
                    })}
                  </TableContainer>
                </ContainerWithTopHeading>
              </Grid>
            </Grid>
            {showBtn && (
              <CenterSubmitBtn variant="contained" type="submit" key={employeeID} isLoading={isLoading}>
                {employeeID ? "Update" : "Create"}
              </CenterSubmitBtn>
            )}
          </Box>
        </PrimaryLayout>
      </EmployeeLayout>
    </>
  );
};

export default Employee;
