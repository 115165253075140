export const EVENTS = [
  {
    event_id: 5,
    title: "Event 5",
    start: new Date(new Date(new Date(new Date().setHours(10)).setMinutes(30)).setDate(new Date().getDate() - 2)),
    end: new Date(new Date(new Date(new Date().setHours(14)).setMinutes(0)).setDate(new Date().getDate() - 2)),
    resourceId: "2",
    editable: true,
  },
  {
    event_id: 6,
    title: "Event 4",
    start: new Date(new Date(new Date(new Date().setHours(10)).setMinutes(30)).setDate(new Date().getDate() - 2)),
    end: new Date(new Date(new Date(new Date().setHours(14)).setMinutes(0)).setDate(new Date().getDate() - 2)),
    resourceId: "2",
    editable: true,
  },
];

export const employees = [
  {
    _id: "1",
    name: "John Doe",
    img: "https://cdn3.vectorstock.com/i/1000x1000/54/17/person-gray-photo-placeholder-man-vector-24005417.jpg",
    color: "#009688",
    unAvailable: ["2024-02-19T15:35"],
  },
  {
    _id: "2",
    name: "Jane Smith",
    img: "https://cdn3.vectorstock.com/i/1000x1000/54/17/person-gray-photo-placeholder-man-vector-24005417.jpg",
    color: "#673ab7",
  },
  {
    _id: "3",
    name: "Alex Johnson",
    img: "https://placehold.co/600x400",
    color: "#3f51b5",
  },
  {
    _id: "4",
    name: "Emily Brown",
    img: "https://placehold.co/600x400",
    color: "#795548",
  },
];

export const generateRandomEvents = (total = 300, employees) => {
  const events = [];
  const numEmployees = employees.length;

  for (let i = 0; i < total; i++) {
    const day = Math.round(i % 15);
    const randomEmployee = employees[Math.floor(Math.random() * numEmployees)];

    events.push({
      event_id: Math.random(),
      title: "Event " + (i + 1),
      start: new Date(new Date(new Date(new Date().setHours(10)).setMinutes(30)).setDate(new Date().getDate() + day)),
      end: new Date(new Date(new Date(new Date().setHours(14)).setMinutes(0)).setDate(new Date().getDate() + day)),
      resourceId: randomEmployee._id,
      color: randomEmployee.color,
    });
  }

  return events;
};
