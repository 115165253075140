import React, { useContext, useEffect, useState } from "react";
import { LeftDrawer } from "@components";
import { useSnackbar } from "notistack";
import apiManager from "../../services/api-manger";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { SaloonContext } from "src/context/SaloonContext/Context";
import { setLoader } from "src/store/reducer";

const SaloonLayout = ({ children, navToLocation = (id) => {} }) => {
  let { state, dispatch } = useContext(SaloonContext);
  const { user } = useSelector((state) => state.appReducer);
  const { saloonID } = useParams();
  const navigate = useNavigate();
  const loaderDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getAllRecords = async () => {
    try {
      if (!state?.saloonData?.length) {
        setIsLoading(true);
        loaderDispatch(setLoader(true));
      }
      let apiResponse = await apiManager({ method: "get", path: `saloons/all?owner_id=${user?._id}` });
      console.log("🚀 ~ getAllRecords ~ apiResponse:", apiResponse.data.data)
      dispatch({ type: "SET_DATA", payload: apiResponse.data.data, });
    } catch (error) {
      console.log("🚀 ~ file: SaloonLayout.js:32 ~ getAllRecords ~ error:", error);
      // enqueueSnackbar(error?.response?.data?.data, { variant: "error" });
    } finally {
      loaderDispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllRecords();
  }, []);

  const renderSaloonList = () => {
    if (isLoading) {
      return <SkeletonList count={5} />;
    }
    if (!state?.saloonData?.results?.length) {
      return <Typography children="No record found" textAlign="center" variant="h5" />;
    }
    return (
      <List>
        {state?.saloonData?.results?.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => navToLocation(item._id)}
              // onClick={() => navigate(`/saloonProfile/${item._id}`)}
              selected={item._id === saloonID}
            >
              <ListItemAvatar sx={{ m: 0 }}>
                <Avatar src={item.imageUrl} alt="Remy Sharp" />
              </ListItemAvatar>
              <ListItemText primary={item.name} secondary={`Type Of Saloon ${item?.typeOfSaloon}`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <>
      <LeftDrawer
        routes={[]}
        flag={true}
        showLogo={true}
        showDetails={false}
        showDrawer={false}
        showButton={{ url: "/saloon/add", icon: <Add />, text: "Add Saloon" }}
        searchLabel={"Search Saloon"}
        drawerList={renderSaloonList()}
      />
      {children}
    </>
  );
};

export default SaloonLayout;

const SkeletonList = ({ count }) => {
  const skeletonItems = Array.from({ length: count }, (_, index) => (
    <ListItem key={index}>
      <ListItemAvatar>
        <Skeleton variant="circular" width={40} height={40} />
      </ListItemAvatar>
      <ListItemText primary={<Skeleton width={150} />} secondary={<Skeleton width={100} />} />
    </ListItem>
  ));

  return <List>{skeletonItems}</List>;
};
