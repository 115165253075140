import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputField } from "@components";
import { useDispatch } from "react-redux";
import apiManager from "../../../services/api-manger";
import { setLoader } from "../../../store/reducer";
import { useSnackbar } from "notistack";

export default function VerifyOtp() {

    const { userId } = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ otpKey: "", role: "owner", userId: userId });
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch(setLoader(true))
            setFormErrors({})
            const { data } = await apiManager({ method: 'post', path: 'verifyOtp', params: formData })
            navigate(`/resetPass/${data?.data?.otpIdentifier}`)
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
        } finally {
            dispatch(setLoader(false));
        }
    }


    return (
        <>
            <Typography component="h1" variant="h5" my={1} align="center">
                OTP Verification
            </Typography>
            <Box
                component="form"
                autoComplete="off"
                autoCapitalize="off"
                onSubmit={handleSubmit}
                sx={{ mt: 1, maxWidth: "500px" }}
            >
                <InputField
                    margin="normal"
                    label="Token"
                    required
                    placeholder="0000"
                    name="otpKey"
                    value={formData.otpKey}
                    error={formErrors?.otpKey}
                    onChange={handleInputChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    size="large"
                >
                    Submit
                </Button>
                <Button fullWidth component={Link} to="/login">
                    Go back to login
                </Button>
            </Box>
        </>
    );
};