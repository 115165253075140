import React from "react";
import { Avatar, Box, Container, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import SearchInput from "../SearchInput/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import { closeSalonLIstingModal } from "../../store/reducer";

const SalonListingModal = () => {
  const dispatch = useDispatch();
  const { openSalonLIstingModal } = useSelector((state) => state.appReducer);
  return (
    <Box>
      <ModalWrapper
        title={"Saloons"}
        dividers
        maxWidth="xs"
        contentBox={false}
        fullScreen={true}
        open={openSalonLIstingModal}
        onClose={() => dispatch(closeSalonLIstingModal())}
      >
        <Container>
          <SearchInput />
          <Box>
            <List disablePadding>
              {Array(12).fill("").map((_, i) => (
                <ListItem
                  disableGutters
                  key={i}>
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`Saloon ${i + 1}`}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary">
                            Ali Connors
                          </Typography>
                          {"9:00-10:00 PM"}
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      </ModalWrapper>
    </Box>
  );
};

export default SalonListingModal;
