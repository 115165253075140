import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { APP_REDUCER } from "../constant";
import apiManager from "../services/api-manger";
import { useSnackbar } from "notistack";
import { setLoader, setUser } from "../store/reducer";
import { SplashScreen } from "@components";

const ComponentWrapper = ({ item }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { element, isProtected = false, layout, role = [], fallBack = "/", showDrawer = true, context: ContextProvider } = item;
  const { isLogged, user } = useSelector((state) => state[APP_REDUCER]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const Layout = layout;

  // Effect to handle redirection based on authentication state and loading.
  useEffect(() => {
    if (!isLogged && !isLoading && isProtected) {
      // Redirect to the specified 'fallBack' route if not logged in and component is protected.
      navigate(fallBack);
    }
    if (isLogged && !isLoading && !isProtected) {
      // Redirect to the specified 'fallBack' route if logged in and component is not protected.
      navigate(fallBack);
    }
  }, [isLogged, isLoading, navigate]);

  // Uncomment and adapt role authorization logic if needed
  // const userRole = user?.role || '';
  // if (!role.includes("all") && !role.includes(userRole)) {
  //   alert("You are unauthorized.");
  //   return <></>;
  // }

  // Effect to initialize the component and fetch user data.
  useEffect(() => {
    async function init() {
      try {
        // Retrieve the authentication token from local storage.
        let token = localStorage.getItem(process.env.REACT_APP_TOKEN);
        // dispatch(setLoader(true));
        if (token) {
          // If a token exists, make an API call to fetch user data.
          let { data } = await apiManager({ method: "get", path: "me" });
          // Dispatch an action to update user data in the Redux store.
          dispatch(setUser(data?.data));
          // enqueueSnackbar(data?.message, { variant: "success" });
        }
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: "error" });
        if (error?.response?.status === 401) {
          enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
          localStorage.removeItem(process.env.REACT_APP_TOKEN);
        }
      } finally {
        // Set loading to false after a timeout of 3 seconds.
        setTimeout(() => {
          setIsLoading(false);
          // dispatch(setLoader(false));
        }, 3000);
      }
    }
    init();
  }, [dispatch, enqueueSnackbar]);

  // Render a loading screen if the component is still loading.
  if (isLoading) {
    return <SplashScreen />;
  }

  // Wrap the element with the context provider if it is provided.
  const componentWithProps = ContextProvider ? <ContextProvider>{element}</ContextProvider> : element;

  if (!Layout) {
    return componentWithProps;
  }

  if (isProtected) {
    return <Layout >{componentWithProps}</Layout>;
  }

  return <Layout >{componentWithProps}</Layout>;
};

export default ComponentWrapper;
