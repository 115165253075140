import React from "react";
import { AppBar, Avatar, Box, Button, Container, IconButton, Stack, Toolbar, Tooltip, useMediaQuery } from "@mui/material";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import { useDispatch } from "react-redux";
import HeadingWithIcon from "../HeadingWithIcon/HeadingWithIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutUser, setOpenDrawer, setSalonLIstingModal } from "../../store/reducer";
import { DRAWER_WIDTH, MOBILE_BREAK_POINT, headerHeight } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Header({ headerTitle, headerTitleIcon, showMoreButtons, openDrawerBtn = true, LogoutBtn }) {
  const matches = useMediaQuery(MOBILE_BREAK_POINT);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = async () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    dispatch(logoutUser());
  };

  return (
    <AppBar position="sticky" sx={headerStyles.appBar} elevation={0}>
      <Toolbar sx={headerStyles.toolbar}>
        <Container sx={{ maxWidth: "1400px !important" }}>
          <Box sx={headerStyles.flexContainer}>
            {/* {!matches && (
              <IconButton onClick={() => dispatch(setSalonLIstingModal())}>
                <ViewHeadlineIcon sx={headerStyles.icon} />
              </IconButton>
            )} */}
            <HeadingWithIcon title={headerTitle} icon={headerTitleIcon} type="heading" />
            <Box sx={headerStyles.flexContainer}>
              {showMoreButtons?.map((btn, index) => (
                <Button
                  key={index}
                  startIcon={btn?.button_startIcon}
                  endIcon={btn?.button_endIcon}
                  sx={{ color: "black" }}
                  onClick={btn?.onClick}
                >
                  {btn?.button_title}
                </Button>
              ))}
              {openDrawerBtn && (
                <IconButton onClick={() => dispatch(setOpenDrawer("right"))}>
                  <ViewHeadlineIcon sx={headerStyles.icon} />
                </IconButton>
              )}
              {LogoutBtn && (
                <>
                  <Tooltip title={"Profile"} arrow>
                    <IconButton children={<AccountCircleIcon sx={{ fontSize: 30 }} />} onClick={() => navigate("/profile")} />
                  </Tooltip>
                  <Tooltip title={"Logout"} arrow>
                    <IconButton children={<LogoutIcon />} onClick={logOut} />
                  </Tooltip>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

const headerStyles = {
  appBar: {
    position: "sticky",
    width: { md: `calc(100% - ${DRAWER_WIDTH}px)`, xs: "100%" },
    ml: { md: `${DRAWER_WIDTH}px` },
    bgcolor: "white",
    zIndex: (theme) => theme.zIndex.drawer + 1,
    boxShadow: { xs: "7px 4px 44px 0px rgba(0, 0, 0, 0.04)", lg: "none" },
  },
  toolbar: {
    height: headerHeight,
    p: "0px !important",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
