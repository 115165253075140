import { Add } from "@mui/icons-material";
import { Fab, IconButton, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";
import PropTypes from "prop-types";

import { MONTH_CELL_HEIGHT, TYPE_NORMAL } from "../../constants";
import { filterDataBySlotType, isToday, sortArrayByStartDateTime } from "../../helpers/generals";
import useStore from "../../hooks/useStore";
import Cell from "../common/Cell";
import CellWrapper from "../common/CellWrapper";
import EventCard from "../events/EventCard";

const MonthCell = ({ date, monthStart, events }) => {
  const { triggerDialog } = useStore();

  const filterEventForEachDay = filterDataBySlotType({
    eventsData: events,
    date: moment(date).format(),
  });
  const sortedArray = sortArrayByStartDateTime(filterEventForEachDay).filter((each) => each.resourceId && !each.available);

  return (
    <CellWrapper
      xs={12 / 7}
      p={0.5}
      styles={{
        minHeight: MONTH_CELL_HEIGHT,
        "& .addIcon": {
          opacity: 0,
        },
        ":hover": {
          "& .addIcon": {
            opacity: 1,
          },
        },
        background: moment(date).isSame(monthStart, "month") ? "" : grey[50],
      }}
    >
      <Stack height="100%">
        <Stack direction={"row"} justifyContent="space-between" alignItems="center">
          <Fab
            color={isToday(date) ? "primary" : "outlined"}
            size="small"
            sx={{
              boxShadow: "0",
              ...(!isToday(date) && {
                background: grey[100],
              }),
              width: "35px",
              height: "20px",
              zIndex: "1",
            }}
          >
            {moment(date).format("DD")}
          </Fab>
          <IconButton
            size="small"
            className={"addIcon"}
            onClick={() =>
              triggerDialog(true, {
                start: moment(date).format(),
                end: moment(date).format(),
              })
            }
          >
            <Add fontSize="inherit" />
          </IconButton>
        </Stack>

        <Cell
          droppableData={{
            id: date,
            data: {
              date,
              type: TYPE_NORMAL,
            },
          }}
        >
          <Stack sx={{ width: "100%" }} spacing={1} my={1}>
            {sortedArray?.map((eachEvent) => (
              <EventCard
                key={eachEvent?.event_id}
                draggableData={{
                  id: eachEvent?.event_id,
                  data: {
                    event: eachEvent,
                    resourceParent: eachEvent?.resourceId,
                  },
                }}
                event={eachEvent}
                onRequestAdd={() =>
                  triggerDialog(true, {
                    start: moment(date).format(),
                    end: moment(date).format(),
                  })
                }
              />
            ))}
          </Stack>
        </Cell>
      </Stack>
    </CellWrapper>
  );
};

MonthCell.propTypes = {
  date: PropTypes.string.isRequired,
  monthStart: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      event_id: PropTypes.string.isRequired,
      resourceId: PropTypes.string,
      available: PropTypes.bool,
    })
  ).isRequired,
};

export default MonthCell;
