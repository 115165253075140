import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Scheduler } from "src/lib";
import SchedulerContextProvider from "src/lib/components/SchedulerContextProvider/SchedulerContextProvider";
import { convertRawDataInToResourceData } from "src/lib/helpers/generals";
import apiManager from "src/services/api-manger";
import { setLoader } from "src/store/reducer";
import AppointmentCreationDialog from "./AppointmentCreationDialog";

const Calender = () => {
  const [employees, setEmployees] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [clickedCellData, setClickedCellData] = useState(null);

  const dispatch = useDispatch();
  const { saloonID } = useParams();

  const getEmployeesData = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await apiManager({ method: "get", path: `/employees/get-all/${saloonID}` });
      setEmployees(
        convertRawDataInToResourceData(data.data.results, {
          name: "firstName",
          id: "_id",
          color: "color",
        })
      );
    } catch (error) {
      console.log("ParlourCrud getData error:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleAppointmentCreate = async (eventData) => {
    try {
      dispatch(setLoader(true));
      let { data } = await apiManager({ method: "post", path: "appointment/create", params: eventData });
      setAppointments((prev) => [...prev, data?.data]);
      setClickedCellData(null);
    } catch (error) {
      console.log("🚀 ~ handleConfirm ~ error:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const eventUpdateHandler = async (eventData, resize) => {
    try {
      dispatch(setLoader(true));
      let { data } = await apiManager({ method: "put", path: `appointment/update/${eventData?._id}?resize=${resize}`, params: eventData });
      setAppointments((prev) => {
        const filterData = prev.filter((each) => each._id !== eventData._id);
        return [...filterData, data.data];
      });
      setClickedCellData(null);
    } catch (error) {
      console.log("🚀 ~ handleConfirm ~ error:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const eventDeleteHandler = async (eventData) => {
    try {
      dispatch(setLoader(true));
      let { data } = await apiManager({ method: "delete", path: `/appointment/delete/${eventData?._id}`, params: eventData });
      setAppointments((prev) => prev.filter((each) => each._id !== eventData._id));
      setClickedCellData(null);
    } catch (error) {
      console.log("🚀 ~ handleConfirm ~ error:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const getAppointments = async () => {
    dispatch(setLoader(true));
    try {
      const { data } = await apiManager({ method: "get", path: `appointment/get-all/${saloonID}` });
      setAppointments(data.data);
    } catch (error) {
      console.log("ParlourCrud getData error:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    getEmployeesData();
    getAppointments();
  }, []);

  return (
    <SchedulerContextProvider
      view="day"
      events={appointments}
      resources={employees}
      onEventDrop={(data) => {
        eventUpdateHandler(data);
      }}
      onEventUpdate={(data) => {
        eventUpdateHandler(data, true);
      }}
      views={["day"]}
      slots={["shiftsSlots"]}
      onEventClick={(data) => setClickedCellData({ ...data, edit: true })}
      onCellClick={(data) => {
        setClickedCellData(data);
      }}
    >
      <Scheduler />
      <AppointmentCreationDialog
        onCreate={(data) => {
          if (data?.edit) {
            eventUpdateHandler(data);
          } else {
            handleAppointmentCreate(data);
          }
        }}
        onRequestDelete={(data) => {
          eventDeleteHandler(data);
        }}
        data={clickedCellData}
        employees={employees}
        setData={() => setClickedCellData(null)}
      />
    </SchedulerContextProvider>
  );
};

export default Calender;
