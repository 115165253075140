import React, { useState } from "react";
import { MenuItem, Select, Box, InputBase, Popover, Paper, Button } from "@mui/material";
import { CirclePicker } from "react-color";
import { DoNotDisturbAlt, UnfoldMore } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";

const ColorPickerWithSelect = ({ value, onChange }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        color="secondary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={value ? <CircleIcon sx={{ color: value }} /> : <DoNotDisturbAlt />}
        endIcon={<UnfoldMore />}
      ></Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <CirclePicker
            color={value}
            onChange={(e) => {
              onChange(e);
              setAnchorEl(null);
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default ColorPickerWithSelect;
