import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, DialogContent, DialogTitle, Divider, Fab, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, Paper, Stack, Switch, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DoneIcon from "@mui/icons-material/Done";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GroupIcon from "@mui/icons-material/Group";
import { ClearOutlined, KeyboardBackspace } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { ColorPicker, InputField, SelectBox, Header, CenterSubmitBtn, ConfirmationModal } from "@components";
import { timeOptions } from "../../../constant";
import { PrimaryLayout, SaloonLayout, ServiceLayout } from "../../../layouts";
import apiManager from "../../../services/api-manger";
import NestedCheckboxes from "./NestedCheckboxes";
import { useSnackbar } from "notistack";
import { helper, rules } from "src/validations";
import { closeDrawer, setLoader } from "src/store/reducer";
import { LoadingButton } from "@mui/lab";
import { MenuContext } from "src/context/MenuContext/Context";
import moment from "moment";

const Service = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let context = useContext(MenuContext);
  const { enqueueSnackbar } = useSnackbar();
  const { serviceID, saloonID } = useParams();
  const [showBtn, setShowBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployee] = useState([]);
  const [packageInServiceData, setPackageInServiceData] = useState([]);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [removeVenueTreatments, setRemoveVenueTreatments] = useState({ status: false, index: null });
  const [formErrors, setFormErrors] = useState({});
  const initialVenueTreatmentObj = {
    price: 120,
    duration: 900,
    tempId: uuidv4(),
  };
  const [formData, setFormData] = useState({
    saloonId: saloonID,
    venueTreatments: [initialVenueTreatmentObj],
    addToFeatured: false,
    serviceGenderType: "neutral",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setShowBtn(true);
  };

  const getEmployees = async () => {
    try {
      const { data } = await apiManager({ method: "get", path: `/employees/get-all/${saloonID}?per_page=99999` });
      setEmployee(data.data?.results);
    } catch (error) {
      console.log("getEmployees ~ error:", error);
    };
  };

  const getServices = async () => {
    try {
      dispatch(closeDrawer("right"));
      dispatch(setLoader(true));
      const { data } = await apiManager({ method: "get", path: `service/single/${serviceID}` });
      setFormData(data.data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    } finally {
      dispatch(setLoader(false));
    }
  };

  // check package includes service.
  const checkIsPackageIncludesService = async (bool) => {
    if (!bool) { setIsLoading(true) };
    const path = `service/${serviceID}/check_package_includes_service${bool ? '?sub_service=true' : ""}`;
    try {
      const { data } = await apiManager({ method: "get", path });
      setPackageInServiceData(data?.data);
      setOpenDeleteModel(true);
    } catch (error) {
      enqueueSnackbar("some error occured", { variant: "error" });
    };
  };

  const handleDelete = async () => {
    setOpenDeleteModel(false);
    setRemoveVenueTreatments({ status: false });
    try {
      const { data } = await apiManager({ method: "delete", path: `service/delete/${serviceID}` });
      enqueueSnackbar(data?.message, { variant: "success" });
      navigate(`/saloon/${saloonID}/serviceListing`);
      context.getAllRecords();
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    const result = await helper(formData, rules.ServiceRules());
    if (result) {
      setFormErrors(result);
      dispatch(setLoader(false));
      return;
    }
    try {
      setIsLoading(true);
      dispatch(setLoader(true));
      const { data } = await apiManager({
        method: serviceID ? "put" : "post",
        path: serviceID ? `service/update/${serviceID}` : `service/create`,
        params: formData,
      });
      context.getAllRecords();
      enqueueSnackbar(data?.message, { variant: "success" });
      navigate(`/saloon/${saloonID}/service/${data?.data?._id}`);
      setShowBtn(false);
    } catch (error) {
      setFormErrors(error?.response?.data?.data);
    } finally {
      setIsLoading(false);
      dispatch(setLoader(false));
    }
  };

  const handleRemoveVenueTreatments = (index) => {
    if (serviceID) {
      setOpenDeleteModel(false);
      setRemoveVenueTreatments({ status: false });
    }
    setFormData((prevData) => {
      const serviceIndex = prevData.venueTreatments.findIndex((_, serviceIndex) => serviceIndex === index);
      if (serviceIndex !== -1) {
        const modifiedData = [...prevData.venueTreatments];
        modifiedData.splice(serviceIndex, 1);
        return { ...prevData, venueTreatments: modifiedData };
      }
      return prevData;
    });
  }

  const handleChangeForVenueTreatments = (id, event) => {
    const { name, value } = event.target;
    const newInputValues = formData.venueTreatments.map((input, index) => (index === id ? { ...input, [name]: value } : input));
    setFormData((prevData) => ({ ...prevData, venueTreatments: newInputValues }));
  };

  useEffect(() => {
    setShowBtn(false);
    getEmployees();
    if (serviceID) {
      getServices();
    }
    setFormErrors({});
    context.dispatch({
      type: "SET_SCREEN_TYPE",
      payload: false,
    });
    return () => {
      setFormData({
        saloonId: saloonID,
        venueTreatments: [initialVenueTreatmentObj],
      });
    };
  }, [serviceID]);

  useEffect(() => {
    setShowBtn(true);
  }, [formData]);

  return (
    <>
      {/* <ServiceLayout> */}
      <SaloonLayout navToLocation={(id) => navigate(`/saloon/${id}/serviceListing`)}>
        <Header headerTitle={serviceID ? "Update Service" : "Create Service"} headerTitleIcon={<GroupIcon />} />
        <PrimaryLayout>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Stack sx={{ position: 'relative', mb: 2 }}>
              <Fab
                size="small"
                color="primary"
                sx={{ position: 'absolute', left: 0, top: -10 }}
                onClick={() => navigate(-1)}
              >
                <KeyboardBackspace />
              </Fab>
              <Typography
                variant="h5"
                align="center"
                children={`${serviceID ? 'Update' : 'Add'} Service`}
              />
            </Stack>
            <Grid container spacing={2}>
              {serviceID && (
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <LoadingButton
                    color="error"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => {
                      checkIsPackageIncludesService(false);
                    }}
                  >
                    <Box component={"span"}>DELETE</Box>
                  </LoadingButton>
                  <Button endIcon={<DoneIcon />} color="success" sx={{ fontSize: 12 }} disableRipple component="div">
                    SAVED: {moment(formData?.updatedAt).calendar()}
                  </Button>
                </Grid>
              )}

              {/* service name section */}
              <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={6}>
                      <Typography>SERVICE NAME</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="Service Name"
                        required
                        fullWidth
                        max={30}
                        name="serviceName"
                        value={formData.serviceName}
                        error={formErrors?.serviceName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>SERVICE SHORT NAME</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="Service Short Name"
                        required
                        fullWidth
                        maxLength={3}
                        min={1}
                        name="shortName"
                        value={formData.shortName}
                        error={formErrors?.shortName}
                        onChange={handleChange}
                        helperText="Short name must be between 0 to 3 characters"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>COLOUR</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ColorPicker
                        value={formData.colorCode}
                        onChange={(e) => {
                          setFormData({ ...formData, colorCode: e.hex });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Alert icon={<InfoOutlinedIcon />}>
                        The short name and colour you choose will be associated with the treatment in your diary each time you make an appointment, so
                        it will be easier for you to recognise it quickly.
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="ADD TO FEATURED SERVICES"
                        labelPlacement="start"
                        control={
                          <Switch
                            color="primary"
                            checked={formData?.addToFeatured}
                            onChange={(e) => setFormData({ ...formData, addToFeatured: e.target.checked })}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* service name section */}

              {/* price and duration section */}
              <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                      <Typography textAlign={"center"} mb={2}>
                        PRICES AND DURATION
                      </Typography>
                    </Grid>
                    {formData.venueTreatments.map((eachService, index) => (
                      <TreatmentInfoFields
                        key={index}
                        formData={formData}
                        error={formErrors}
                        index={index}
                        inputValues={eachService}
                        handleChange={(event) => handleChangeForVenueTreatments(index, event)}
                        onRequestRemove={() => {
                          if (serviceID) {
                            setRemoveVenueTreatments({ status: true, index: index });
                            checkIsPackageIncludesService(true);
                          } else {
                            handleRemoveVenueTreatments(index);
                          }
                        }}
                      />
                    ))}
                    {formData.hasVariants && (
                      <>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} align="Center">
                          <Button
                            size="small"
                            endIcon={<AddIcon />}
                            onClick={() => setFormData((prev) => ({ ...prev, venueTreatments: [...prev.venueTreatments, initialVenueTreatmentObj] }))}
                          >
                            ADD NEW VARIANT
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="THE SERVICE HAS VARIANTS IN PRICE OR DURATION"
                        labelPlacement="end"
                        control={<Switch color="primary" checked={formData.hasVariants ?? false} />}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData((prevData) => ({
                              ...prevData,
                              hasVariants: e.target.checked,
                              venueTreatments: [
                                {
                                  price: 120,
                                  duration: 900,
                                  tempId: uuidv4(),
                                },
                                {
                                  price: 120,
                                  duration: 900,
                                  tempId: uuidv4(),
                                },
                              ],
                            }));
                          } else {
                            setFormData((prevData) => ({
                              ...prevData,
                              hasVariants: e.target.checked,
                              venueTreatments: [initialVenueTreatmentObj],
                            }));
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="THE SERVICE REQUIRES A CLEAN-UP TIME"
                        labelPlacement="end"
                        control={
                          <Switch
                            color="primary"
                            onChange={(e) => {
                              setFormData((prevData) => ({ ...prevData, requiredExtraTime: e.target.checked }));
                            }}
                            checked={formData?.requiredExtraTime}
                          />
                        }
                      />
                      {formData?.requiredExtraTime && <Divider sx={{ mt: 1.5 }} />}
                    </Grid>
                    {formData.requiredExtraTime && (
                      <>
                        <Grid item xs={6}>
                          <SelectBox
                            items={timeOptions}
                            name="extraTime"
                            label={"CLEAN UP TIME"}
                            size="small"
                            fullWidth
                            initValue={formData?.extraTime}
                            value={formData?.extraTime}
                            error={formErrors?.extraTime}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={6} align="right">
                          <FormControlLabel
                            label="MANDATORY AFTER EVERY TREATMENT"
                            labelPlacement="end"
                            control={
                              <Switch
                                color="primary"
                                onChange={(e) => setFormData((prevData) => ({ ...prevData, alwaysApplyExtraTime: e.target.checked }))}
                                checked={formData?.alwaysApplyExtraTime}
                              />
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
              {/* price and duration section */}

              {/* WHICH TEAM MEMBERS CAN PERFORM THIS SERVICE? section */}
              <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                      <Typography textAlign={"center"} mb={2}>
                        WHICH TEAM MEMBERS CAN PERFORM THIS SERVICE?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <NestedCheckboxes data={employees} formData={formData} handleChange={setFormData} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* WHICH TEAM MEMBERS CAN PERFORM THIS SERVICE? section */}

              {/* SERVICE DETAILS */}
              <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                      <Typography textAlign={"center"} mb={2}>
                        SERVICE DETAILS
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List sx={{ width: "100%" }}>
                        <ListItem alignItems="center" sx={{ gap: 5 }}>
                          <ListItemText
                            sx={{ width: "80%" }}
                            primary="Gender"
                            secondary="Define if the service is targeted to a specific gender identity or leave it as gender-neutral. Specifying the gender identity will not block bookings from customers who identify with a different gender."
                          />
                          <ListItemText
                            sx={{ width: "20%" }}
                            primary={
                              <SelectBox
                                size="small"
                                required
                                name="serviceGenderType"
                                value={formData.serviceGenderType}
                                error={formErrors?.serviceGenderType}
                                onChange={handleChange}
                                items={[
                                  { label: "Male", value: "male" },
                                  { label: "Female", value: "female" },
                                  { label: "Gender-neutral", value: "neutral" },
                                ]}
                              />
                            }
                          />
                        </ListItem>
                        <Divider />
                        <ListItem alignItems="center">
                          <ListItemText
                            sx={{ width: "90%" }}
                            primary="Bookable Online"
                            secondary="Services bookable online will be visible on your salon's Marketplace page and Widget, and users will be able to book them directly."
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={formData?.bookableOnline}
                                onChange={(e) => {
                                  setFormData((prevData) => ({ ...prevData, bookableOnline: e.target.checked }));
                                }}
                              />
                            }
                          />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            sx={{ width: "90%" }}
                            primary="Bookable only in package"
                            secondary="Activate this flag in case you want this service to be bookable only in combination of others in one of your packages."
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={formData?.bookableOnlyInPackage}
                                onChange={(e) => {
                                  setFormData((prevData) => ({ ...prevData, bookableOnlyInPackage: e.target.checked }));
                                }}
                              />
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* SERVICE DETAILS */}

              {/* SERVICE DESCRIPTION */}
              <Grid item xs={12}>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                      <Typography textAlign={"center"} mb={2}>
                        SERVICE DESCRIPTION
                      </Typography>
                      <InputField
                        multiline
                        rows={5}
                        fullWidth
                        placeholder="Type the description here"
                        name="serviceDescription"
                        value={formData.serviceDescription}
                        error={formErrors?.serviceDescription}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* SERVICE DESCRIPTION */}

              {showBtn && (
                <CenterSubmitBtn variant="contained" type="submit" key={serviceID} isLoading={isLoading}>
                  {serviceID ? "Update" : "Create"}
                </CenterSubmitBtn>
              )}
            </Grid>
          </Box>
          <ConfirmationModal
            open={openDeleteModel}
            callBack={() => removeVenueTreatments.status ? handleRemoveVenueTreatments(removeVenueTreatments.index) : handleDelete()}
            handleClose={() => {
              setOpenDeleteModel(false);
              setRemoveVenueTreatments({ status: false });
              setIsLoading(false);
            }}
            children={
              packageInServiceData?.length &&
              <>
                <DialogTitle sx={{ textAlign: "center" }}>ATTENTION</DialogTitle>
                <DialogContent>
                  <Typography variant="span" sx={{ textAlign: "center" }}>Remove this service from the following packages before deleting it</Typography>
                  <Box my={2}>
                    {packageInServiceData?.map((item) => (
                      <Typography key={item?._id} variant="h6" sx={{ my: 1 }}>{item?.packageName}</Typography>
                    ))}
                  </Box>
                </DialogContent>
              </>
            }
          />
        </PrimaryLayout>
        {/* </ServiceLayout> */}
      </SaloonLayout>
    </>
  );
};

export default Service;

const TreatmentInfoFields = ({ formData, handleChange, error, index, onRequestRemove, inputValues }) => {
  const hasVariants = formData?.venueTreatments.length > 1;
  const showDelete = formData?.venueTreatments.length > 2;
  return (
    <>
      <Grid item xs={hasVariants ? 4 : 6}>
        <InputField
          label="PRICE"
          size="small"
          type="number"
          required
          fullWidth
          name="price"
          error={error[`venueTreatments[${index}].price`]}
          value={inputValues?.price}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={hasVariants ? 4 : 6}>
        <SelectBox
          label={"Duration"}
          size="small"
          required
          fullWidth
          items={timeOptions}
          name="duration"
          initValue={inputValues?.duration}
          value={inputValues?.duration}
          error={error[`venueTreatments[${index}].duration`]}
          onChange={handleChange}
        />
      </Grid>
      {hasVariants && (
        <Grid item xs={showDelete ? 3 : hasVariants ? 4 : 6}>
          <InputField
            label="Name"
            size="small"
            fullWidth
            required
            name="name"
            value={inputValues?.name}
            error={error[`venueTreatments[${index}].name`]}
            onChange={handleChange}
          />
        </Grid>
      )}
      {showDelete && (
        <Grid item xs={1}>
          <IconButton color="error" onClick={onRequestRemove}>
            <ClearOutlined />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography>MANDATORY RELATED SERVICE</Typography>
          <Button variant="outlined" size="small" endIcon={<AddIcon />}>
            ADD SERVICE
          </Button>
        </Box>
      </Grid>
    </>
  );
};
