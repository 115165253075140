import React, { useCallback, useMemo } from "react";

import DayEvent from "./DayEvent";

const DayEvents = ({ events, hours }) => {
  const renderEvents = useMemo(() => {
    return events?.map((eachEvent, index) => <DayEvent key={index} event={eachEvent} hoursArray={hours} events={events} />);
  }, [events, hours]);

  return <>{renderEvents}</>;
};

export default DayEvents;
