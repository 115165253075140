import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TableRow, TableCell, Tooltip, TableHead, Table, TableBody, Paper, Collapse, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Header, TableContainer, InputField } from "@components";
import { PrimaryLayout, SaloonLayout } from "../../../layouts";
import { setLoader } from "src/store/reducer";
import { useDispatch } from "react-redux";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AddIcon from "@mui/icons-material/Add";
import apiManager from "../../../services/api-manger";
import moment from "moment";
import { Edit, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { timeOptions } from "src/constant";

const ServiceListing = () => {

    const { saloonID } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const totalPages = Math.ceil(records?.length / perPage);
    const thLabels = ['Treatment Details', 'Service Name', 'Service Gender Type', 'Created At', 'Actions',];

    const getAllServices = async () => {
        try {
            setIsLoading(true);
            dispatch(setLoader(true));
            let { data } = await apiManager({ method: "get", path: `service/all/${saloonID}` });
            setRecords(data?.data);
            console.log("🚀 ~ getAllPackages ~ data:", data)
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
        } finally {
            dispatch(setLoader(false));
            setIsLoading(false);
        }
    };

    const handleDelete = async (serviceID) => {
        setIsLoading(true);
        dispatch(setLoader(true));
        try {
            const { data } = await apiManager({ method: "delete", path: `service/delete/${serviceID}` });
            enqueueSnackbar(data?.message, { variant: "success" });
            getAllServices();
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
            setIsLoading(false);
        } finally {
            dispatch(setLoader(false));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllServices();
    }, [saloonID]);


    const Row = ({ item, index, saloonId }) => {
        const [open, setOpen] = useState(false)
        return (
            <>
                <TableRow key={index}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{item?.serviceName || '-'}</TableCell>
                    <TableCell>{item?.serviceGenderType || '-'}</TableCell>
                    <TableCell>{moment(item.createdAt).format('DD MMM YYYY')}</TableCell>
                    <TableCell>
                        <Tooltip title="Edit">
                            <IconButton
                                color="success"
                                component={Link}
                                to={`/saloon/${saloonId}/service/${item?._id}`}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Paper sx={{ my: 1, p: 2 }} square={false}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Details
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell align="right">Duration</TableCell>
                                            <TableCell align="right">Appealable employee to perform</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {item?.venueTreatments?.map((each, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{each?.name || '-'}</TableCell>
                                                <TableCell>{each?.price}</TableCell>
                                                <TableCell align="right">
                                                    {timeOptions?.find((eachTme) => eachTme?.value === each?.duration)?.label ||
                                                        '-'}
                                                </TableCell>
                                                <TableCell align="right">{each?.applicableToPerform?.length}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    return (
        <>
            <SaloonLayout navToLocation={(id) => navigate(`/saloon/${id}/serviceListing`)}>
                <Header headerTitle={"Services"} headerTitleIcon={<ContentPasteIcon />} />
                <PrimaryLayout>
                    <Grid container rowSpacing={3}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={9}>
                                <InputField
                                    label="Search Services"
                                    type="text"
                                    size="small"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box component={Link} to={`/saloon/${saloonID}/service`}>
                                    <Button variant="contained" fullWidth startIcon={<AddIcon />}>ADD SERVICES</Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer
                                thContent={thLabels.map((value, i) => <TableCell key={i} children={value} />)}
                                spanTd={thLabels.length}
                                page={page}
                                totalPages={totalPages}
                                callBack={setPage}
                                isContent={records.results?.length}
                            >
                                {records.results?.map((item, i) => {
                                    return <Row item={item} index={i} key={i} saloonId={saloonID} />;
                                })}
                            </TableContainer>
                        </Grid>
                    </Grid>
                </PrimaryLayout>
            </SaloonLayout>
        </>
    );
};

export default ServiceListing;