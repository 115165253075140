import React, { useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Logo } from "../../assets";
import { SelectBox } from "@components";
import { Link } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const [language, setLanguage] = useState("english");
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: { xs: 2, md: 4 },
          my: { xs: 2, md: 4 },
          maxWidth: "730px",
          boxShadow: "0px 6px 58px 0px rgba(0, 0, 0, 0.05)",
        }}
        elevation={6}
      >
        <Box>
          <Avatar
            sx={{ width: "100%", height: { xs: "40px", md: "50px" } }}
            variant="square"
            src={Logo}
          />
        </Box>
        {children}
        <Grid container spacing={2} flexWrap={"wrap"} alignItems={"center"} mt={{ xs: 0.2 }}>
          <Grid item xs={4} sm={4}>
            <Typography whiteSpace={"nowrap"}>2023 Treatwell</Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography
              component={Link}
              sx={style.linkSx}
            >
              Privacy
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography
              component={Link}
              sx={style.linkSx}
            >
              Legal
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems={"center"} mt={{ xs: 0.2 }}>
          <Grid item xs={4} sm={4}>
            <Typography
              component={Link}
              sx={style.linkSx}
            >
              Help
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Typography size="small">Language:</Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <SelectBox
              size="small"
              name="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              items={[{ label: "English", value: "english" },]}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const style = {
  linkSx: {
    textDecoration: "none",
    color: "inherit",
  },
};

export default AuthLayout;
