import React, { useState, useEffect } from "react";
import { InputLabel, FormControl, Select, MenuItem, FormHelperText, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";


export default function SelectBox({
  labelTop,
  label,
  items = [],
  error = "",
  value: propsValue,
  styles = {},
  size = "small",
  name = "",
  onChange: propsOnChange,
  helperText,
  ...props
}) {
  const [stateValue, setStateValue] = React.useState("");
  const value = propsValue !== undefined ? propsValue : stateValue;

  const onChange = (event) => {
    if (propsOnChange) {
      propsOnChange(event);
    } else {
      setStateValue(event.target.value);
    }
  };

  const _id = `select__${uuidv4()}`;

  return (
    <Box sx={styles}>
      {labelTop && (
        <InputLabel
          id={_id}
          sx={{
            marginBottom: "5px",
            color: "#000",
          }}
        >
          labelTop
        </InputLabel>
      )}

      <FormControl {...props} size={size} fullWidth>
        {label && <InputLabel id={_id}>{label}</InputLabel>}
        <Select id={_id} labelId={_id} name={name} error={Boolean(error !== "")} value={value} onChange={onChange} label={label}>
          {items.map((_v, _i) => {
            return <MenuItem key={_i} value={_v.value}>
              {_v.label}
            </MenuItem>;
          })}
        </Select>
      </FormControl>
      {error !== "" && <FormHelperText sx={{ color: "red", mt: "0 !important" }}>{error}</FormHelperText>}
      {helperText && error === "" && <FormHelperText sx={{ mt: "0 !important" }}>{helperText}</FormHelperText>}
    </Box>
  );
}