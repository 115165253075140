import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { routerData } from "./appRoutes.js";
import ComponentWrapper from "../hoc/ComponentWrapper";
import { Loader } from "@components";
import { useSelector } from "react-redux";

const Router = () => {
  const { loader } = useSelector((state) => state.appReducer);
  return (
    <>
      <Routes>
        {routerData.map((item, index) => {
          return <Route key={index} path={item.url} exact={item.exact} element={<ComponentWrapper item={item} />} />;
        })}
      </Routes>
      {loader && <Loader />}
    </>
  );
};

export default Router;
