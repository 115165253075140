import * as Yup from 'yup'

const PackageRules = (update) =>
  Yup.object().shape({
    packageName: Yup.string()
      .required('Name is required')
      .min(6, 'Name must be at least 6 characters')
      .max(30, 'Name must be most 6 characters'),
    description: Yup.string()
      .optional()
      .min(6, 'Package description must be at least 6 characters'),
    packageOptionAttributes: Yup.array().min(2, 'Please select at least 2 service').required(),
  })

export default PackageRules