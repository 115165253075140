import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { DeleteForever } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import { Header, TableContainer, ContainerWithTopHeading, InputField, SelectBox, TypoWithSubText, CenterSubmitBtn } from "@components";
import { PrimaryLayout, SaloonLayout } from "../../../layouts";
import { MainGridRowSpacing } from "../../../constant";
import { useNavigate, useParams } from "react-router-dom";
import apiManager from "../../../services/api-manger";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { closeDrawer, setLoader, setSaloonInfo } from "src/store/reducer";

const SaloonProfile = () => {
  const { saloonID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const [saloonData, setSaloonData] = useState(null);
  const thLabels = ["CARD", "EXPIRATION", "ACTION"];

  const getSingleSalon = async () => {
    dispatch(setLoader(true));
    try {
      let { data } = await apiManager({ method: "get", path: `saloons/get-single/${saloonID}` });
      dispatch(setSaloonInfo(data?.data));
      dispatch(closeDrawer("right"));
      setSaloonData(data?.data);
    } catch (error) {
      console.log("getSingleSalon ~ error:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    getSingleSalon();
  }, [saloonID]);

  // CARD CRUD LOGIC
  const [cards, setCards] = React.useState(Array(4).fill(null));
  const handleAddCard = () => {
    const newCard = {
      cardNumber: 'New card number', // You can set initial values for the new card
      expirationDate: 'New expiration date', // Example values, replace them with actual data
    };
    setCards((prevCards) => [...prevCards, newCard]);
  };
  const handleDeleteCard = (index) => {
    setCards((prevCards) => prevCards.filter((_, i) => i !== index));
  };

  const [avatarImage, setAvatarImage] = useState(null);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <SaloonLayout
      navToLocation={(id) => {
        navigate(`/saloonProfile/${id}`);
      }}
    >
      <Header
        headerTitle={"Profile"}
        headerTitleIcon={<PersonIcon />}
        showMoreButtons={[
          {
            visible: true,
            button_title: "Settings",
            button_startIcon: <SettingsIcon />,
            button_endIcon: false,
            button_link: "/",
          },
        ]}
      />
      <PrimaryLayout>
        <Grid container rowSpacing={MainGridRowSpacing} mb={10}>
          {/* Details */}
          <Grid item xs={12}>
            <ContainerWithTopHeading headingTop={false}>
              <Grid container>
                <Grid item lg={4} container alignItems="center" justifyContent="center" mb={2}>
                  <Stack direction={{ sm: "row", xs: "column" }} alignItems="center" justifyContent="center" spacing={2}>
                    <Avatar sx={{ width: 80, height: 80 }} />
                    <Box>
                      <Typography variant="h2">{saloonData?.name}</Typography>
                      <Typography variant="h6">{saloonData?.address?.location}</Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item mx={2}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid lg={7} item container>
                  <Grid item sm={6} xs={12}>
                    <Stack spacing={1}>
                      <TypoWithSubText primaryText="Business Name" subText={saloonData?.name} />
                      <TypoWithSubText primaryText="Email address" subText={saloonData?.email} />
                      <TypoWithSubText primaryText="Business Address" subText={saloonData?.address?.location} />
                    </Stack>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Stack spacing={1}>
                      <TypoWithSubText primaryText="Phone number" subText={saloonData?.phone || "-"} />
                      <TypoWithSubText primaryText="Iban" subText={saloonData?.iban || "-"} />
                      <TypoWithSubText primaryText="Tax Code" subText={saloonData?.taxCode || "-"} />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </ContainerWithTopHeading>
          </Grid>
          {/* Details */}

          {/* Logo upload */}
          <Grid item xs={12}>
            <ContainerWithTopHeading title="Personal Details">
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xl={2} sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar sx={{ width: 200, height: 200 }} src={avatarImage ? avatarImage : null} />
                </Grid>
                <Grid item lg={0.5} xs={12} sx={{ display: "flex", justifyContent: "center", alignSelf: "stretch" }}>
                  <Divider orientation={"vertical"} />
                </Grid>
                <Grid item lg={8}>
                  <Stack spacing={1}>
                    <Typography children={saloonData?.name} variant="h5" />
                    <Typography
                      children="Add your Business logo or Salon photo to help you identify your accounts easily in multi-Salon View"
                      variant="body1"
                    />
                    <Stack direction={{ sm: "row", xs: "column" }} spacing={2} alignItems={{ sm: "center", xs: "normal" }}>
                      <input
                        type="file"
                        accept="image/*"
                        id="avatar-upload"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="avatar-upload" style={{ margin: 0 }}>
                        <Button
                          component="span"
                          children="Upload your logo/photo"
                          variant="contained"
                          startIcon={<ImageIcon />}
                          size="medium"
                          sx={{ width: { sm: "auto", xs: "100%" } }}
                        />
                      </label>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </ContainerWithTopHeading>
          </Grid>
          {/* Logo upload */}

          {/* Salon type */}
          <Grid item xs={12}>
            <ContainerWithTopHeading title="Personal Details">
              <Stack flexDirection={"row"} alignItems={'center'} gap={2} mb={2}>
                <Typography children="Type of Salon" variant="h5" />
                <Chip label={saloonData?.typeOfSaloon} variant="outlined" color="primary" sx={{ fontWeight: "bold", textTransform: "capitalize" }} />
              </Stack>
              <Typography children="Lorem ipsum dolor sit amet consectetur. Vivamus eget cursus arcu nisl massa aliquet orci. Vivamus eget cursus arcu nisl massa aliquet orci. Vivamus eget cursus arcu nisl massa aliquet orci. Vivamus eget cursus arcu nisl massa aliquet orci." variant="body1" />
            </ContainerWithTopHeading>
          </Grid>
          {/* Salon type */}

          {/* Notifications settings */}
          <Grid item xs={12}>
            <ContainerWithTopHeading title="Personal Details">
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item lg={4} xs={12}>
                  <Typography children="Client Booking Confirmation Settings" variant="h5" />
                  <Box>
                    <FormControlLabel control={<Switch name="Send Push Notification" />} label="Send Push Notification" />
                    <FormControlLabel control={<Switch name="Send Notification Email" />} label="Send Notification Email" />
                    <FormControlLabel control={<Switch name="Send SMS Notification" />} label="Send SMS Notification" />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Typography children="Appointments Reminder" variant="h5" />
                  <Box>
                    <FormControlLabel control={<Switch name="Send Push Notification" />} label="Send Push Notification" />
                    <FormControlLabel control={<Switch name="Send Notification Email" />} label="Send Notification Email" />
                    <FormControlLabel control={<Switch name="Send SMS Notification" />} label="Send SMS Notification" />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Stack spacing={2}>
                    <SelectBox
                      label="When"
                      items={[
                        { label: "24 Hours before", value: "24-hours" },
                        { label: "48 Hours before", value: "48-hours" },
                        { label: "72 Hours before", value: "72-hours" },
                      ]}
                    />
                    <Stack direction="row" spacing={1}>
                      <InputField label="Sender Name" size="small" value="John" disabled />
                      <IconButton children={<EditIcon />} color="primary" />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </ContainerWithTopHeading>
          </Grid>
          {/* notifications settings */}

          <Grid item xs={12}>
            <ContainerWithTopHeading title="Add payment method">
              <Typography children="Your Cards" variant="h4" textAlign="center" mb={1} />
              <Grid container spacing={3}>
                <Grid item xl={3} lg={4} xs={12}>
                  <Card sx={{
                    minHeight: '350px',
                    height: '320px'
                  }} elevation={6}>
                    <CardActionArea
                      component={'div'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <CreditCardIcon sx={{ fontSize: '3.5rem' }} onClick={handleAddCard} />
                      <Typography children="Add a new debit or Credit Card" />
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item lg xs={12}>
                  <TableContainer
                    thContent={thLabels.map((value, i) => (<TableCell key={i} children={value} />))}
                    isContent={true}
                  >
                    {cards.map((card, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <CreditCardIcon fontSize="small" />
                            <Typography children={card?.cardNumber || 'Card Number'} />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography children={card?.expirationDate || 'Expiration Date'} />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            children={<DeleteForever />}
                            size="small"
                            color="error"
                            onClick={() => handleDeleteCard(index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableContainer>
                </Grid>
              </Grid>
            </ContainerWithTopHeading>
          </Grid>
        </Grid>
        <CenterSubmitBtn variant="contained" size="medium" onClick={() => navigate(`/saloon/${saloonID}/update`)}>Edit Saloon</CenterSubmitBtn>
      </PrimaryLayout>
    </SaloonLayout>
  );
};

export default SaloonProfile;
