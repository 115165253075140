import React from "react";
import { Box, InputAdornment, Paper, Stack, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

const SearchBar = ({ onChange = () => {} }) => {
  return (
    <Stack justifyContent="Center" alignItems="center" height="100%">
      <Box sx={{ width: "90%" }}>
        <TextField
          size="small"
          placeholder="search"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={onChange}
        />
      </Box>
    </Stack>
  );
};

export default SearchBar;
