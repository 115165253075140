import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";

const HeadingWithIcon = ({ title, icon, type = "subHeading", listStyle, component = "div", ...props }) => {
  return (
    <ListItem component={component} {...props} sx={styles(type, listStyle).listItem}>
      {icon && <ListItemIcon sx={styles(type, listStyle).listItem}>{icon}</ListItemIcon>}
      <ListItemText primary={title} sx={styles(type, listStyle).listItemText} />
    </ListItem>
  );
};

export default HeadingWithIcon;

const styles = (type, listStyle) => {
  let typoStyle = {
    heading: (theme) => ({ ...theme.typography.h4, color: "black" }),
    subHeading: (theme) => ({ ...theme.typography.h5, color: "black" }),
    normal: {
      color: "black",
    },
  };

  const listSx = typoStyle[type];
  return {
    listItem: {
      display: "flex",
      minWidth: "30px",
      p: "0px",
      ...listStyle,
      "& .MuiSvgIcon-root": listSx,
    },
    listItemText: {
      "& .MuiTypography-root": listSx,
    },
  };
};
