import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

import StoreProvider from "../../contexts/storeProvider/provider";

const SchedulerContextProvider = ({ children, ...props }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );
  return (
    <DndContext modifiers={[restrictToFirstScrollableAncestor]} sensors={sensors}>
      <StoreProvider initial={props}>{children}</StoreProvider>
    </DndContext>
  );
};

export default SchedulerContextProvider;
