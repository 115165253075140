import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { Header, LeftDrawer } from "@components";
import { PrimaryLayout, SaloonLayout } from "../../layouts";
import { AddProfile } from "../../assets";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import { SaloonContextProvider } from "src/context/SaloonContext/Context";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <SaloonLayout
        navToLocation={(id) => {
          navigate(`/saloonProfile/${id}`);
        }}
      >
        <Header
          openDrawerBtn={false}
          LogoutBtn={true}
        />
        <PrimaryLayout>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 4,
              minHeight: "80vh",
            }}
          >
            <Avatar sx={{ width: "20%", height: "10%" }} variant="square" src={AddProfile} />
            <Typography sx={{ fontSize: 25, color: "grey.300" }}>Select a Saloon from side menu or, Create a new one</Typography>
            <Box component={Link} to={"/saloon/add"}>
              <Button variant="contained" startIcon={<AddIcon />}>
                Add Saloon
              </Button>
            </Box>
          </Box>
        </PrimaryLayout>
      </SaloonLayout>
    </>
  );
};

export default Home;
