import { Button } from "@mui/material";
import React from "react";

const TypoWithIcon = ({ icon, text, hover = false ,size ="small" ,...props}) => {
  return (
    <div>
      <Button
        size={size}
        startIcon={icon}
        disableRipple
        color="secondary"
        component="div"
        {...props}
        sx={{
          px: 0,
          "&:hover": {
            background: !hover && "none",
          },
          justifyContent:"flex-start"
        }}
      >
        {text}
      </Button>
    </div>
  );
};

export default TypoWithIcon;
