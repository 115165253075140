import React from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";

const tabVariants = {
  hidden: { x: "100%", opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const TabContent = ({ value, index, children }) => {
  return (
    <Box role="tabpanel" hidden={value !== index} sx={{ mt: 2 }}>
      {value === index && (
        <Box component={motion.div} initial="hidden" animate="visible" variants={tabVariants} transition={{ duration: 0.3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TabContent;
