import { Box, Checkbox, Divider, FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";

const NestedCheckboxes = ({ data, formData, handleChange }) => {

  const [myFormData, setMyFormData] = useState(formData);
  const employeeIds = data?.map((each) => ({
    employeeID: each._id,
    availableOnline: true,
  }));

  const applicableToPerform = formData?.venueTreatments[0]?.applicableToPerform || [];
  const isDisable = formData?.hasVariants && !(formData?.venueTreatments || []).every(({ name }) => {
    return name !== "" && name !== undefined;
  });

  const isIndeterminate = () => {
    if (!formData?.hasVariants) {
      return (
        applicableToPerform?.length >= 1 &&
        employeeIds?.length !== applicableToPerform?.length
      );
    }
    const checkIndeterminate = formData.venueTreatments.every((eachVenue) => {
      return eachVenue.applicableToPerform?.length
        ? eachVenue.applicableToPerform?.length === employeeIds.length
        : true;
    });
    return !checkIndeterminate;
  };

  useEffect(() => {
    setMyFormData(formData?.venueTreatments);
  }, [formData]);

  const toggleSimpleCheckBoxes = (e) => {
    handleChange((prevData) => {
      const currentValue = { employeeID: e.target.value, availableOnline: true };
      const applicableToPerform = prevData?.venueTreatments[0]?.applicableToPerform?.slice() || [];
      const updatedArray = applicableToPerform.filter((item) => item.employeeID !== currentValue.employeeID);
      return {
        ...prevData,
        venueTreatments: [
          { ...prevData.venueTreatments[0], applicableToPerform: updatedArray.length === applicableToPerform.length ? [...applicableToPerform, currentValue] : updatedArray, },
        ],
      };
    });
  };

  const toggleAllCheckBoxes = (e) => {
    handleChange((prevData) => {
      const venue = prevData?.venueTreatments;
      const areAllEqual = venue.every((each) => each.applicableToPerform?.length === employeeIds.length);
      const updatedArray = venue.map((each) => ({ ...each, applicableToPerform: areAllEqual ? [] : employeeIds, }));
      return { ...prevData, venueTreatments: updatedArray, };
    });
  };

  const toggleAllSimpleCheckBoxes = (e) => {
    handleChange((prevData) => {
      return {
        ...prevData,
        venueTreatments: [
          { ...prevData.venueTreatments[0], applicableToPerform: applicableToPerform.length === employeeIds.length ? [] : employeeIds, },
        ],
      };
    });
  };

  const toggleNestedChecks = (e, employeeID) => {
    handleChange((prevData) => {
      const updateData = prevData?.venueTreatments?.map((venue) => {
        if (venue.tempId === e.target.value) {
          const isEmployeeSelected = venue?.applicableToPerform?.some((each) => each.employeeID === employeeID,);
          if (isEmployeeSelected) {
            // Remove the employee from applicableToPerform
            const updatedApplicableToPerform = venue.applicableToPerform.filter((each) => each.employeeID !== employeeID);
            return { ...venue, applicableToPerform: updatedApplicableToPerform };
          } else {
            // Add the employee to applicableToPerform
            return { ...venue, applicableToPerform: [...(venue.applicableToPerform || []), { employeeID, availableOnline: true },], };
          }
        }
        return venue;
      });
      return { ...prevData, venueTreatments: updateData, };
    });
  };

  const toggleNestedChecksForAll = (e) => {
    handleChange((prevData) => {
      const employeeId = e.target.value;
      const isEmployeeSelected = prevData?.venueTreatments?.every((venue) =>
        venue.applicableToPerform?.some(
          (each) => each.employeeID === employeeId,
        ),
      );

      const updateData = prevData?.venueTreatments?.map((venue) => {
        return {
          ...venue, applicableToPerform: isEmployeeSelected ? [...(venue?.applicableToPerform?.filter((eachItem) => eachItem.employeeID !== employeeId,) || []),]
            : [...(venue?.applicableToPerform?.filter((eachItem) => eachItem.employeeID !== employeeId,) || []), { employeeID: employeeId, availableOnline: true },],
        };
      }) || [];

      return { ...prevData, venueTreatments: updateData, };
    });
  };

  const toggleNestedSwitch = (e, id) => {
    handleChange((prevData) => {
      const updatedData = (prevData?.venueTreatments || []).map((eachVenue) => {
        if (eachVenue.tempId === e.target.value) {
          return {
            ...eachVenue, applicableToPerform: (eachVenue?.applicableToPerform || []).map(
              (eachApplicable) => {
                if (eachApplicable.employeeID === id) {
                  return { ...eachApplicable, availableOnline: !eachApplicable.availableOnline, };
                }
                return eachApplicable;
              },
            ),
          };
        }
        return eachVenue;
      });

      // Check if the id is not present in applicableToPerform, add a new object
      const venueToUpdate = updatedData.find((venue) => venue.tempId === e.target.value,);
      if (venueToUpdate) {
        const applicableToPerform = venueToUpdate.applicableToPerform || [];
        const existingEntry = applicableToPerform.find((entry) => entry.employeeID === id,);

        if (!existingEntry) {
          // If id is not found, add a new object
          venueToUpdate.applicableToPerform = [...applicableToPerform, { employeeID: id, availableOnline: true },];
        }
      }
      return { ...prevData, venueTreatments: updatedData, };
    });
  };

  return (
    <>
      <FormControlLabel
        label="Enable all team members"
        control={
          <Checkbox
            onChange={formData?.hasVariants ? toggleAllCheckBoxes : toggleAllSimpleCheckBoxes}
            checked={employeeIds.length === applicableToPerform.length}
            indeterminate={isIndeterminate()}
            disabled={isDisable}
          />
        }
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {data.map((each) => (
          <CheckWithSwitch
            key={each._id}
            checkLabel={each?.firstName + "" + each?.lastName}
            checkRest={{
              onChange: formData?.hasVariants ? toggleNestedChecksForAll : toggleSimpleCheckBoxes,
              value: each._id,
              checked: applicableToPerform?.some(({ employeeID }) => employeeID === each._id,) ?? false,
            }}
            switchRest={{
              checked: applicableToPerform?.find(({ employeeID }) => employeeID === each?._id,)?.availableOnline ?? false,
            }}
            formData={formData}
            employeeId={each._id}
            nestedChecksRest={{ onChange: (e) => toggleNestedChecks(e, each._id), }}
            nestedSwitchRest={{ onChange: (e) => toggleNestedSwitch(e, each._id), }}
            isDisable={isDisable}
          />
        ))}
      </Box>
    </>
  );
};

export default NestedCheckboxes;

const CheckWithSwitch = ({
  checkLabel,
  checkRest,
  switchRest,
  formData,
  nestedChecksRest,
  nestedSwitchRest,
  employeeId,
  isDisable,
}) => {

  const isNestedToggleChecked = (id, employeeId) => {
    const venueTreatment = formData.venueTreatments.find(({ tempId }) => tempId === id,);
    if (!venueTreatment) {
      return false;
    }
    const isCheck = venueTreatment?.applicableToPerform?.some((each) => each.employeeID === employeeId,) ?? false;
    return isCheck;
  };

  const isNestedToggleIndeterminate = (employeeId) => {
    const venueTreatment = formData.venueTreatments;
    if (!venueTreatment) {
      return false;
    }
    const employeeIds = [];
    venueTreatment?.forEach((each) => {
      each?.applicableToPerform?.forEach((item) => {
        if (item?.employeeID === employeeId) {
          employeeIds.push(item.employeeID);
        }
      });
    });

    return (!!employeeIds.length && employeeIds.length !== venueTreatment?.length);
  };

  const isNestedSwitchCheck = (id, employeeId) => {
    const venueTreatment = formData.venueTreatments.find(({ tempId }) => tempId === id,);
    if (!venueTreatment) {
      return false;
    }
    const isCheck = venueTreatment?.applicableToPerform?.find((each) => each.employeeID === employeeId,)?.availableOnline ?? false;
    return isCheck;
  };

  return (
    <Grid container>
      <Grid item xs={formData.hasVariants ? 12 : 6} sx={{ borderBottom: 1, py: 1, borderColor: (theme) => theme.palette.divider, }}>
        <FormControlLabel
          label={checkLabel}
          control={
            <Checkbox
              {...checkRest}
              indeterminate={isNestedToggleIndeterminate(employeeId)}
              disabled={isDisable}
            />
          }
        />
        <Box display="block">
          {formData.hasVariants && formData?.venueTreatments?.map((each, i) => {
            return (
              each.name && (
                <Grid container xs={12} ml={5} key={i}>
                  <Grid item xs={5}>
                    <FormControlLabel
                      label={each?.name}
                      control={
                        <Checkbox
                          {...nestedChecksRest}
                          disabled={isDisable}
                          value={each?.tempId}
                          checked={isNestedToggleChecked(each?.tempId, employeeId,)}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={6} align="right">
                    <FormControlLabel
                      label="Book online"
                      control={
                        <Switch
                          {...nestedSwitchRest}
                          value={each?.tempId}
                          disabled={isDisable}
                          checked={isNestedSwitchCheck(each?.tempId, employeeId,)}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              )
            );
          })}
        </Box>
      </Grid>
      {!formData.hasVariants && (
        <Grid item xs={6} align="right" sx={{ borderBottom: 1, py: 1, borderColor: (theme) => theme.palette.divider, }}        >
          <FormControlLabel label="Book online" control={<Switch {...switchRest} disabled={isDisable} />} />
        </Grid>
      )}
    </Grid>
  );
};
