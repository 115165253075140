import useStore from "./useStore";

export const useCellAttributes = ({ start, end, resources, type }) => {
  const { triggerDialog, onCellClick } = useStore();

  return {
    onClick: () => {
      if (typeof onCellClick === "function") {
        return onCellClick({
          start,
          end,
          resourceId: resources?._id,
          for: type,
        });
      }
      triggerDialog(true, {
        start,
        end,
        resourceId: resources?._id,
        for: type,
      });
    },
  };
};
