import React, { useState, useCallback, useEffect, useLayoutEffect } from "react";

import { Box } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import { Resizable } from "react-resizable";

import { calculatePositionAndWidth, calculateWidthByTime } from "../../helpers/generals";
import { useGetElementWidth } from "../../hooks/useGetParentWidth";
import useStore from "../../hooks/useStore";
import EventCard from "../events/EventCard";
import "../../styles/style.css";

const DayEvent = React.memo(({ event, hoursArray, events }) => {
  const {
    onEventUpdate,
    day: { step, eventResizeInterval },
  } = useStore();
  const [previewState, setPreviewState] = useState(null);
  const [widthAndPosition, setWidthAndPosition] = useState(null);
  const [resizePosition, setResizePosition] = useState(null);

  const startHour = hoursArray[0];
  const endHours = moment(hoursArray[hoursArray.length - 1]).add(step, "minutes");

  const [parentWidth, setParentWidth] = useState("");

  useLayoutEffect(() => {
    const element = document.getElementById("day-view-row");
    if (element) {
      const width = element.offsetWidth;
      setParentWidth(width);
    }
  }, [step]);

  const calculateRoundedDiff = (newWidth) => {
    const baseDiff = (newWidth / parentWidth) * (hoursArray.length * step);
 
    return Math.round(baseDiff) - step;
  };

  const calculateNewTime = (event, handle, roundedDiff, forPreview) => {
    const diff = forPreview ? roundedDiff : Math.round(roundedDiff / eventResizeInterval) * eventResizeInterval;
    // const diff = roundedDiff;
    if (handle === "e") {
      return moment(event.start).add(diff, "minutes").format();
    } else if (handle === "w") {
      return moment(event.end).subtract(diff, "minutes").format();
    }
  };

  const onResizeEnd = (size, handle) => {
    if (handle === "e" || handle === "w") {
      const newWidth = size.width;
      const roundedDiff = calculateRoundedDiff(newWidth, handle, event);
      const updatedEvent = { ...event, [handle === "e" ? "end" : "start"]: calculateNewTime(event, handle, roundedDiff) };

      setResizePosition(null);
      setPreviewState(null);
      setWidthAndPosition(null);
      onEventUpdate(updatedEvent);
    }
  };

  const onResize = (size, handle) => {
    const roundedDiff = calculateRoundedDiff(size.width, handle, event);

    const updateEvent = { ...event, [handle === "e" ? "end" : "start"]: calculateNewTime(event, handle, roundedDiff, false) };
    const previewEvent = { ...event, [handle === "e" ? "end" : "start"]: calculateNewTime(event, handle, roundedDiff, true) };
    const { left, width } = calculatePositionAndWidth(previewEvent, parentWidth, hoursArray.length, step);

    // Check if the previewEvent overlaps with other events
    const isOverlap = events.some(
      (e) => e !== event && moment(previewEvent.end).add(1, "h").isAfter(e.start) && moment(previewEvent.start).subtract(1, "h").isBefore(e.end)
    );

    if (!isOverlap) {
      if (previewEvent) setPreviewState(updateEvent);
      setWidthAndPosition({
        left,
        width,
      });
    }
  };

  const calculateMaxWidth = () => {
    const startDate = moment(previewState?.start || event?.start);
    const endDate = moment(previewState?.end || event?.end).add(step, "minutes");

    let maxWidth = "";
    if (resizePosition === "e") {
      const durationMinutesForRightSide = endHours.diff(startDate, "minutes");
      maxWidth = calculateWidthByTime(parentWidth, durationMinutesForRightSide, hoursArray.length, step);
    } else {
      const durationMinutesForRightLeft = endDate.diff(startHour, "minutes");
      maxWidth = calculateWidthByTime(parentWidth, durationMinutesForRightLeft, hoursArray.length, step);
    }
    if (resizePosition) {
      return [maxWidth, 0];
    }
  };

  const { left, width } = calculatePositionAndWidth(event, parentWidth, hoursArray.length, step);

  return (
    <>
      <Resizable
        key={event?.event_id}
        width={parseFloat(widthAndPosition?.width?.px) || parseFloat(width.px)}
        height={0}
        onResizeStop={(evnts, { size, handle }) => onResizeEnd(size, handle)}
        onResize={(evnts, { size, handle }) => {
          setResizePosition(handle);
          onResize(size, handle);
        }}
        resizeHandles={["e", "w"]}
        // maxConstraints={calculateMaxWidth()}
        minConstraints={[140, 0]}
      >
        <Box
          sx={{
            position: "absolute",
            left: widthAndPosition?.left || left,
            width: widthAndPosition?.width?.px || parseInt(width.px) - 1.5,
            // width: width.percentage,
            // left: left,
            top: 7,
            zIndex: 9,
            right: 0,
          }}
        >
          <EventCard
            draggableData={{
              id: event?.event_id,
              data: {
                event: event,
                resourceParent: event?.resourceId,
              },
            }}
            event={widthAndPosition ? { ...event, start: previewState?.start, end: previewState?.end } : event}
            resourceParent={event?.resourceId}
            disableAddBtn={true}
          />
        </Box>
      </Resizable>
    </>
  );
});

DayEvent.displayName = "DayEvent";

DayEvent.propTypes = {
  event: PropTypes.object.isRequired,
  hoursArray: PropTypes.array.isRequired,
  events: PropTypes.arrayOf(PropTypes.object),
};

export default DayEvent;

// const calculatePositionAndWidth = (event, containerWidth) => {
//   const start = moment(event.start);
//   const end = moment(event.end).add(1, "h");
//   const dayStart = moment(start).startOf("day");
//   const offsetMinutes = start.diff(dayStart, "minutes");

//   const left = `${(offsetMinutes / (24 * 60)) * 100}%`;
//   const leftPx = (parseFloat(left) / 100) * containerWidth;

//   const durationMinutes = end.diff(start, "minutes");
//   const widthPercentage = (durationMinutes / (24 * 60)) * 100;
//   const widthPx = (widthPercentage / 100) * containerWidth;

//   return { left, width: { percentage: `${widthPercentage}%`, px: `${widthPx}px` }, leftPx };
// };
