import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
};

function MapWithAutocomplete({ mapZoom = 14, coords, handleChange }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
  });

  const [center, setCenter] = useState(coords || defaultCenter);
  const [autocompleteValue, setAutocompleteValue] = useState("");

  useEffect(() => {
    setCenter(coords);
  }, [coords]);

  const handleAutocompleteChange = (e, value) => {
    setAutocompleteValue(value);
    clearSuggestions();
    if (value) {
      getGeocode({ address: value?.description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        handleChange({ latitude: lat, longitude: lng });
        setCenter({ lat, lng });
      });
    }
  };

  const {
    ready,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  return isLoaded && ready ? (
    <div sx={{ position: "relative" }}>
      <Autocomplete
        loading={loading}
        options={data}
        getOptionLabel={(option) => option.description}
        onChange={handleAutocompleteChange}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleInput}
            value={autocompleteValue}
            label="Search Location"
            sx={{ mb: 2 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={mapZoom}
        onClick={(e) => handleChange({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })}
      >
        <MarkerF position={center} />
      </GoogleMap>
    </div>
  ) : null;
}

export default React.memo(MapWithAutocomplete);
