import React from "react";
import { Box, Container } from "@mui/material";
import { SalonListingModal, AdminRightDrawer } from "@components";
import { DRAWER_WIDTH } from "../../constant";

const PrimaryLayout = ({ children }) => {
  return (
    <>
      <Box>
        <Box
          component={"main"}
          sx={{
            width: { md: `calc(100% - ${DRAWER_WIDTH}px)`, xs: "100%" },
            ml: { md: `${DRAWER_WIDTH}px`, sm: "0" },
            my: "30px",
          }}
        >
          <Container sx={{ maxWidth: "1400px !important" }}>{children}</Container>
        </Box>
        <AdminRightDrawer />
      </Box>
      <SalonListingModal />
    </>
  );
};

export default PrimaryLayout;

