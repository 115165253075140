import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";

import { CELL_PADDING_MUI } from "../../constants";
import useStore from "../../hooks/useStore";

const TodayTypo = ({ date, type = "week" }) => {
  const { view } = useStore();
  const condition = type === "week" ? "day" : "hour";
  const today = moment(date).isSame(moment(), condition);

  const dateFormate = (type) => {
    switch (type) {
      case "week":
        return "DD";
      default:
        return "H:m";
    }
  };

  return (
    <Box
      sx={{
        p: CELL_PADDING_MUI,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100% - 16px)",
      }}
    >
      <Typography
        style={{
          fontWeight: today ? "bold" : "inherit",
        }}
        color={today ? "primary" : "inherit"}
        fontSize="small"
      >
        {moment(date).format(dateFormate(view))}
      </Typography>
      {type === "week" && (
        <Typography
          color={today ? "primary" : "inherit"}
          style={{
            fontWeight: today ? "bold" : "inherit",
            fontSize: 11,
          }}
          noWrap
        >
          {moment(date).format("ddd")}
        </Typography>
      )}
    </Box>
  );
};

export default TodayTypo;
