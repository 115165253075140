import { Stack } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";

import { filterDataBySlotType, sortArrayByStartDateTime } from "../../helpers/generals";
import { useCellAttributes } from "../../hooks/useCellAttributes";
import AddBtn from "../common/AddBtn";
import Cell from "../common/Cell";
import CellWrapper from "../common/CellWrapper";
import EventCard from "../events/EventCard";

const WeekCell = ({ date, start, end, resourceParent, events, type, cellId, cellBgColor }) => {
  const props = useCellAttributes({ start, end, resources: resourceParent, type });

  const filterEventForEachDay = filterDataBySlotType({
    eventsData: events,
    date: moment(date).format(),
  });
  const sortedArray = sortArrayByStartDateTime(filterEventForEachDay);



  return (
    <CellWrapper
      xs={12 / 8}
      p={0.5}
      styles={{
        // backgroundImage: `linear-gradient(124deg, ${grey[300]} 28.26%, #ffffff 28.26%, #ffffff 50%, ${grey[300]} 50%, ${grey[300]} 78.26%, #ffffff 78.26%, #ffffff 100%)`,
        // backgroundSize: "15.00px 22.24px"
        background: cellBgColor,
      }}
    >
      <Cell
        droppableData={{
          id: cellId,
          data: {
            resourceId: resourceParent?._id,
            date,
            type,
            color: resourceParent?.color,
          },
        }}
        cellProps={props}
      >
        {!filterEventForEachDay?.length && <AddBtn />}
        {Boolean(filterEventForEachDay?.length) && (
          <Stack sx={{ width: "100%" }} spacing={1}>
            {sortedArray?.map((eachEvent) => (
              <EventCard
                key={eachEvent?.event_id}
                draggableData={{
                  id: eachEvent?.event_id,
                  data: {
                    event: eachEvent,
                    resourceParent: eachEvent?.resourceId,
                  },
                }}
                event={eachEvent}
              />
            ))}
          </Stack>
        )}
      </Cell>
    </CellWrapper>
  );
};

WeekCell.propTypes = {
  date: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  resourceParent: PropTypes.object,
  events: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  cellBgColor: PropTypes.string.isRequired,
};

export default WeekCell;


// {
//   start: '12:00',
//   end: '13:00',
//   title: 'Lunch break',
//   type: 'lunch',
//   recurring: {
//     repeat: 'weekly',
//     weekDays: 'MO,TU,WE,TH,FR',
//   },
// },