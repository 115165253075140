import React, { useMemo } from "react";

import PropTypes from "prop-types";

import { useCellAttributes } from "../../hooks/useCellAttributes";
import AddBtn from "../common/AddBtn";
import Cell from "../common/Cell";
import CellWrapper from "../common/CellWrapper";

const DayCell = React.memo(({ date, start, end, resourceParent, type, cellId, cellBgColor }) => {
  const props = useCellAttributes({ start, end, resources: resourceParent, type });

  const renderCells = useMemo(() => {
    return (
      <CellWrapper
        styles={{
          background: cellBgColor,
          p: 0.5,
        }}
        xs
      >
        <Cell
          droppableData={{
            id: cellId,
            data: {
              resourceId: resourceParent?._id,
              date,
              type,
              color: resourceParent?.color,
            },
          }}
          cellProps={props}
        >
          <AddBtn />
        </Cell>
      </CellWrapper>
    );
  }, [cellBgColor, cellId, date, resourceParent?._id]);

  return renderCells;
});
DayCell.displayName = "DayCell";
DayCell.propTypes = {
  date: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  resourceParent: PropTypes.object,
  events: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  cellId: PropTypes.string.isRequired,
  cellBgColor: PropTypes.string.isRequired,
};

export default DayCell;
