import { useState } from "react";

import { DragOverlay } from "@dnd-kit/core";
import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";

import AvailableShiftsSlot from "../components/availableShiftsSlot/availableShiftsSlot";
import CellWrapper from "../components/common/CellWrapper";
import ResourceInfoBox from "../components/common/ResourceInfoBox";
import TodayTypo from "../components/common/TodayTypo";
import EventCard from "../components/events/EventCard";
import SearchBar from "../components/searchBar/searchBar";
import UnassignedShiftsSlot from "../components/unassignedShiftsSlot/unassignedShiftsSlot";
import WeekCell from "../components/weekViewCompnents/weekCell";
import { DATE_FORMATE, TYPE_NORMAL } from "../constants";
import { getResourcedEvents, isToday } from "../helpers/generals";
import useStore from "../hooks/useStore";
import { StickyBox } from "../styles/styles";

const Week = () => {
  const { week, selectedDate, events, resources, draggedEvent, slots } = useStore();

  const { weekStartOn } = week;

  const _weekStart = moment(selectedDate).startOf("week").weekday(weekStartOn);

  const daysList = Array.from({ length: 7 }).map((_, index) => moment(_weekStart).add(index, "days")._d);

  const [filteredResources, setFilteredResources] = useState(resources);

  const handleSearchChange = (searchInput) => {
    const filtered = resources.filter((resource) => resource.name.toLowerCase().includes(searchInput.toLowerCase()));
    setFilteredResources(filtered);
  };

  const renderResourceAndCells = () => {
    return (
      <>
        <Typography my={1} ml={1} variant="body1">
          Scheduled shifts
        </Typography>

        {!filteredResources.length && <Typography textAlign="Center" py={5} variant="h5" children="No record found" />}
        {filteredResources.map((eachResource, i) => {
          return (
            <Grid container id="weekGrids" sx={{ flexWrap: "nowrap" }} key={i}>
              <ResourceInfoBox resource={eachResource} />

              {daysList.map((date, ii) => {
                const start = moment(date).format(DATE_FORMATE);
                const end = moment(start).add(1, "hour").format(DATE_FORMATE);
                return (
                  <WeekCell
                    key={ii}
                    date={date}
                    start={start}
                    end={end}
                    resourceParent={eachResource}
                    events={getResourcedEvents(events, eachResource?._id)}
                    type={TYPE_NORMAL}
                    cellId={`${start}_${eachResource._id}`}
                    cellBgColor={isToday(date) ? "" : grey[50]}
                  />
                );
              })}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderTable = () => {
    return (
      <Box sx={{ position: "relative;" }}>
        {/* Header days */}
        <StickyBox sx={{ zIndex: "8" }}>
          <Grid container sx={{ flexWrap: "nowrap" }}>
            <ResourceInfoBox children={<SearchBar onChange={(e) => handleSearchChange(e.target.value)} />} show={false} />
            {daysList.map((date, i) => (
              <CellWrapper key={i} xs={12 / 8}>
                <TodayTypo date={date} />
              </CellWrapper>
            ))}
          </Grid>
          {slots?.includes("unAssignedShiftsSlot") && <UnassignedShiftsSlot daysList={daysList} />}
          {slots?.includes("availableShiftsSlot") && <AvailableShiftsSlot daysList={daysList} />}
        </StickyBox>

        {renderResourceAndCells()}
        {draggedEvent && (
          <DragOverlay>
            {!!draggedEvent && (
              <EventCard
                draggableData={{
                  id: draggedEvent?.event_id,
                }}
                event={draggedEvent?.event}
                resourceParent={draggedEvent?.resourceParent}
                disable={true}
                dragging={draggedEvent}
              ></EventCard>
            )}
          </DragOverlay>
        )}
      </Box>
    );
  };

  return renderTable();
};

export default Week;
