import { useState } from "react";

import { Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon, DateCalendar } from "@mui/x-date-pickers";
import moment from "moment";

import useStore from "../../hooks/useStore";


const WeekDateBtn = () => {
  const { week, selectedDate, handleState } = useStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const { weekStartOn } = week;

  const weekStart = moment(selectedDate).startOf("week").weekday(weekStartOn);
  const weekEnd = moment(selectedDate).endOf("week");

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    handleState(e, "selectedDate");
    handleClose();
  };

  const handlePrev = () => {
    const lastDayPrevWeek = moment(weekStart).subtract(2, "day");
    handleState(lastDayPrevWeek, "selectedDate");
  };
  const handleNext = () => {
    const firstDayNextWeek = moment(weekEnd).add(1, "day");
    handleState(firstDayNextWeek, "selectedDate");
  };

  return (
    <Stack justifyContent="center" alignItems="center" direction="row">
      <IconButton onClick={handlePrev}>
        <ArrowLeftIcon />
      </IconButton>
      <Button style={{ padding: 4 }} onClick={handleOpen} aria-label="selected week">
        <Typography variant="h4" textTransform="none">
          {" "}
          {`${weekStart.format("DD")} - ${weekEnd.format("DD MMMM YYYY")}`}
        </Typography>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DateCalendar openTo="day" views={["month", "day"]} value={moment(selectedDate)} onChange={handleChange} />
      </Popover>
      <IconButton onClick={handleNext}>
        <ArrowRightIcon />
      </IconButton>
    </Stack>
  );
};

export default WeekDateBtn;
