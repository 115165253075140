import * as Yup from "yup";

const addEmployeeRules = (update) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),
    mobile: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(11, "Mobile number must be at least 11 digits"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    password:
      !update &&
      Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
  });

export default addEmployeeRules;
