import { useSnackbar } from "notistack";

export function useShowToast() {
  const { enqueueSnackbar } = useSnackbar();

  const displayToast = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  return displayToast
}
