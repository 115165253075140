import React from "react";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { TableContainer } from "@components";

const ClientProducts = () => {
  const dummyData = ["Brand", "Product", "Barcode", "Price", ""];
  return (
    <div>
      <Typography children="History" variant="h4" />
      <Box mt={2}>
        <TableContainer thContent={dummyData} size="large" isContent={true}>
          {dummyData.map((each, key) => (
            <TableRow key={key} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {dummyData.map((each, key) => (
                <TableCell key={key}>{each}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableContainer>
      </Box>
    </div>
  );
};

export default ClientProducts;

