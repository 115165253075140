export default async function validationHelper(formData, validationSchema) {
  try {
    await validationSchema.validate(formData, { abortEarly: false });
    return false; // No errors
  } catch (errors) {
    const validationErrors = {};

    errors.inner.forEach((error) => {
      validationErrors[error.path] = error.message;
    });

    return validationErrors; // Return the error object
  }
}
