import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TableRow, TableCell, Tooltip } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Header, TableContainer, InputField } from "@components";
import { PrimaryLayout, SaloonLayout } from "../../../layouts";
import { setLoader } from "src/store/reducer";
import { useDispatch } from "react-redux";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import apiManager from "../../../services/api-manger";
import moment from "moment";

const PackageListing = () => {

    const { saloonID } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const totalPages = Math.ceil(records?.length / perPage);
    const thLabels = ["Package Name", "Total Price", "After Discount", "Applied Discount", "Total Services Includes In Package", "Created At", "Actions"];

    const getAllPackages = async () => {
        try {
            setIsLoading(true);
            dispatch(setLoader(true));
            let { data } = await apiManager({ method: "get", path: `package/all/${saloonID}` });
            setRecords(data?.data);
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
        } finally {
            dispatch(setLoader(false));
            setIsLoading(false);
        }
    };

    const handleDelete = async (packageID) => {
        setIsLoading(true);
        dispatch(setLoader(true));
        try {
            const { data } = await apiManager({ method: "delete", path: `package/delete/${packageID}` });
            enqueueSnackbar(data?.message, { variant: "success" });
            getAllPackages();
        } catch (error) {
            enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
            setIsLoading(false);
        } finally {
            dispatch(setLoader(false));
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllPackages();
    }, [saloonID]);

    const renderRow = (item, index) => {
        return (
            <TableRow key={index}>
                <TableCell>{item?.packageName || '-'}</TableCell>
                <TableCell>{item?.totalPrice || '-'}</TableCell>
                <TableCell>{item?.totalDiscountPrice || '-'}</TableCell>
                <TableCell>{item?.totalDiscountPercentage + '%' || '-'}</TableCell>
                <TableCell>{item?.packageOptionAttributes?.length || '-'}</TableCell>
                <TableCell>{moment(item.createdAt).format('DD MMM YYYY')}</TableCell>
                <TableCell>
                    <Tooltip title="Update Details" placement="top">
                        <IconButton component={Link} to={`/saloon/${saloonID}/package/${item._id}`}>
                            <ModeEditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                        <IconButton onClick={() => handleDelete(item._id)}>
                            <DeleteForeverIcon color="error" />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <>
            <SaloonLayout navToLocation={(id) => navigate(`/saloon/${id}/packageListing`)}>
                <Header headerTitle={"Packages"} headerTitleIcon={<ContentPasteIcon />} />
                <PrimaryLayout>
                    <Grid container rowSpacing={3}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={9}>
                                <InputField
                                    label="Search packages"
                                    type="text"
                                    size="small"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Box component={Link} to={`/saloon/${saloonID}/package`}>
                                    <Button variant="contained" fullWidth startIcon={<AddIcon />}>ADD PACKAGE</Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer
                                thContent={thLabels.map((value, i) => <TableCell key={i} children={value} />)}
                                spanTd={thLabels.length}
                                page={page}
                                totalPages={totalPages}
                                callBack={setPage}
                                isContent={records.results?.length}
                            >
                                {records.results?.map((item, i) => {
                                    return <Fragment key={i} children={renderRow(item, i)} />;
                                })}
                            </TableContainer>
                        </Grid>
                    </Grid>
                </PrimaryLayout>
            </SaloonLayout>
        </>
    );
};

export default PackageListing;