import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Container, Divider, Fab, Grid, List, ListItem, ListItemText, Paper, Stack, TextField, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { KeyboardBackspace } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiManager from "../../../services/api-manger";
import { InputField, SearchInputMultiSelect, Header, ConfirmationModal } from "@components";
import Utils from "src/utils/utils";
import { helper, rules } from "src/validations";
import { PrimaryLayout, SaloonLayout, ServiceLayout } from "../../../layouts";
import { closeDrawer, setLoader } from "src/store/reducer";
import { useDispatch } from "react-redux";
import { MenuContext } from "src/context/MenuContext/Context";
import { useSnackbar } from "notistack";

const Package = () => {
  const { saloonID, packageID } = useParams();
  let { state, dispatch, getAllRecords } = useContext(MenuContext);

  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [formData, setFormData] = useState({
    saloonId: saloonID,
    packageOptionAttributes: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    const result = await helper(formData, rules.PackageRules());
    if (result) {
      setFormErrors(result);
      return;
    }
    setIsLoading(true);
    reduxDispatch(setLoader(true));
    try {
      const { data } = await apiManager({
        method: packageID ? "put" : "post",
        path: packageID ? `package/update/${packageID}` : "package/create",
        params: {
          ...formData,
          totalPrice: Utils.calculateTotalByKey(formData?.packageOptionAttributes, "price"),
          totalDiscountPercentage: Utils.calculateDiscountPercentage(
            Utils.calculateTotalByKey(formData?.packageOptionAttributes, "price"),
            Utils.calculateTotalByKey(formData?.packageOptionAttributes, "discountPrice")
          ),
          totalDiscountPrice: Utils.calculateTotalByKey(formData?.packageOptionAttributes, "discountPrice"),
        },
      });

      getAllRecords();
      navigate(`/saloon/${saloonID}/package/${data?.data?._id}`);
      enqueueSnackbar(data?.message, { variant: 'success' });
    } catch (error) {
      console.log("🚀 ~ file: Package.js:54 ~ handleSubmit ~ error:", error);
      if (error.status === 422) {
        setFormErrors(error?.errors);
      }
    } finally {
      reduxDispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  const getSinglePackage = async () => {
    setIsLoading(true);
    reduxDispatch(closeDrawer("right"));
    reduxDispatch(setLoader(true));
    try {
      const { data } = await apiManager({ method: "get", path: `package/single/${packageID}` });
      setFormData(data?.data);
    } catch (error) {
      console.log("🚀 ~ file: Package.js:67 ~ getSinglePackage ~ error:", error);
    } finally {
      reduxDispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  const handleServiceSelect = (value) => {
    setFormData((prev) => ({ ...prev, packageOptionAttributes: value }));
  };

  const handleDelete = async () => {
    setOpenDeleteModel(false);
    setIsLoading(true);
    reduxDispatch(setLoader(true));
    try {
      const { data } = await apiManager({ method: "delete", path: `package/delete/${packageID}` });
      enqueueSnackbar(data?.message, { variant: 'success' });
      setOpenDeleteModel(false);
      getAllRecords();
      navigate(`/saloon/${saloonID}/packageListing`);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      setIsLoading(false);
    } finally {
      reduxDispatch(setLoader(false));
      setIsLoading(false);
    };
  }

  const handleChangeForDiscountInput = (id, event) => {
    const { name, value } = event.target;
    const newInputValues = formData?.packageOptionAttributes?.map((input, index) => (index === id ? { ...input, [name]: value } : input));
    setFormData((prevData) => ({ ...prevData, packageOptionAttributes: newInputValues }));
  };

  const handleDeleteService = (index) => {
    const updatedServiceData = [...formData.packageOptionAttributes];
    updatedServiceData.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, packageOptionAttributes: updatedServiceData }));
  };

  useEffect(() => {
    if (packageID) {
      getSinglePackage();
    }
    dispatch({
      type: "SET_SCREEN_TYPE",
      payload: true,
    });

    return () => {
      setFormData({
        saloonId: saloonID,
        packageOptionAttributes: [],
      });
    };
  }, [packageID]);

  return (
    // <ServiceLayout packageScreen={true}>
    <SaloonLayout navToLocation={(id) => navigate(`/saloon/${id}/packageListing`)}>
      <Header headerTitle={`${packageID ? 'Update' : 'Create'} Package`} headerTitleIcon={<GroupIcon />} />
      <PrimaryLayout>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} container>
            <Container maxWidth={"md"}>
              <Stack sx={{ position: 'relative' }}>
                <Fab
                  size="small"
                  color="primary"
                  sx={{ position: 'absolute', left: 0, top: -10 }}
                  onClick={() => navigate(-1)}
                >
                  <KeyboardBackspace />
                </Fab>
                <Typography
                  variant="h4"
                  align="center"
                  children={`${packageID ? 'Update' : 'Create'} Package`}
                />
                {packageID && (
                  <Button
                    startIcon={<DeleteForeverIcon />}
                    color="error"
                    onClick={() => setOpenDeleteModel(true)}
                    sx={{ position: "absolute", top: 0, right: 0 }}
                  >
                    DELETE
                  </Button>
                )}
              </Stack>
            </Container>
          </Grid>
          <Grid item xs={12} container>
            <Container maxWidth={"md"}>
              <Paper sx={{ padding: 3 }} elevation={8} component={"form"} onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {`${packageID ? "Update" : "Add"} Package`}
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputField
                      label="Package Name"
                      required
                      fullWidth
                      name="packageName"
                      value={formData.packageName}
                      error={formErrors?.packageName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      label="Add Description"
                      value={formData.description}
                      name="description"
                      onChange={handleChange}
                      multiline
                      rows={5}
                      fullWidth
                      placeholder="Type the description here"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SearchInputMultiSelect
                      apiUrl={`service/all/modified/${saloonID}`}
                      label={"Add services"}
                      callBack={handleServiceSelect}
                      value={formData.packageOptionAttributes}
                      error={formErrors.packageOptionAttributes}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DiscountCard
                      serviceData={formData.packageOptionAttributes}
                      handleChange={handleChangeForDiscountInput}
                      handleDeleteService={handleDeleteService}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack alignItems={"center"}>
                      <Stack direction={"row"} width={"50%"} justifyContent="space-between">
                        <Typography variant="h5">Total price</Typography>
                        <Typography variant="h5">${Utils.calculateTotalByKey(formData?.packageOptionAttributes, "price")}</Typography>
                      </Stack>
                      <Stack direction={"row"} width={"50%"} justifyContent="space-between">
                        <Typography fontSize="1.1rem" color="primary.text">
                          Retail price
                        </Typography>
                        <Typography color="primary.text" fontSize="1.1rem">
                          ${Utils.calculateTotalByKey(formData?.packageOptionAttributes, "price")}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} width={"50%"} justifyContent="space-between">
                        <Typography color="primary.text" fontSize="1.1rem">
                          Discount price
                        </Typography>
                        <Typography color="primary.text" fontSize="1.1rem">
                          ${Utils.calculateTotalByKey(formData?.packageOptionAttributes, "discountPrice")}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} width={"50%"} justifyContent="space-between">
                        <Typography color="primary.text" fontSize="1.1rem">
                          Discount applied
                        </Typography>
                        <Typography color="primary.text" fontSize="1.1rem">
                          {Utils.calculateDiscountPercentage(
                            Utils.calculateTotalByKey(formData?.packageOptionAttributes, "price"),
                            Utils.calculateTotalByKey(formData?.packageOptionAttributes, "discountPrice")
                          )}
                          %
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" type="submit" fullWidth sx={styles.continueButton}>
                      CONTINUE
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </Grid>
        </Grid>
      </PrimaryLayout>
      <ConfirmationModal open={openDeleteModel} callBack={handleDelete} handleClose={() => setOpenDeleteModel(false)} />
      {/* </ServiceLayout> */}
    </SaloonLayout>
  );
};

const DiscountCard = ({
  serviceData,
  handleChange,
  handleDeleteService
}) => {
  return (
    <>
      {serviceData?.map((service, index) => {
        return (
          <Paper key={service?.name} component={List} elevation={3} sx={styles.listItemPaper}>
            <ListItem disablePadding secondaryAction={
              <DeleteForeverIcon
                color="error"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDeleteService(index)}
              />
            }>
              <ListItemText primary={service?.name} />
            </ListItem>
            <Divider />
            <ListItem disablePadding disableGutters secondaryAction={<ListItemText sx={{ pr: 2 }} primary={`$${service?.price}`} />}>
              <ListItemText primary="Retail price" />
            </ListItem>
            <ListItem
              disablePadding
              disableGutters
              secondaryAction={
                <Box sx={{ minWidth: "150px" }}>
                  <InputField
                    label="Discount Price"
                    onChange={(e) => handleChange(index, e)}
                    type="number"
                    value={service?.discountPrice}
                    name="discountPrice"
                    max={service?.price}
                    fullWidth={true}
                  />
                </Box>
              }
            >
              <ListItemText primary="Discount Price" />
            </ListItem>
            <Stack>
              <Typography variant="caption" sx={{ textAlign: "right" }} mt={1}>
                Discount applied: {Utils.calculateDiscountPercentage(service?.price, service?.discountPrice)}%
              </Typography>
            </Stack>
          </Paper>
        )
      })}
    </>
  );
};

const styles = {
  container: {
    maxWidth: "750px !important",
  },
  accordionHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  buttonAbsolute: {
    position: "absolute",
    top: 10,
    right: 15,
  },
  listItemPaper: {
    p: 2.5,
    px: 3,
    my: 1,
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 1.2,
  },
  discountCard: {
    border: "1px solid",
    borderRadius: 2,
    p: 2,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default Package;
