import React, { useEffect, useRef, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WarningIcon from "@mui/icons-material/Warning";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Header, ContainerWithTopHeading, InputField, SelectBox, ConfirmationModal } from "@components";
import { PrimaryLayout, ClientLayout } from "../../../layouts";
import apiManager from "../../../services/api-manger";
import { MainGridRowSpacing } from "../../../constant";
import { CenterSubmitBtn } from "@components";
import { closeDrawer, setLoader } from "src/store/reducer";

const Client = () => {
  const { user } = useSelector((state) => state.appReducer);
  const dispatch = useDispatch();
  const clientLayoutRef = useRef();
  const { clientID, saloonID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showBtn, setShowBtn] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const initialSate = {
    ownerId: user?._id,
    creatorId: user?._id,
    salonId: saloonID,
    fullName: "",
    email: "",
    mobileNumber: "",
    countryCode: "",
    individual: true,
    address: "",
    city: "",
    gender: "",
  };

  const [formData, setFormData] = useState(initialSate);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setShowBtn(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({});
    setIsLoading(true);
    dispatch(setLoader(true));
    const { dateOfBirth } = formData;

    const formattedDateOfBirth = moment(dateOfBirth).format("YYYY-MM-DD");
    try {
      const method = clientID ? "put" : "post";
      const path = method === "put" ? `client/update/${clientID}` : "client/create";
      const params = {
        ...formData,
        dateOfBirth: formattedDateOfBirth,
      };
      let { data } = await apiManager({ method, path, params });
      if (clientLayoutRef.current) {
        clientLayoutRef.current.getClients();
      }
      // setFormData(initialSate);
      navigate(`/saloon/${saloonID}/client/${data?.data?._id}`);
      setShowBtn(false);
    } catch (error) {
      setFormErrors(error?.response?.data?.data);
      if (error?.response.status !== 422) {
        enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      }
    } finally {
      dispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  const getSingleClient = async () => {
    setIsLoading(true);
    dispatch(setLoader(true));
    dispatch(closeDrawer("right"));
    try {
      const { data } = await apiManager({ method: "get", path: `client/get-single/${clientID}` });
      setFormData(data?.data);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    } finally {
      dispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    dispatch(setLoader(true));
    try {
      const { data } = await apiManager({ method: "delete", path: `client/delete/${clientID}` });
      navigate(`/saloon/${saloonID}/addClient`);
      if (clientLayoutRef.current) {
        clientLayoutRef.current.getClients();
      }
      setOpenDeleteModel(false);
      setFormData(initialSate);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      if (clientLayoutRef.current) {
        clientLayoutRef.current.getClients();
      }
      setIsLoading(false);
    } finally {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    // Call the getClients function from the ClientLayout component
    if (clientLayoutRef.current) {
      clientLayoutRef.current.getClients();
    }
  }, [clientLayoutRef]);

  useEffect(() => {
    if (clientID) {
      getSingleClient();
    }
    // Reset form data to its initial state
    setFormData(initialSate);
  }, [clientID, user?._id, saloonID]);

  return (
    <>
      <ClientLayout ref={clientLayoutRef}>
        <Header
          headerTitle={clientID ? "Update Client" : "Create Client"}
          headerTitleIcon={<PersonIcon />}
          showMoreButtons={
            clientID && [
              {
                visible: true,
                button_title: "History",
                button_startIcon: <ShoppingCartIcon />,
                button_endIcon: false,
                button_link: `/saloon/${saloonID}/clientHistory/${clientID}`,
              },
              {
                visible: true,
                button_title: "Delete",
                button_startIcon: <WarningIcon sx={{ color: "red" }} />,
                button_endIcon: false,
                button_link: "/",
                onClick: () => setOpenDeleteModel(true),
              },
            ]
          }
        />
        <PrimaryLayout>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid container rowSpacing={MainGridRowSpacing}>
              <Grid item xs={12}>
                <ContainerWithTopHeading title="Personal Details">
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="individual"
                          value={formData.individual}
                          onChange={(e) => {
                            setFormData((prevData) => ({ ...prevData, individual: e.target.value === "true" ? true : false }));
                            setShowBtn(true);
                          }}
                        >
                          <FormControlLabel value="true" label="Company" control={<Radio />} />
                          <FormControlLabel value="false" label="Individual" control={<Radio />} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="FULL NAME"
                        required
                        fullWidth={true}
                        type="text"
                        size="large"
                        name="fullName"
                        value={formData.fullName}
                        error={formErrors?.fullName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="EMAIL"
                        required
                        fullWidth={true}
                        type="email"
                        size="large"
                        name="email"
                        value={formData.email}
                        error={formErrors?.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="ADDRESS"
                        required
                        fullWidth={true}
                        size="large"
                        type="text"
                        name="address"
                        value={formData.address}
                        error={formErrors?.address}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="Country Code"
                        required
                        fullWidth={true}
                        size="large"
                        type="number"
                        name="countryCode"
                        value={formData?.countryCode}
                        error={formErrors?.countryCode}
                        onChange={handleChange}
                        maxLength={4}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="PHONE NUMBER"
                        required
                        fullWidth={true}
                        size="large"
                        name="mobileNumber"
                        type="number"
                        value={formData?.mobileNumber}
                        error={formErrors?.mobileNumber}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputField
                        label="CITY"
                        required
                        fullWidth={true}
                        size="large"
                        type="text"
                        name="city"
                        value={formData.city}
                        error={formErrors?.city}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectBox
                        label="GENDER"
                        size="large"
                        required
                        name="gender"
                        value={formData.gender}
                        error={formErrors?.gender}
                        onChange={handleChange}
                        items={[
                          { label: "Male", value: "male" },
                          { label: "Female", value: "female" },
                          { label: "Gender-Neutral", value: "gender-neutral" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Select DOB"
                        // views={['day', 'month', 'year']}
                        value={moment(formData.dateOfBirth)}
                        onChange={(e) => {
                          const selectedUnixTimestamp = moment(e).unix();
                          setFormData((prev) => ({ ...prev, dateOfBirth: selectedUnixTimestamp * 1000 }));
                          setShowBtn(true);
                        }}
                        slotProps={{
                          textField: {
                            required: true,
                            fullWidth: true,
                            size: "medium",
                          },
                        }}
                        maxDate={moment()}
                      />
                      <FormHelperText sx={{ color: "red", mt: "0 !important" }}>{formErrors?.dateOfBirth}</FormHelperText>
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>

              {showBtn && (
                <CenterSubmitBtn variant="contained" type="submit" isLoading={isLoading}>
                  {clientID ? "UPDATE" : "CREATE"}
                </CenterSubmitBtn>
              )}
            </Grid>
          </Box>
        </PrimaryLayout>
      </ClientLayout>
      <ConfirmationModal open={openDeleteModel} callBack={handleDelete} handleClose={() => setOpenDeleteModel(false)} />
    </>
  );
};

export default Client;
