import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

const ConfirmationModal = ({ title, open, children, hideFooter = false, handleClose, callBack = () => { } }) => {
  const isYesButtonDisabled = !!children; // Disable the "Yes" button if children are present
  return (
    <Dialog title={title} open={open} handleClose={handleClose}>
      <Box>
        {!!children ? (
          children
        ) : (
          <>
            <DialogTitle>ATTENTION</DialogTitle>
            <DialogContent>
              <Typography variant="span" sx={{ textAlign: "center" }}>
                Are you sure you want to delete?
              </Typography>
            </DialogContent>
          </>
        )}
        {!hideFooter && (
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              sx={{ color: "white" }}
              onClick={callBack}
              disabled={isYesButtonDisabled}
            >
              Yes
            </Button>
            <Button variant="contained" color="error" onClick={() => handleClose()}>
              No
            </Button>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
