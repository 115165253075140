import * as Yup from "yup";

const serviceRules = (update) =>
  Yup.object().shape({
    serviceName: Yup.string()
      .required("Name is required")
      .min(6, "Name must be at least 6 characters")
      .max(30, "Name must be most 6 characters"),
    shortName: Yup.string()
      .required("Short Name is required")
      .min(1, "Short Name must be at least 2 characters")
      .max(3, "Short Name must be most  3 characters"),
    venueTreatments: Yup.array().of(
      Yup.object({
        price: Yup.number()
          .required("Price is required")
          .positive("Price must be a positive number"),
        name: Yup.string()
          .min(5, "Name must be at least 5 characters")
          .max(10, "Name must be most 10 characters"),
      }),
    ),
  });

export default serviceRules;
