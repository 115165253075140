import { createTheme } from "@mui/material/styles";

function linearGradient(color, colorState, angle = 195) {
  return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
}

function pxToRem(number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}
const elevationShadows = [
  "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
  "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
  "0px 6px 10px 0px rgba(0, 0, 0, 0.08)",
  "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
  "0px 12px 24px 0px rgba(0, 0, 0, 0.08)",
  "0px 16px 32px 0px rgba(0, 0, 0, 0.08)",
  "0px 24px 48px 0px rgba(0, 0, 0, 0.08)",
  "0px 32px 64px 0px rgba(0, 0, 0, 0.08)",
];

const theme = createTheme({
  functions: {
    linearGradient,
    pxToRem,
  },
  typography: {
    fontFamily: "'Poppins', sans-serif;",
    h1: {
      fontWeight: 600,
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
      fontFamily: "'Poppins', sans-serif;",
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.875rem",
      lineHeight: "2.25rem",
      fontFamily: "'Poppins', sans-serif;",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
      fontFamily: "'Poppins', sans-serif;",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.3125rem",
      lineHeight: "1.6rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: "1.6rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "1.2rem",
    },
    button: {
      textTransform: "capitalize",
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.334rem",
    },
    body2: {
      fontSize: "0.75rem",
      letterSpacing: "0rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    caption: {
      color: " rgb(142, 147, 171)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },

    MuiPaper: {
      styleOverrides: elevationShadows.reduce((overrides, shadow, index) => {
        overrides[`elevation${index}`] = {
          boxShadow: shadow,
        };
        return overrides;
      }, {}),
    },
    MuiCssBaseline: {
      styleOverrides: {
        ".MuiPaper-elevation9, .MuiPopover-root .MuiPaper-elevation": {
          boxShadow: "rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
        },
      },
    },
  },
});

export { theme };
export const APP_REDUCER = "appReducer";
export const DRAWER_WIDTH = 300;
export const RIGHT_DRAWER_WIDTH = 260;
export const headerHeight = "100px";
export const MOBILE_BREAK_POINT = "(min-width:900px)";
export const inputGridSpacing = 2;
export const MainGridRowSpacing = 5;
export const timeOptions = [
  // { label: "0:00", value: 0 },
  { label: "0:15", value: 900 },
  { label: "0:30", value: 1800 },
  { label: "0:45", value: 2700 },
  { label: "1:00", value: 3600 },
  { label: "1:15", value: 4500 },
  { label: "1:30", value: 5400 },
  { label: "1:45", value: 6300 },
  { label: "2:00", value: 7200 },
  { label: "2:15", value: 8100 },
  { label: "2:30", value: 9000 },
  { label: "2:45", value: 9900 },
  { label: "3:00", value: 10800 },
  { label: "3:15", value: 11700 },
  { label: "3:30", value: 12600 },
];
export const weekTimings = [
  { day: "sunday", startTime: 0, endTime: 0, close: true },
  { day: "monday", startTime: 1701370800, endTime: 1701403200, close: false },
  { day: "tuesday", startTime: 1701370800, endTime: 1701403200, close: false },
  { day: "wednesday", startTime: 1701370800, endTime: 1701403200, close: false },
  { day: "thursday", startTime: 1701370800, endTime: 1701403200, close: false },
  { day: "friday", startTime: 1701370800, endTime: 1701403200, close: false },
  { day: "saturday", startTime: 1701370800, endTime: 1701403200, close: false },
];
