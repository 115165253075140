import { createSlice } from "@reduxjs/toolkit";

export const reducer = createSlice({
  name: "appReducer",
  initialState: {
    user: null,
    saloonInfo: null,
    isLogged: false,
    loader: false,
    openSideDrawer: false,
    openRightDrawer: false,
    openSalonLIstingModal: false,
  },

  reducers: {
    setUser: (state, { payload }) => {
      state.isLogged = true;
      state.user = payload;
    },
    setSaloonInfo: (state, { payload }) => {
      state.saloonInfo = payload;
    },
    setLogged: (state) => {
      state.isLogged = true;
    },
    setLoader: (state, { payload }) => {
      state.loader = payload;
    },
    setOpenDrawer: (state, { payload }) => {
      if (payload === "right") {
        state.openRightDrawer = true;
      }
      state.openSideDrawer = true;
    },
    closeDrawer: (state, { payload }) => {
      if (payload === "right") {
        state.openRightDrawer = false;
      }
      state.openSideDrawer = false;
    },
    setSalonLIstingModal: (state) => {
      state.openSalonLIstingModal = true;
    },
    closeSalonLIstingModal: (state) => {
      state.openSalonLIstingModal = false;
    },
    logoutUser: (state) => {
      state.user = null;
      state.isLogged = false;
    },
  },
});

export const { setLogged, setOpenDrawer, closeDrawer, closeSalonLIstingModal, setSalonLIstingModal, setUser, setSaloonInfo, logoutUser, setLoader } =
  reducer.actions;

export default reducer.reducer;
