import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { Header } from "@components";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link, useParams } from "react-router-dom";
import { PrimaryLayout, EmployeeLayout } from "../../../layouts";
import { AddProfile } from "../../../assets";

const EmployeeListing = () => {
    const { saloonID } = useParams();
    return (
        <>
            <EmployeeLayout>
                <Header />
                <PrimaryLayout>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 4,
                    }}>
                        <Avatar sx={{ width: "20%", height: "10%" }} variant="square" src={AddProfile} />
                        <Typography sx={{ fontSize: 25, color: "grey.300" }}>Add New Employee Or Edit current One</Typography>
                        <Box component={Link} to={`/saloon/${saloonID}/staff_members/add_member`}>
                            <Button variant="contained" startIcon={<PersonAddIcon />}>Add Employee</Button>
                        </Box>
                    </Box>
                </PrimaryLayout>
            </EmployeeLayout>
        </>
    )
}

export default EmployeeListing;