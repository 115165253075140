import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";

import { DATE_FORMATE, TYPE_AVAILABLE } from "../../constants";
import { filterDataByProvidedDate } from "../../helpers/generals";
import useStore from "../../hooks/useStore";
import CellWrapper from "../common/CellWrapper";
import RenderCel from "../common/RenderCell";
import ResourceInfoBox from "../common/ResourceInfoBox";
import DayCell from "../dayViewComponent/DayCell";
import DayEvents from "../dayViewComponent/DayEvents";

const AvailableShiftsSlot = ({ daysList }) => {
  const { events, view } = useStore();
  const filterEvent = events.filter((each) => !each.resourceId && each.available);

  const filterDataByDate = filterDataByProvidedDate(filterEvent, moment());

  return (
    <>
      <Typography py={1} ml={1} variant="body1" color={green[500]}>
        Available shifts
      </Typography>

      {view === "day" ? (
        <Box sx={{ display: "flex"}}>
          <ResourceInfoBox show={false} bgColor={green[100]} />
          <Grid
            sx={{
              position: "relative",
              width: "fit-content",
              flexGrow: 1,
            }}
            container
            wrap="nowrap"
            id="day-view-row"
          >
            <DayEvents events={filterDataByDate} hours={daysList} />
            {daysList.map((date, ii) => {
              return (
                <DayCell
                  key={ii}
                  date={date}
                  start={moment(date).format(DATE_FORMATE)}
                  end={moment(date).add(1, "hour").format(DATE_FORMATE)}
                  cellId={`${date}_${TYPE_AVAILABLE}`}
                  cellBgColor={green[100]}
                  type={TYPE_AVAILABLE}
                  hours={daysList}
                />
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Grid container sx={{ flexWrap: "nowrap" }}>
          <ResourceInfoBox show={false} bgColor={green[100]} />
          {daysList.map((date, ii) => {
            const start = moment(date).format(DATE_FORMATE);
            const end = moment(start).add(1, "hour").format(DATE_FORMATE);
            return (
              <RenderCel
                key={ii}
                date={date}
                minuteHeight={20}
                start={start}
                end={end}
                data={filterEvent}
                type={TYPE_AVAILABLE}
                cellId={`${start}_${TYPE_AVAILABLE}`}
                cellBgColor={green[100]}
              />
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default AvailableShiftsSlot;
