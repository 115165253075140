import React from "react";
import { Box, Grid } from "@mui/material";
import { SelectBox, StyledTab, StyledTabs, TabContent, TimelineWithCard } from "@components";

const ClientAppointments = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box mt={2}>
      <Grid container alignItems="baseline" spacing={2}>
        <Grid item md={6} xs={12}>
          <Box sx={{ maxWidth: { md: "300px", xs: "100%" }, width: "100%" }}>
            <SelectBox items={[]} label="Category" size="large" />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <StyledTabs onChange={handleChange} value={value} variant="fullWidth" textColor="secondary">
            <StyledTab label="Booked" value="1" />
            <StyledTab label="No Shows" value="2" />
            <StyledTab label="Cancelled" value="3" />
          </StyledTabs>
        </Grid>
      </Grid>
      <Box>
        <TabContent value="1" sx={{ p: 0, mt: 2 }} index={value}>
          <div className="tab-panel-content">
            <TimelineWithCard />{" "}
          </div>
        </TabContent>
        <TabContent value="2" sx={{ p: 0 }} index={value}>
          <div className="tab-panel-content">Products</div>
        </TabContent>
        <TabContent value="3" sx={{ p: 0 }} index={value}>
          Cancelled
        </TabContent>
      </Box>
    </Box>
  );
};

export default ClientAppointments;

