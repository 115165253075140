import React, { Fragment } from "react";

import { AppBar, Box, Grid, Typography } from "@mui/material";
import { grey, lightBlue } from "@mui/material/colors";
import moment from "moment";

import { CELL_HEIGHT, DATE_FORMATE, TYPE_UNASSIGNED } from "../../constants";
import { filterDataByProvidedDate, isToday } from "../../helpers/generals";
import useStore from "../../hooks/useStore";
import { StickyBox } from "../../styles/styles";
import CellWrapper from "../common/CellWrapper";
import RenderCel from "../common/RenderCell";
import ResourceInfoBox from "../common/ResourceInfoBox";
import DayCell from "../dayViewComponent/DayCell";
import DayEvents from "../dayViewComponent/DayEvents";

const UnassignedShiftsSlot = ({ daysList }) => {
  const { events, view } = useStore();
  const filterEvent = events.filter((each) => !each.resourceId && !each.available);

  const filterDataByDate = filterDataByProvidedDate(filterEvent, moment());

  return (
    <>
      <Typography py={1} ml={1} variant="body1" color={lightBlue[700]}>
        Unassigned shifts
      </Typography>

      {view === "day" ? (
        <Box sx={{ display: "flex" }}>
          <ResourceInfoBox show={false} bgColor={lightBlue[50]} />
          <Grid
            sx={{
              position: "relative",
              width: "fit-content",
              flexGrow: 1,
            }}
            container
            wrap="nowrap"
            id="day-view-row"
          >
            <DayEvents events={filterDataByDate} hours={daysList} />
            {daysList.map((date, ii) => {
              return (
                <Fragment key={ii}>
                  <DayCell
                    date={date}
                    start={moment(date).format(DATE_FORMATE)}
                    end={moment(date).add(1, "hour").format(DATE_FORMATE)}
                    type={TYPE_UNASSIGNED}
                    cellId={`${date}_${TYPE_UNASSIGNED}`}
                    cellBgColor={lightBlue[50]}
                    hours={daysList}
                  />
                </Fragment>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Grid container sx={{ flexWrap: "nowrap" }}>
          <ResourceInfoBox show={false} bgColor={lightBlue[50]} />
          {daysList.map((date, ii) => {
            const start = moment(date).format(DATE_FORMATE);
            const end = moment(start).add(1, "hour").format(DATE_FORMATE);
            return (
              <Fragment key={ii}>
                <RenderCel
                  date={date}
                  start={start}
                  end={end}
                  data={filterEvent}
                  type={TYPE_UNASSIGNED}
                  cellId={`${start}_${TYPE_UNASSIGNED}`}
                  cellBgColor={lightBlue[50]}
                />
              </Fragment>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default UnassignedShiftsSlot;
