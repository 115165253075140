import { forwardRef, useEffect } from "react";

import moment from "moment";

import SchedulerComponent from "./SchedulerComponent";
import useStore from "./hooks/useStore";

const Scheduler = forwardRef(function Scheduler(props, ref) {
  useEffect(() => {
    moment.updateLocale("en", {
      week: {
        dow: 1,
      },
    });
  }, []);

  return (
    // <DndContext modifiers={[restrictToFirstScrollableAncestor]} sensors={sensors}>
    //   <StoreProvider initial={props}>
    <SchedulerComponent ref={ref} />
    //   </StoreProvider>
    // </DndContext>
  );
});

export { Scheduler, useStore };

// function customCollisionDetectionAlgorithm(args) {
//   // First, let's see if there are any collisions with the pointer
//   const pointerCollisions = pointerWithin(args);

//   // Collision detection algorithms return an array of collisions
//   // if (pointerCollisions.length > 0) {
//   //   return pointerCollisions;
//   // }

//   // If there are no collisions with the pointer, return rectangle intersections
//   return rectIntersection(args);
// }
