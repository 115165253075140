import { Tabs, styled } from "@mui/material";
import React from "react";

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)({
  "& .MuiTabs-indicator": {
    display: "none",
  },

  borderRadius: "100vw",
  backgroundColor: "rgba(0, 0, 0, 0.15)",
  padding: "2px",
});

export default StyledTabs;
