import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Fab, FormControl, FormHelperText, Grid, Stack, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ContainerWithTopHeading, ImagePicker, InputField, Header, SelectBox, SevenDayTimeSelector, MapProvider } from "@components";
import { MainGridRowSpacing, inputGridSpacing, weekTimings } from "../../../constant";
import { PrimaryLayout, SaloonLayout } from "../../../layouts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setLoader, setSaloonInfo } from "src/store/reducer";
import { CenterSubmitBtn } from "@components";
import { City, Country, State } from "country-state-city";
import { helper, rules } from "src/validations";
import apiManager from "../../../services/api-manger";
import useLocation from "../../../hooks/use-location";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import ContentCutIcon from "@mui/icons-material/ContentCut";

const Saloon = () => {
  const { user } = useSelector((state) => state.appReducer);
  const { saloonID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [location, hasPermission] = useLocation();
  const [formErrors, setFormErrors] = useState({});
  const [showBtn, setShowBtn] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const default_LAT_LONG = {
    latitude: 24.843532323608336,
    longitude: 67.05422737301245,
  }
  const initialState = {
    ownerId: user?._id,
    address: {
      latitude: default_LAT_LONG.latitude,
      longitude: default_LAT_LONG.longitude,
      country: { label: 'Pakistan', value: "PK" },
      state: null,
      city: null,
    },
    timings: weekTimings,
  };
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setShowBtn(true);
  };

  const getSaloonData = async () => {
    try {
      dispatch(setLoader(true));
      const { data } = await apiManager({ method: "get", path: `saloons/get-single/${saloonID}` });
      setFormData((prevData) => ({
        ...prevData,
        ...data?.data,
        timings: data?.data.timings.length ? data?.data.timings : initialState.timings
      }));
    } catch (error) {
      console.log("ParlourCrud getData error:", error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoader(true));
    setFormErrors({});
    const result = await helper(formData, rules.saloonRules(saloonID));
    if (result) {
      setFormErrors(result);
      dispatch(setLoader(false));
      return;
    }
    const { phone, taxCode } = formData;
    const convertedPhone = parseInt(phone);
    const convertedTaxCode = parseInt(taxCode);
    const numericRegex = /^[0-9]+$/;
    if (!numericRegex.test(phone)) {
      setFormErrors({ phone: isNaN(convertedPhone) || convertedPhone < 0 ? "Invalid phone number" : null, });
      dispatch(setLoader(false));
      return;
    }
    if (!numericRegex.test(taxCode)) {
      setFormErrors({ taxCode: isNaN(convertedTaxCode) || convertedTaxCode < 0 ? "Tax code must be a positive number" : null });
      dispatch(setLoader(false));
      return;
    }
    const { city, state, country, latitude, longitude } = formData.address;
    if (!latitude || !longitude) {
      setFormData((prevData) => ({ ...prevData, address: { ...prevData.address, latitude: default_LAT_LONG.latitude, longitude: default_LAT_LONG.longitude, }, }));
      dispatch(setLoader(false));
      return;
    }
    const method = saloonID ? "put" : "post";
    const path = method === "put" ? `saloons/update/${saloonID}` : "saloons";
    const params = {
      ...formData,
      address: {
        ...formData.address,
        city: city.label || city,
        state: state.label || state,
        country: country.label || country,
      },
    };
    try {
      const { data } = await apiManager({ method, path, params });
      dispatch(setSaloonInfo(data?.data));
      enqueueSnackbar(data?.message, { variant: "success" });
      navigate(`/saloonProfile/${data?.data?._id}`);
    } catch (error) {
      setFormErrors(error?.response?.data?.data);
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    } finally {
      dispatch(setLoader(false));
    }
  };

  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({ label: country.name, value: country.isoCode, ...country }));
  const updatedStates = (countryId) => State.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.isoCode, ...state }));
  const updatedCities = (countryId, stateId) => City.getCitiesOfState(countryId, stateId).map((city) => ({ label: city.name, value: city.label, ...city }))

  useEffect(() => {
    if (!saloonID) setFormData({
      ...formData,
      address: {
        ...formData.address,
        latitude: location?.lat,
        longitude: location?.lng,
      },
    })
  }, [location]);

  useEffect(() => {
    if (saloonID) { getSaloonData() };
    return () => {
      setFormData(initialState);
      setFormErrors({});
    };
  }, [saloonID]);

  useEffect(() => {
    setShowBtn(true);
  }, [formData]);

  const renderAutocomplete = (id, label, options, value, onChange, error) => (
    <Autocomplete
      id={id}
      name={id}
      fullWidth
      options={options}
      value={value}
      onChange={(event, value) => onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          label={label}
          variant="outlined"
          fullWidth
          helperText={error}
        />
      )}
    />
  );

  return (
    <>
      <SaloonLayout
        navToLocation={(id) => {
          navigate(`/saloonProfile/${id}`);
          // navigate(saloonID ? `/saloon/${id}/update` : `/saloonProfile/${id}`);
        }}
      >
        <Header headerTitle={saloonID ? "Parlour details" : "Create Parlour"} headerTitleIcon={<ContentCutIcon />} openDrawerBtn={!!saloonID} />
        <PrimaryLayout>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid item xs={12} pb={2}>
              <Fab size="small" color="primary" onClick={() => navigate(-1)}>
                <KeyboardBackspaceIcon />
              </Fab>
            </Grid>
            <Grid container rowSpacing={MainGridRowSpacing} pb={8}>
              {/* Salon Information */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title={"Salon Information"}>
                  <Grid container spacing={inputGridSpacing}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <InputField
                        label="Name of salon"
                        type="text"
                        required
                        name="name"
                        value={formData.name}
                        error={formErrors?.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={8} sm={6} xs={12}>
                      <InputField
                        label="Location address"
                        type="text"
                        required
                        name="location"
                        value={formData?.address?.location}
                        error={formErrors?.address?.location}
                        onChange={(e) => setFormData((prevData) => ({ ...prevData, address: { ...prevData?.address, location: e.target.value } }))}
                      />
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      {renderAutocomplete(
                        "country",
                        "Country",
                        updatedCountries,
                        formData?.address?.country,
                        (value) => setFormData((prevData) => ({ ...prevData, address: { ...prevData?.address, country: value, state: null, city: null } })),
                        formErrors?.["address.country"],
                      )}
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      {renderAutocomplete(
                        "state",
                        "State",
                        updatedStates(formData?.address.country ? formData?.address.country.value : null),
                        formData?.address?.state,
                        (value) => setFormData((prevData) => ({ ...prevData, address: { ...prevData?.address, state: value, city: null } })),
                        formErrors?.["address.state"],
                      )}
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      {renderAutocomplete(
                        "city",
                        "City",
                        updatedCities(formData?.address.state ? formData?.address.state.countryCode : null, formData?.address.state ? formData?.address.state.isoCode : null),
                        formData?.address?.city,
                        (value) => setFormData((prevData) => ({ ...prevData, address: { ...prevData?.address, city: value } })),
                        formErrors?.["address.city"],
                      )}
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12}>
                      <InputField
                        label="Zip/postal code"
                        type="text"
                        required
                        name="zipCode"
                        value={formData?.address?.zipCode}
                        error={formErrors?.["address.zipCode"]}
                        onChange={(e) => setFormData((prevData) => ({ ...prevData, address: { ...prevData?.address, zipCode: e.target.value } }))}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography mb={1}>Pin Location</Typography>
                        {formErrors?.mapLocation && <Typography sx={{ color: "red" }}>{formErrors?.mapLocation}</Typography>}
                        <MapProvider
                          height="400px"
                          coords={{
                            lat: formData?.address?.latitude || default_LAT_LONG.latitude,
                            lng: formData?.address?.longitude || default_LAT_LONG.longitude,
                          }}
                          handleChange={({ latitude, longitude }) => {
                            setFormData((prevData) => ({ ...prevData, address: { ...prevData.address, latitude, longitude, }, }));
                            setShowBtn(true);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>

              {/* Contact Information */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title={"Contact Information"}>
                  <Grid container spacing={inputGridSpacing}>
                    <Grid item lg={4} sm={6} xs={12}>
                      <InputField
                        label="Phone number"
                        type="number"
                        required
                        name="phone"
                        value={formData.phone}
                        error={formErrors?.phone}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <InputField
                        label="Email address"
                        type="email"
                        required
                        name="email"
                        value={formData.email}
                        error={formErrors?.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <InputField
                        label="IBAN"
                        type="text"
                        name="iban"
                        value={formData.iban}
                        error={formErrors?.iban}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <InputField
                        label="VAT Number"
                        type="text"
                        required
                        name="vatNumber"
                        value={formData.vatNumber}
                        error={formErrors?.vatNumber}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <InputField
                        label="Tax Code"
                        type="number"
                        required
                        name="taxCode"
                        value={formData.taxCode}
                        error={formErrors?.taxCode}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <InputField
                        label="Short Name"
                        type="text"
                        required
                        name="shortName"
                        value={formData.shortName}
                        error={formErrors?.shortName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12}>
                      <FormControl fullWidth>
                        <SelectBox
                          label="Saloon Type"
                          size="large"
                          required
                          name="typeOfSaloon"
                          value={formData.typeOfSaloon}
                          error={formErrors?.typeOfSaloon}
                          onChange={handleChange}
                          items={[
                            { label: "Male", value: "male" },
                            { label: "Female", value: "female" },
                            { label: "Neutral", value: "neutral" },
                          ]}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>

              {/* Salon Description */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title={"Salon Description"}>
                  <Grid container spacing={inputGridSpacing}>
                    <Grid item xs={12}>
                      <InputField
                        fullWidth
                        rows={5}
                        multiline
                        placeholder="Type the description here"
                        name="description"
                        value={formData.description}
                        error={formErrors?.description}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>

              {/* Salon Operating Timing */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title="Salon Operating Timing" icon={<ScheduleIcon />} type="subHeading">
                  <SevenDayTimeSelector
                    data={formData.timings}
                    requestDisableButton={setDisableBtn}
                    setFormData={setFormData}
                    formErrors={formErrors}
                    onChange={(value) => {
                      setFormData((prev) => ({ ...prev, timings: value }));
                      setShowBtn(true);
                      setDisableBtn(false);
                    }}
                  />
                </ContainerWithTopHeading>
              </Grid>

              {/* Logo and Social Accounts */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title={"Logo and Social Accounts"} icon={<SpaceDashboardIcon />}>
                  <Grid container sx={{ height: "100%" }}>
                    {/* Logo */}
                    <Grid item lg={3} xs={12}>
                      <ImagePicker width="100%" />
                    </Grid>

                    {/* Social Accounts */}
                    <Grid item lg={9} xs={12} container>
                      <Grid item lg={3} xs={12} mt={3}>
                        <Stack sx={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
                          <Typography children="Social Links" variant="h5" />
                        </Stack>
                      </Grid>
                      <Grid item container columnSpacing={2} rowSpacing={{ lg: 0, xs: 2 }} lg={9} xs={12} justifyContent="center" alignItems="Center">
                        <Grid item lg={6} xs={12}>
                          <InputField
                            label="Facebook"
                            type="url"
                            name="facebook"
                            value={formData?.socialAccounts?.facebook}
                            error={formErrors?.socialAccounts?.facebook}
                            onChange={(e) =>
                              setFormData((prevData) => ({ ...prevData, socialAccounts: { ...prevData?.socialAccounts, facebook: e.target.value } }))
                            }
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <InputField
                            label="Instagram"
                            type="url"
                            name="instagram"
                            value={formData?.socialAccounts?.instagram}
                            error={formErrors?.socialAccounts?.instagram}
                            onChange={(e) =>
                              setFormData((prevData) => ({ ...prevData, socialAccounts: { ...prevData?.socialAccounts, instagram: e.target.value } }))
                            }
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <InputField
                            label="Twitter"
                            type="url"
                            name="twitter"
                            value={formData?.socialAccounts?.twitter}
                            error={formErrors?.socialAccounts?.twitter}
                            onChange={(e) =>
                              setFormData((prevData) => ({ ...prevData, socialAccounts: { ...prevData?.socialAccounts, twitter: e.target.value } }))
                            }
                          />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                          <InputField
                            label="Youtube"
                            type="url"
                            name="youtube"
                            value={formData?.socialAccounts?.youtube}
                            error={formErrors?.socialAccounts?.youtube}
                            onChange={(e) =>
                              setFormData((prevData) => ({ ...prevData, socialAccounts: { ...prevData?.socialAccounts, youtube: e.target.value } }))
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>
              {!disableBtn && showBtn && (
                <CenterSubmitBtn variant="contained" size="large" type="submit">
                  {saloonID ? "Update" : "Create"}
                </CenterSubmitBtn>
              )}
            </Grid>
          </Box>
        </PrimaryLayout>
      </SaloonLayout>
    </>
  );
};

export default Saloon;