import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { Link, useNavigate } from "react-router-dom";
import { InputField } from "@components";
import { useDispatch } from "react-redux";
import apiManager from "src/services/api-manger";
import { useSnackbar } from "notistack";
import { setLoader } from "src/store/reducer";

export default function ForgetPass() {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({ email: "", role: "owner" });
  const [formErrors, setFormErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      dispatch(setLoader(true))
      setFormErrors({})
      const { data } = await apiManager({ method: 'post', path: 'forgetPass', params: {...formData, email: formData.email.toLowerCase()} })
      enqueueSnackbar(data?.message, { variant: 'success' });
      navigate(`/verifyOtp/${data?.data?.identifierId}`);
    } catch (error) {
      if (error?.response?.status === 422) {
        setFormErrors(error?.response?.data?.data);
      } else {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    } finally {
      dispatch(setLoader(false))
    }
  }


  return (
    <>
      <Typography component="h1" variant="h5" my={1}>
        Forgot Password
      </Typography>
      <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
        <InputField
          fullWidth
          label="Email Address"
          required
          type="email"
          name="email"
          value={formData.email}
          error={formErrors?.email}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          size="large"
          endIcon={<EastIcon />}
        >
          Send Otp
        </Button>
        <Button fullWidth component={Link} to="/login">
          Go back to login
        </Button>
      </Box>
    </>
  );
}
