import React, { useEffect, useState } from "react";

import { useDndMonitor } from "@dnd-kit/core";
import moment from "moment";

import { StoreContext } from "./context";
import { initialStore, defaultProps } from "./default";
import { TIME_FORMATE_ONLY, TYPE_AVAILABLE, TYPE_NORMAL } from "../../constants";

const StoreProvider = ({ children, initial }) => {
  const [state, setState] = useState({ ...initialStore, ...defaultProps(initial) });

  useEffect(() => {
    setState((prev) => ({ ...prev, events: initial?.events, resources: initial?.resources }));
  }, [initial?.events, initial?.resources]);

  const handleState = (value, name) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const triggerDialog = (status, selected) => {
    const isEvent = selected;
    setState((prev) => ({
      ...prev,
      dialog: status,
      selectedRange: isEvent?.event_id
        ? undefined
        : isEvent || {
            start: new Date(),
            end: new Date(Date.now() + 60 * 60 * 1000),
          },
      selectedEvent: isEvent?.event_id ? isEvent : undefined,
      selectedResource: isEvent?.[state.resourceFields?.idField],
      dialogFor: selected?.for,
    }));
  };

  useDndMonitor({
    onDragStart(event) {
      const draggedEvent = event?.active?.data?.current;
      setState((prev) => ({ ...prev, draggedEvent }));
    },

    onDragEnd(event) {
      const { active, over } = event;
      const dropEvent = active?.data?.current?.event;
      const dropOver = over?.data?.current;
      const isDayView = state?.view === "day";

      if (!dropEvent || !dropOver) return;

      let dateObj = {};
      if (isDayView) {
        const { start: activeEventStart, end: activeEventEnd } = dropEvent;
        const diffInMinutes = moment(activeEventEnd).diff(activeEventStart, "minutes");
        const onOverCellDate = dropOver.date;
        const onOverCellDateWithDateDiff = moment(onOverCellDate).subtract(state.day.step, "minutes");
        const checkDiff = moment.duration(moment(activeEventStart).diff(onOverCellDateWithDateDiff)).asMinutes();
        const newFormula = (state.day.step - (checkDiff % state.day.step)) % state.day.step;
        const adjustedStart = moment(onOverCellDate).subtract(newFormula, "minutes");
        const adjustedEndDrop = moment(onOverCellDate).add(diffInMinutes, "minutes").subtract(newFormula, "minutes");

        dateObj = {
          start: adjustedStart.format(),
          end: adjustedEndDrop.format(),
        };
      }

      const formattedDragEventStart = moment(dropEvent.start).format(TIME_FORMATE_ONLY);
      const formattedDragEventEnd = moment(dropEvent.end).format(TIME_FORMATE_ONLY);
      const formattedDragOverDate = moment(dropOver.date);

      const updatedEvent = {
        ...dropEvent,
        start: isDayView ? dateObj.start : moment(formattedDragOverDate).add(formattedDragEventStart).format(),
        end: isDayView ? dateObj.end : moment(formattedDragOverDate).add(formattedDragEventEnd).format(),
        resourceId: dropOver.type === TYPE_NORMAL ? dropOver.resourceId || dropEvent.resourceId : undefined,
        isPublished: false,
        available: dropOver.type === TYPE_AVAILABLE,
        ...(state.view !== "month" && { color: dropOver.color }),
      };

      const isSameStart = moment(dropEvent.start).isSame(dropOver.start);
      const isSameResourceId = dropOver.resourceId === dropEvent.resourceId;

      if (!isSameStart || !isSameResourceId) {
        state?.onEventDrop(updatedEvent);
      }

      setState((prev) => ({ ...prev, draggedEvent: null }));
    },
  });

  return <StoreContext.Provider value={{ ...state, triggerDialog, handleState }}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
