import * as Yup from "yup";

const SaloonRules = (update) =>
  Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(10, "Name must be at least 10 characters long")
      .max(30, "Name must be at most 30 characters long"),
    phone: Yup.string()
      .required("Phone is required")
      .min(11, "Phone must be at least 11 characters long")
      .max(11, "Phone must be at most 11 characters long"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    typeOfSaloon: Yup.string()
      .required("Type of Saloon is required")
      .oneOf(["male", "female", "neutral"], "Type of Saloon must be male, female, or neutral"),
    description: Yup.string(),
    address: Yup.object().shape({
      location: Yup.string(),
      country: Yup.mixed().required("Country is required"),
      state: Yup.mixed().required("State is required"),
      city: Yup.mixed().required("City is required"),
      zipCode: Yup.string()
        .required("Zip Code is required")
        .min(5, "Zip Code must be at least 5 characters long"),
      latitude: Yup.number()
        .required("Latitude is required")
        .typeError("Latitude must be a number")
        .default(0),
      longitude: Yup.number()
        .required("Longitude is required")
        .typeError("Longitude must be a number")
        .default(0),
    }),
    image: Yup.string(),
    iban: Yup.string(),
    vatNumber: Yup.string()
      .required("Vat Number is required")
      .min(5, "Vat Number must be at least 5 characters long")
      .max(20, "Vat Number must be at most 20 characters long"),
    taxCode: Yup.string()
      .required("Tax Code is required")
      .min(5, "Tax Code must be at least 5 characters long")
      .max(20, "Tax Code must be at most 20 characters long"),
    shortName: Yup.string()
      .min(3, "Short Name must be at least 3 characters long")
      .max(3, "Short Name must be at most 3 characters long"),
    socialAccounts: Yup.object().shape({
      facebook: Yup.string(),
      instagram: Yup.string(),
      twitter: Yup.string(),
      youtube: Yup.string(),
    }),
  });

export default SaloonRules;
