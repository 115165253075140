// Validation Helper.
import validationHelper from "./validationHelper"

// Validation Rules.
import saloonRules from "./validationRules/SaloonRules"
import EmployeeRules from "./validationRules/EmployeeRules"
import ServiceRules from "./validationRules/ServiceRules"
import UserRules from "./validationRules/UserRules"
import PackageRules from "./validationRules/PackageRules"

// Export the rules and helper.
export const helper = validationHelper;
export const rules = {
    saloonRules,
    EmployeeRules,
    ServiceRules,
    UserRules,
    PackageRules,
};
