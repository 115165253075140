import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import apiManager from "src/services/api-manger";
import { useParams } from "react-router-dom";

export const MenuContext = createContext("Initial Value");
let data = {
  packageScreen: false,
};

export const MenuContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  const { saloonID } = useParams();

  const getAllRecords = async () => {
    console.log("🚀 ~ file: Context.js:19 ~ getAllRecords ~ state.packageScreen:", state.packageScreen);
    try {
      const path = state.packageScreen ? `package/all/${saloonID}?per_page=999999` : `service/all?saloonId=${saloonID}`;
      let { data } = await apiManager({ method: "get", path });
      dispatch({
        type: "SET_DATA",
        payload: data?.data?.results || data?.data,
      });
    } catch (error) {
      console.log("🚀 ~ file: Context.js:21 ~ getAllRecords ~ error:", error);
    } finally {
    }
  };

  const contextValue = {
    state: { ...state },
    dispatch,
    getAllRecords,
  };

  return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
};
