import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, InputBase, TableRow, MenuItem, Paper, Select, TableCell, Tooltip } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Header, LeftDrawer, TableContainer, InputField } from "@components";
import { PrimaryLayout, SaloonLayout } from "../../../layouts";
import apiManager from "../../../services/api-manger";
import Utils from "../../../utils/utils";
import { setLoader } from "src/store/reducer";
import { useDispatch } from "react-redux";

const Products = () => {
  const { saloonID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const thLabels = ["No", "Name", "Brand Name", "Gender", "Price", "Stock", "Tax Rate", "Action"];

  //---------------------------------------------------------------------------------------//
  const initialSelectedItems = ["BRAND", "CATEGORY", "SIZE"];
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const totalPages = Math.ceil(records?.length / perPage);
  const Items = [
    { value: "BRAND", label: "BRAND" },
    { value: "CATEGORY", label: "CATEGORY" },
    { value: "SIZE", label: "SIZE" },
  ];

  const handleChange = (event, index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = event.target.value;
    setSelectedItems(newSelectedItems);
  };

  const SelectBoxFilter = ({ value, onChange, items }) => (
    <Select size="small" sx={{ width: "100%", fontSize: 12 }} value={value} onChange={onChange}>
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
      ;
    </Select>
  );
  //---------------------------------------------------------------------------------------//

  const getProductsData = async () => {
    try {
      setIsLoading(true);
      dispatch(setLoader(true));
      let { data } = await apiManager({ method: "get", path: `product/all/${saloonID}` });
      setRecords(data?.data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    } finally {
      dispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  const handleDelete = async (productId) => {
    setIsLoading(true);
    dispatch(setLoader(true));
    try {
      const { data } = await apiManager({ method: "delete", path: `product/delete/${productId}` });
      enqueueSnackbar(data?.message, { variant: "success" });
      getProductsData();
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      setIsLoading(false);
    } finally {
      dispatch(setLoader(false));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductsData();
  }, [saloonID]);

  const renderRow = (item, index) => {
    return (
      <TableRow key={index}>
        <TableCell>{Utils.getRowNumber(page, perPage, index)}</TableCell>
        <TableCell>{item?.name ? item?.name : "-"}</TableCell>
        <TableCell>{item?.brandName ? item?.brandName : "-"}</TableCell>
        <TableCell>{item?.gender ? item?.gender : "-"}</TableCell>
        <TableCell>{item?.price ? item?.price : "-"}</TableCell>
        <TableCell>{item?.stock ? item?.stock : "-"}</TableCell>
        <TableCell>{item?.taxRate ? item?.taxRate : "-"}</TableCell>
        <TableCell>
          <Tooltip title="Update Details" placement="top">
            <IconButton component={Link} to={`/saloon/${saloonID}/product/${item._id}`}>
              <ModeEditIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <IconButton onClick={() => handleDelete(item._id)}>
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <SaloonLayout
        navToLocation={(id) => {
          navigate(`/saloon/${id}/products`);
        }}
      >
        <Header headerTitle={"Products"} headerTitleIcon={<ContentPasteIcon />} />
        <PrimaryLayout>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={9}>
                <InputField
                  label="Search Product, Brand or Item"
                  type="text"
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Box component={Link} to={`/saloon/${saloonID}/addProduct`}>
                  <Button variant="contained" fullWidth startIcon={<AddIcon />}>
                    ADD PRODUCT
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {selectedItems.map((selectedItem, index) => (
                  <Grid item xs={4} lg={2} key={index}>
                    <SelectBoxFilter value={selectedItem} onChange={(event) => handleChange(event, index)} items={Items} />
                  </Grid>
                ))}
                <Grid item xs={12} lg={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="outlined" sx={{ width: { xs: "100%", lg: "inherit" } }} endIcon={<ArrowDownwardIcon />}>
                    DOWNLOAD PRODUCT LIST
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                thContent={thLabels.map((value, i) => (
                  <TableCell key={i} children={value} />
                ))}
                spanTd={thLabels.length}
                page={page}
                totalPages={totalPages}
                callBack={setPage}
                isContent={records.results?.length}
              >
                {records.results?.map((item, i) => {
                  return <Fragment key={i} children={renderRow(item, i)} />;
                })}
              </TableContainer>
            </Grid>
          </Grid>
        </PrimaryLayout>
      </SaloonLayout>
    </>
  );
};

export default Products;
