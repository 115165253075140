import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { theme } from "./constant";
import Router from "./router/router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "./App.css";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ScrollToTop />
        <Router />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
