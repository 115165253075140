import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

// const useGetElementWidth = (id) => {
//   const [elementWidth, setElementWidth] = useState(null);

//   useEffect(() => {
//     const element = document.getElementById(id);
//     const updateWidth = () => {
//       const parentElement = element?.parentElement;
//       if (parentElement) {
//         const parentWidth = parentElement.clientWidth;
//         setElementWidth(parentWidth);
//       }
//     };

//     if (element) {
//       updateWidth();
//       window.addEventListener("resize", updateWidth);
//     }

//     return () => {
//       if (element) {
//         window.removeEventListener("resize", updateWidth);
//       }
//     };
//   }, [id]);

//   return elementWidth;
// };

// const useGetElementWidth = (id) => {
//   const [elementWidth, setElementWidth] = useState(null);

//   useEffect(() => {
//     const element = document.getElementById(id);
//     const parentElement = element?.parentElement;
//     if (parentElement) {
//       const parentWidth = parentElement.clientWidth;
//       setElementWidth(parentWidth);
//     }
//   }, [id]);

//   return elementWidth;
// };


const useGetElementWidth = (elementId) => {
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    const updateElementWidth = () => {
      const element = document.getElementById(elementId);
      if (element) {
        const width = element.offsetWidth;
        setElementWidth(width);
      }
    };

    // Initial width update
    updateElementWidth();

    // Attach a resize event listener to update width on resize
    window.addEventListener("resize", updateElementWidth);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateElementWidth);
    };
  }, [elementId]);

  return elementWidth;
};

export { useWindowDimensions, useGetElementWidth };
