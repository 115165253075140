import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export const nvaLinks = [
  {
    label: "Calender",
    route: `/calender`,
    icon: <CalendarMonthIcon />,
  },
  {
    label: "Profile",
    route: "/profile",
    icon: <AccountCircleIcon />,
  },
  {
    label: "Clients",
    route: "/addClient",
    icon: <PermContactCalendarIcon />,
  },
  {
    label: "Employees",
    route: "/staff_members",
    icon: <GroupsIcon />,
  },
  {
    label: "Menu",
    match: "menu",
    icon: <MenuBookIcon />,
    children: [
      {
        label: "Services",
        match: "services",
        routeName: `/serviceListing`,
      },
      {
        label: "Packages",
        match: "products",
        routeName: `/packageListing`,
      },
      {
        label: "Products",
        match: "products",
        routeName: `/products`,
      },
    ],
  },
];
