

import { AddCircleOutline } from '@mui/icons-material'
import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'

import { CELL_BORDER_RADIUS, CELL_HEIGHT } from '../../constants'

const AddBtn = () => {
    return (
        <Box
            className="cellPlusIcon"
            elevation={6}
            sx={{
                width: "100%",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transition: "ease-out 0.2s",
                background: "none",
                border: 2,
                borderColor: grey[400],
                borderRadius: CELL_BORDER_RADIUS,
                scale:0  
            }}
        >
            <AddCircleOutline sx={{ color: (theme) => theme.palette.grey[500] }} />
        </Box>
    )
}

export default AddBtn