import { ListItemText, Typography } from "@mui/material";
import React from "react";

const TypoWithSubText = ({ primarySx = { fontSize: ".8rem", fontWeight: "400", textTransform: "uppercase" }, secondarySx, primaryText, subText }) => {
  return (
    <div>
      <ListItemText
        component={"p"}
        primary={primaryText}
        primaryTypographyProps={{
          sx: primarySx,
        }}
        secondary={
          <Typography component="span" variant="h5" color="text.primary" sx={[{fontWeight:"500"},secondarySx]}>
            {subText}
          </Typography>
        }
      />
    </div>
  );
};

export default TypoWithSubText;
