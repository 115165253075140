import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DragOverlay } from "@dnd-kit/core";
import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";

import AvailableShiftsSlot from "../components/availableShiftsSlot/availableShiftsSlot";
import CellWrapper from "../components/common/CellWrapper";
import ResourceInfoBox from "../components/common/ResourceInfoBox";
import TodayTypo from "../components/common/TodayTypo";
import DayCell from "../components/dayViewComponent/DayCell";
import DayEvents from "../components/dayViewComponent/DayEvents";
import EventCard from "../components/events/EventCard";
import SearchBar from "../components/searchBar/searchBar";
import UnassignedShiftsSlot from "../components/unassignedShiftsSlot/unassignedShiftsSlot";
import { DATE_FORMATE, TYPE_NORMAL } from "../constants";
import { eachMinuteOfInterval, filterDataByProvidedDate, getResourcedEvents, isToday } from "../helpers/generals";
import useStore from "../hooks/useStore";
import { StickyBox } from "../styles/styles";

const Day = React.memo(() => {
  const {
    events,
    resources,
    draggedEvent,
    selectedDate,
    day: { startHour, step, endHour },
    slots,
  } = useStore();
  const [filteredResources, setFilteredResources] = useState(resources);

  useEffect(() => {
    setFilteredResources(resources);
  }, [resources]);

  const START_TIME = useMemo(() => moment(moment(selectedDate).format("l")).startOf("day").add(startHour, "hour"), [selectedDate, startHour]);
  const END_TIME = useMemo(() => moment(moment(selectedDate).format("l")).startOf("day").add(endHour, "hour"), [selectedDate, endHour]);

  const hours = useMemo(() => eachMinuteOfInterval(START_TIME, END_TIME, step), [START_TIME, END_TIME, step]);

  const handleSearchChange = useCallback(
    (searchInput) => {
      const filtered = resources.filter((resource) => resource.name.toLowerCase().includes(searchInput.toLowerCase()));
      setFilteredResources(filtered);
    },
    [resources]
  );

  const filterEvents = useCallback(
    (eachResource) => {
      return filterDataByProvidedDate(getResourcedEvents(events, eachResource?._id), moment(selectedDate)) || [];
    },
    [events, selectedDate]
  );

  const renderResourceAndCells = useMemo(() => {
    return (
      <>
        <Typography my={1} ml={1} variant="body1">
          Scheduled shifts
        </Typography>

        {!filteredResources.length && (
          <Typography textAlign="center" py={5} variant="h5">
            No record found
          </Typography>
        )}

        {filteredResources.map((eachResource, i) => {
          const eventsData = filterEvents(eachResource);
          return (
            <Box sx={{ display: "flex" }} key={i}>
              <ResourceInfoBox resource={eachResource} />
              <Grid
                sx={{
                  position: "relative",
                  width: "fit-content",
                  flexGrow: 1,
                }}
                container
                wrap="nowrap"
                id="day-view-row"
              >
                <DayEvents events={eventsData} hours={hours} />
                {hours.map((date, ii) => (
                  <React.Fragment key={ii}>
                    <DayCell
                      date={date}
                      start={moment(date).format(DATE_FORMATE)}
                      end={moment(date).add(1, "hour").format(DATE_FORMATE)}
                      resourceParent={eachResource}
                      // events={eventsData}
                      type={TYPE_NORMAL}
                      cellId={`${date}_${eachResource._id}`}
                      cellBgColor={isToday(date) ? "" : grey[50]}
                      hours={hours}
                    />
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          );
        })}
      </>
    );
  }, [filterEvents, filteredResources, hours]);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "auto",
      }}
    >
      <StickyBox sx={{ zIndex: "8" }}>
        <Grid container sx={{ flexWrap: "nowrap" }}>
          <ResourceInfoBox children={<SearchBar onChange={(e) => handleSearchChange(e.target.value)} />} show={false} />

          <Box sx={{ width: "100%", display: "flex" }}>
            {hours.map((date, i) => (
              <CellWrapper key={i} xs={12 / hours.length + 1}>
                <TodayTypo date={date} type="day" />
              </CellWrapper>
            ))}
          </Box>
        </Grid>
        {slots?.includes("unAssignedShiftsSlot") && <UnassignedShiftsSlot daysList={hours} />}
        {slots?.includes("availableShiftsSlot") && <AvailableShiftsSlot daysList={hours} />}
      </StickyBox>

      {renderResourceAndCells}

      {draggedEvent && (
        <DragOverlay>
          {!!draggedEvent && (
            <EventCard
              draggableData={{
                id: draggedEvent?.event_id,
              }}
              event={draggedEvent?.event}
              resourceParent={draggedEvent?.resourceParent}
              disable={true}
              dragging={draggedEvent}
            ></EventCard>
          )}
        </DragOverlay>
      )}
    </Box>
  );
});

Day.displayName = "Day";

export default Day;
