import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'

const ModalWrapper = ({ open, onClose, title, children, dialogContentSx, ...props }) => {

  function BootstrapDialogTitle(props) {
    const { children, ...other } = props;
    return (
      <DialogTitle sx={{ textAlign: "center" }} {...other}>
        {children}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { minWidth: "280px" } } }}
      scroll="paper"
      {...props}
    >
      <BootstrapDialogTitle onClose={onClose}>{title}</BootstrapDialogTitle>
      <DialogContent sx={{ dialogContentSx }} dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ModalWrapper;