// import { Paper, alpha, styled } from "@mui/material";

import { Box, ButtonBase, InputBase, Paper, alpha, styled } from "@mui/material";
import { grey } from "@mui/material/colors";

import { CELL_BORDER_RADIUS } from "../constants";

export const Wrapper = styled(Paper)(({ theme, dialog }) => ({
  position: "relative",
  "& .rs__table_loading": {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 999999,
    "& .rs__table_loading_internal": {
      background: dialog ? "" : alpha(theme.palette.background.paper, 0.4),
      height: "100%",
      "& > span": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column",
        "& >span": {
          marginBottom: 15,
        },
      },
    },
  },
}));

export const Table = styled("div")(({ resource_count }) => ({
  position: "relative",
  display: "flex",
  flexDirection: resource_count > 1 ? "row" : "column",
  width: "100%",
  boxSizing: "content-box",
  "& > div": {
    flexShrink: 0,
    flexGrow: 1,
  },
}));

export const NavigationDiv = styled(Paper)(({ sticky = "0" }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: sticky === "1" ? "sticky" : "relative",
  top: sticky === "1" ? 0 : undefined,
  zIndex: sticky === "1" ? 999 : undefined,
  boxShadow: "none",
}));

export const TableGrid = styled("div")(({ days, sticky = "0", stickyNavigation, indent = "1", theme }) => ({
  display: "grid",
  gridTemplateColumns: +indent > 0 ? `repeat(${days + 1}, 1fr)` : `repeat(${days}, 1fr)`,
  overflowX: "auto",
  overflowY: "hidden",
  position: sticky === "1" ? "sticky" : "relative",
  top: sticky === "1" ? (stickyNavigation ? 36 : 0) : undefined,
  zIndex: sticky === "1" ? 99 : undefined,
  borderStyle: "solid",
  borderColor: theme.palette.grey[300],
  borderWidth: "0 0 0 1px",
  "&:first-of-type": {
    borderWidth: "1px 0 0 1px",
  },
  "&:last-of-type": {
    borderWidth: "0 0 1px 1px",
  },
  "& .rs__cell": {
    background: theme.palette.background.paper,
    position: "relative",
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    minWidth: "130px",
    borderWidth: "0 1px 1px 0",
    "&.rs__header": {
      "& > :first-of-type": {
        padding: "2px 5px",
      },
    },
    "&.rs__header__center": {
      padding: "6px 0px",
    },
    "&.rs__time": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "sticky",
      left: 0,
      zIndex: 99,
      [theme.breakpoints.down("sm")]: {
        writingMode: "vertical-rl",
      },
    },
    "& > button": {
      width: "100%",
      height: "100%",
      borderRadius: 0,
      cursor: "pointer",
      "&:hover": {
        background: alpha(theme.palette.primary.main, 0.1),
      },
    },
    "& .rs__event__item": {
      position: "absolute",
      zIndex: 1,
    },
    "& .rs__multi_day": {
      position: "absolute",
      zIndex: 1,
      textOverflow: "ellipsis",
    },
    "& .rs__block_col": {
      display: "block",
      position: "relative",
    },
    "& .rs__hover__op": {
      cursor: "pointer",
      "&:hover": {
        opacity: 0.7,
        textDecoration: "underline",
      },
    },
    // "&:not(.rs__time)": {
    //   minWidth: 65,
    // },
  },
}));

export const EventItemPaper = styled(Paper, {
  shouldForwardProp: (prop) => {
    return !["themeColor", "isDragging", "disabled", "isPublished"].includes(prop);
  },
})(({ themeColor, isDragging, disabled, isPublished }) => ({
  width: "100%",
  display: "block",
  cursor: isDragging ? "grabbing" : disabled ? "not-allowed" : "pointer",
  overflow: "hidden",
  border: "2px solid",
  background: "none",
  borderStyle: "dashed",
  borderColor: themeColor,
  height: "calc(100% - 4px)",
  backgroundColor: isPublished ? themeColor : "white",
  color: isPublished ? "white" : themeColor,
  borderRadius: CELL_BORDER_RADIUS,
}));

export const PopperInner = styled("div")(({ theme }) => ({
  maxWidth: "100%",
  width: 400,
  "& > div": {
    padding: "5px 10px",
    "& .rs__popper_actions": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .MuiIconButton-root": {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

export const EventActions = styled("div")(({ theme }) => ({
  display: "inherit",
  "& .MuiIconButton-root": {
    color: theme.palette.primary.contrastText,
  },
  "& .MuiButton-root": {
    "&.delete": {
      color: theme.palette.error.main,
    },
    "&.cancel": {
      color: theme.palette.action.disabled,
    },
  },
}));

export const TimeIndicatorBar = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: 9,
  width: "100%",
  display: "flex",
  "& > div:first-of-type": {
    height: 12,
    width: 12,
    borderRadius: "50%",
    background: theme.palette.error.light,
    marginLeft: -6,
    marginTop: -5,
  },
  "& > div:last-of-type": {
    borderTop: `solid 2px ${theme.palette.error.light}`,
    width: "100%",
  },
}));

export const CustomAddButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  border: "2px solid black",
  borderStyle: "dashed",
  borderRadius: CELL_BORDER_RADIUS,
  width: "40px",
  "&:hover": {
    borderColor: theme.palette.getContrastText(grey[500]),
  },
}));

export const StickyBox = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: "0",
  zIndex: 5,
  background: "white",
}));

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
