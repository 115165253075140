import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import HeadingWithIcon from "../HeadingWithIcon/HeadingWithIcon";

const ContainerWithTopHeading = ({ title, icon, children, styles, headingTop = true }) => {
  return (
    <Box>
      {headingTop && <HeadingWithIcon title={title} icon={icon ? icon : null} type="subHeading" />}
      <Stack
        component={Paper}
        elevation={3}
        sx={{
          borderRadius: "4px",
          minHeight: "20vh",
          mt: "10px",
          p: 3,
          flexGrow: 1,
          ...styles,
        }}
      >
        {children}
      </Stack>
    </Box>
  );
};

export default ContainerWithTopHeading;
