import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import apiManager from "src/services/api-manger";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Utils from "src/utils/utils";

export default function ServicesAutoComplete({ handleSelected = () => {}, value, options, getOptions = () => {} }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (options?.length) {
      setLoading(false);
    }
  }, [options]);

  return (
    <Autocomplete
      id="services"
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
        setLoading(true)
        getOptions();
      }}
      onClose={() => {
        setLoading(false)
        setOpen(false);
      }}
      limitTags={1}
      onChange={handleSelected}
      value={value}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      getOptionLabel={(option) => option?.name}
      options={options}
      loading={loading}
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props}>
            <Box sx={{ display: "flex !important", justifyContent: "space-between", width: "100%" }}>
              <Box>{option?.name}</Box>
              <Box>
                {Utils._formatDuration(option?.duration)} / {option?.price}$
              </Box>
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Services"
          fullWidth
          required
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
