import React, { useContext, useEffect, useState } from "react";
import { LeftDrawer } from "@components";
import { useSnackbar } from "notistack";
import apiManager from "../../services/api-manger";
import { Add } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { EmployeeContext } from "src/context/EmployeeContext/Context";
import { setLoader } from "src/store/reducer";
import { useSelector } from "react-redux";

const EmployeeLayout = ({ children }) => {

  const { saloonInfo } = useSelector((state) => state.appReducer);
  const { enqueueSnackbar } = useSnackbar();
  let { state, dispatch } = useContext(EmployeeContext);
  const { saloonID, employeeID } = useParams();
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEmployees = async () => {
    try {
      if (!state?.employeeData?.length) {
        setIsLoading(true);
        dispatch(setLoader(true));
      }
      let { data } = await apiManager({ method: "get", path: `employees/get-all/${saloonID}` });
      dispatch({
        type: "SET_DATA",
        payload: data?.data?.results || data?.data,
      });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.data, { variant: "error" });
    } finally {
      setIsLoading(false);
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const renderEmployeeList = () => {
    if (isLoading) {
      return <SkeletonList count={5} />;
    }
    if (!state?.employeeData?.length) {
      return <Typography children="No record found" textAlign="center" variant="h5" />;
    }
    return (
      <List>
        {state?.employeeData?.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={`/saloon/${saloonID}/staff_members/${item?._id}`}
              selected={item._id === employeeID}
              className="listBtn"
            >
              <ListItemAvatar sx={{ m: 0 }}>
                <Avatar src={item.imageUrl} alt="Remy Sharp" />
              </ListItemAvatar>
              <ListItemText primary={item?.firstName} secondary={`Role (${item?.role})`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <div>
      <LeftDrawer
        routes={[]}
        flag={true}
        showLogo={false}
        showDetails={{
          icon: <GroupIcon />,
          title: `${state?.employeeData?.length || 0} Employees`,
          subTitle: saloonInfo?.name || "Saloon Name",
        }}
        showDrawer={false}
        showButton={{ url: `/saloon/${saloonID}/staff_members/add_member`, icon: <Add />, text: "Add Employee" }}
        searchLabel={"Search Employees"}
        drawerList={renderEmployeeList()}
      />
      {children}
    </div>
  );
};

export default EmployeeLayout;

const SkeletonList = ({ count }) => {
  const skeletonItems = Array.from({ length: count }, (_, index) => (
    <ListItem key={index}>
      <ListItemAvatar>
        <Skeleton variant="circular" width={40} height={40} />
      </ListItemAvatar>
      <ListItemText primary={<Skeleton width={150} />} secondary={<Skeleton width={100} />} />
    </ListItem>
  ));

  return <List>{skeletonItems}</List>;
};
