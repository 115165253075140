import React from "react";
import { Box } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { PrimaryLayout, ClientLayout } from "../../../layouts";
import { Header, HeadingWithIcon, StyledTab, StyledTabs, TabContent } from "@components";
import ClientAppointments from "./ClientAppointments/ClientAppointments";
import ClientProducts from "./ClientProducts/ClientProducts";

const ClientHistory = () => {

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ClientLayout>
        <Header headerTitle={"Client History"} headerTitleIcon={<HistoryIcon />} />
        <PrimaryLayout>
          <HeadingWithIcon title={"History Details"} icon={<HistoryIcon />} type="heading" />
          <Box mt={2}>
            <StyledTabs onChange={handleChange} aria-label="lab API tabs example" value={value} variant="fullWidth" textColor="secondary">
              <StyledTab label="Appointments" value="1" />
              <StyledTab label="Products" value="2" />
            </StyledTabs>
            <TabContent value="1" index={value}>
              <ClientAppointments />
            </TabContent>
            <TabContent value="2" index={value}>
              <ClientProducts />
            </TabContent>
          </Box>
        </PrimaryLayout>
      </ClientLayout>
    </>
  );
};

export default ClientHistory;
