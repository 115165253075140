import React from "react";

import { DragOverlay } from "@dnd-kit/core";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";

import CellWrapper from "../components/common/CellWrapper";
import EventCard from "../components/events/EventCard";
import MonthCell from "../components/monthViewCompnents/monthCell";
import useStore from "../hooks/useStore";
import { StickyBox } from "../styles/styles";



const Month = () => {
  const { month, selectedDate, events, draggedEvent } = useStore();

  const { weekDays } = month;

  const monthStart = moment(selectedDate).startOf("month");
  const monthEnd = moment(selectedDate).endOf("month");

  const eachWeekStart = [];

  let currentWeek = monthStart.clone().startOf("week");
  while (currentWeek.isBefore(monthEnd)) {
    eachWeekStart.push(currentWeek.format());
    currentWeek.add(1, "week");
  }

  const daysList = weekDays.map((_, index) => moment(eachWeekStart[0]).add(index, "days")._d);

  const renderCells = () => {
    const rows = [];

    eachWeekStart.forEach((startDay) => {
      const cells = weekDays.map((eachDate, i) => {
        const today = moment(startDay).add(eachDate, "days").format();
        return <MonthCell key={i} date={today} monthStart={moment(monthStart).format()} events={events} 
        />;
      });
      rows.push(
        <Grid container key={startDay} sx={{ flexWrap: "nowrap" }}>
          {cells}
        </Grid>
      );
    });
    return rows;
  };

  const renderTable = () => {
    return (
      <Box sx={{ position: "relative;" }}>
        {/* Header days */}
        <StickyBox sx={{ zIndex: "8" }}>
          <Grid container sx={{ flexWrap: "nowrap" }}>
            {daysList.map((date, i) => (
              <CellWrapper key={i} xs={12 / 7} styles={{ display: "flex", justifyContent: "Center", alignItems: "center" }}>
                <Typography className="rs__cell rs__header rs__header__center" align="center" variant="body2">
                  {moment(date).format("dddd")}
                </Typography>
              </CellWrapper>
            ))}
          </Grid>
        </StickyBox>

        {renderCells()}
        {draggedEvent && (
          <DragOverlay>
            <EventCard
              draggableData={{
                id: draggedEvent?.event_id,
              }}
              event={draggedEvent?.event} disableAddBtn={true} dragging={Boolean(draggedEvent)}

            />
          </DragOverlay>
        )}
      </Box>
    );
  };

  return renderTable();
};

export default Month;
