import { Paper, Tab, styled } from "@mui/material";

const StyledTab = styled((props) => <Tab  {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color:"secondary",
  "&.Mui-selected": {
    backgroundColor: "white",
  },
  borderRadius: "100vw",
  margin: "0px !important",

}));
export default StyledTab;
