import { Fragment, useState } from "react";

import { useDraggable } from "@dnd-kit/core";
import AddIcon from "@mui/icons-material/Add";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import moment from "moment";

import { CELL_HEIGHT } from "../../constants";
import useStore from "../../hooks/useStore";
import { CustomAddButton, EventItemPaper } from "../../styles/styles";

const EventItem = ({ event, showdate = true, resourceParent, disable, dragging, type }) => {
  const [showAdd, setShowAdd] = useState(disable);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: event?.event_id,
    data: {
      event,
      resourceParent,
      type,
    },
  });

  const { hourFormat } = useStore();
  const hFormat = hourFormat === "12" ? "hh:mm a" : "HH:mm";

  let item = (
    <div style={{ padding: "2px 6px" }}>
      <Typography variant="subtitle2" style={{ fontSize: 12 }} noWrap>
        {event?.title}
      </Typography>
      {showdate && (
        <Typography style={{ fontSize: 11 }} noWrap>
          {`${moment(event?.start).format(hFormat)} - ${moment(event?.end).format(hFormat)}`}
        </Typography>
      )}
    </div>
  );

  const renderEvent = () => {
    return (
      <Stack
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        direction={"row"}
        sx={{ height: CELL_HEIGHT, zIndex: 9, opacity: isDragging ? 0.5 : 1, transform: dragging && "rotate(5deg)" }}
        spacing={1}
        onMouseOver={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
      >
        <EventItemPaper themeColor={resourceParent?.color} isPublished={event.isPublished} isDragging={dragging}>
          <div>{item}</div>
        </EventItemPaper>

        <Box>
          <Collapse in={!disable && !isDragging && showAdd} orientation="horizontal" sx={{ height: "100%" }}>
            <CustomAddButton sx={{ height: "100%" }}>
              <AddIcon />
            </CustomAddButton>
          </Collapse>
        </Box>
      </Stack>
    );
  };

  return <Fragment>{renderEvent()}</Fragment>;
};

export default EventItem;
