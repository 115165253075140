import { Fragment, useEffect, useState, useCallback } from "react";

import { useDraggable } from "@dnd-kit/core";
import AddIcon from "@mui/icons-material/Add";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";

import { CELL_HEIGHT } from "../../constants";
import useStore from "../../hooks/useStore";
import { CustomAddButton, EventItemPaper } from "../../styles/styles";

const EventCard = ({ event, showdate = true, disableAddBtn, dragging, draggableData, onRequestAdd, style }) => {
  const { onEventClick } = useStore();
  const [showAdd, setShowAdd] = useState(disableAddBtn);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable(draggableData);

  const hFormat = "HH:mm";
  let item = (
    <div style={{ padding: "2px 6px" }}>
      <Typography variant="subtitle2" style={{ fontSize: 12 }} noWrap>
        {event?.fullName}
      </Typography>
      {showdate && (
        <>
          <Typography style={{ fontSize: 11 }} noWrap>
            {`${moment(event?.start).format(hFormat)} - ${moment(event?.end).format(hFormat)}`}&nbsp;
          </Typography>
        </>
      )}
    </div>
  );

  const renderEvent = () => {
    return (
      <Stack
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        direction={"row"}
        sx={{ height: CELL_HEIGHT, zIndex: 9, opacity: isDragging && !dragging ? 0.5 : 1, transform: dragging && "rotate(3deg)", ...style }}
        onMouseOver={() => setShowAdd(true)}
        onMouseLeave={() => setShowAdd(false)}
        onClick={() => onEventClick(event)}
      >
        <EventItemPaper themeColor={event?.color} isPublished={event?.isPublished} isDragging={dragging} elevation={dragging ? 24 : 0}>
          <div>{item}</div>
        </EventItemPaper>

        <Box>
          <Collapse in={!disableAddBtn && !isDragging && showAdd} orientation="horizontal" sx={{ height: "100%" }} unmountOnExit>
            <CustomAddButton sx={{ height: "100%", ml: 0.5 }} onClick={onRequestAdd}>
              <AddIcon />
            </CustomAddButton>
          </Collapse>
        </Box>
      </Stack>
    );
  };

  return <Fragment>{renderEvent()}</Fragment>;
};

EventCard.propTypes = {
  event: PropTypes.shape({
    event_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    isPublished: PropTypes.bool,
    color: PropTypes.string,
  }).isRequired,
  showdate: PropTypes.bool,
  resourceParent: PropTypes.object,
  draggableData: PropTypes.object,
  disable: PropTypes.bool,
  dragging: PropTypes.bool,
  type: PropTypes.string,
};

export default EventCard;
