import React, { useEffect, useState } from 'react'
import { Container, Box, Typography, Button, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { InputField, PasswordInputField, Header } from '../../component'
import { setUser } from 'src/store/reducer'
import { PrimaryLayout, SaloonLayout } from 'src/layouts'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import apiManager from 'src/services/api-manger'

export default function Profile() {

    const { user } = useSelector((state) => state.appReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [formData, setFormData] = useState({})

    useEffect(() => {
        setFormData({
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            mobile: user?.mobile || '',
            email: user?.email || '',
        })
    }, [user])

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const updatedData = {};
        Object.keys(formData).forEach(key => {
            if (formData[key] !== user[key]) {
                updatedData[key] = formData[key];
            }
        });
        try {
            const { data } = await apiManager({ method: 'put', path: `user/update/${user?._id}`, params: updatedData });
            dispatch(setUser(data?.data));
            enqueueSnackbar(data?.message, { variant: "success" });
        } catch (error) {
            setFormErrors(error?.response?.data?.data);
            if (error?.status !== 422) {
                enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
            }
        } finally {
            setIsLoading(false);
        };
    };

    return (
        <>
            <SaloonLayout navToLocation={(id) => navigate(`/saloonProfile/${id}`)}>
                <Header openDrawerBtn={false} LogoutBtn={true} />
                <PrimaryLayout>
                    <Container sx={{ pt: 2 }}>
                        <Box variant="div" sx={{ my: 3 }}>
                            <Typography variant="h5" align="center">Profile</Typography>
                        </Box>
                        <Box
                            component="form"
                            autoComplete="off"
                            autoCapitalize="off"
                            onSubmit={handleSubmit}
                            sx={{
                                mt: 5,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Box sx={{ minWidth: { sm: '400px', xs: '320px' }, mb: 2 }}>
                                <InputField
                                    label="First name"
                                    placeholder="First name"
                                    required
                                    type="text"
                                    name="firstName"
                                    value={formData?.firstName}
                                    error={formErrors?.firstName}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box sx={{ minWidth: { sm: '400px', xs: '320px' }, mb: 2 }}>
                                <InputField
                                    label="Last name"
                                    placeholder="Last name"
                                    required
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    error={formErrors?.lastName}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box sx={{ minWidth: { sm: '400px', xs: '320px' }, mb: 2 }}>
                                <InputField
                                    label="Email Address"
                                    placeholder="Email Address"
                                    type="email"
                                    name="email"
                                    required
                                    value={formData.email}
                                    error={formErrors?.email}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box sx={{ minWidth: { sm: '400px', xs: '320px' }, mb: 2 }}>
                                <InputField
                                    label="Phone No"
                                    placeholder="123-456-789-0"
                                    type="number"
                                    name="mobile"
                                    required
                                    value={formData.mobile}
                                    error={formErrors?.mobile}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box sx={{ minWidth: { sm: '400px', xs: '320px' }, mb: 2 }}>
                                <PasswordInputField
                                    label="Password"
                                    placeholder="******"
                                    name="password"
                                    value={formData.password}
                                    error={formErrors?.password}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Button variant="contained" type="submit" sx={{ mt: 1 }}>
                                {isLoading ? <CircularProgress size={20} color='inherit' /> : 'Update'}
                            </Button>
                        </Box>
                    </Container>
                </PrimaryLayout>
            </SaloonLayout>
        </>
    )
};