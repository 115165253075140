import React, { useState } from "react";

import { useDndMonitor, useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";
import { yellow } from "@mui/material/colors";
import moment from "moment";

import { CELL_HEIGHT } from "../../constants";
import { isTimeInRange } from "../../helpers/generals";
import useStore from "../../hooks/useStore";

const Cell = ({ droppableData, cellProps, children }) => {
  const { setNodeRef, over, isOver, active } = useDroppable(droppableData);
  // const {
  //   view,
  //   day: { step },
  // } = useStore();
  const view = "day";
  const step = 5;

  const [checkState, setCheckState] = useState(null);

  useDndMonitor({
    onDragOver: (event) => {
      const activeEvent = active?.data?.current?.event;
      const onOverCell = event?.over?.data?.current;

      if (activeEvent && onOverCell) {
        const durationInMinutes = moment.duration(moment(activeEvent.end).diff(activeEvent.start)).asMinutes();

        const onOverCellDate = onOverCell.date;
        const onOverCellDateWithStepSubtraction = moment(onOverCellDate).subtract(step, "minutes");
        const checkDiffStart = moment.duration(moment(activeEvent.start).diff(onOverCellDateWithStepSubtraction)).asMinutes();

        const adjustedStartDiff = getAdjustedDiff(checkDiffStart, step);
        const dateWithDiffAdded = moment(onOverCell.date).add(durationInMinutes, "minutes").subtract(adjustedStartDiff, "minutes");
        const adjustedEndDiff = getAdjustedDiff(moment.duration(moment(dateWithDiffAdded).diff(onOverCellDate)).asMinutes(), step, "end");

        setCheckState({
          start: onOverCellDate,
          end: dateWithDiffAdded,
          cellType: onOverCell.type,
          startDiff: adjustedStartDiff,
          endDiff: adjustedEndDiff,
        });
      }
    },
    onDragEnd: () => {
      setCheckState(null);
    },
  });

  const isActive =
    checkState &&
    isTimeInRange(droppableData?.data?.date, checkState?.start, checkState?.end, step, checkState.startDiff, checkState.endDiff) &&
    droppableData?.data?.resourceId === over?.data?.current?.resourceId &&
    droppableData?.data?.type === checkState?.cellType;

  return (
    <Box ref={setNodeRef} sx={getCellStyles(view === "day" ? isActive : isOver)} {...cellProps}>
      {children}
    </Box>
  );
};

export default Cell;

Cell.displayName = "Cell";

const getCellStyles = (isActive) => ({
  minHeight: CELL_HEIGHT,
  ":hover": {
    "& > .cellPlusIcon": {
      scale: "1 !important",
    },
  },
  display: "flex",
  justifyContent: "center",
  zIndex: 1,
  background: isActive ? yellow[200] : undefined,
  alignItems: "center",
});

const getAdjustedDiff = (diff, step, type = "start") => {
  if (type === "start") {
    return (step - (diff % step)) % step;
  } else {
    return diff % step;
  }
};
