import { useState } from "react";

import { Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon, DateCalendar } from "@mui/x-date-pickers";
import moment from "moment";

import useStore from "../../hooks/useStore";

const MonthDateSelector = () => {
    const {selectedDate, handleState } = useStore();
    const [anchorEl, setAnchorEl] = useState(null);

    const monthStart = moment(selectedDate).startOf("month");
    const monthEnd = moment(selectedDate).endOf("month");

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (e) => {
        handleState(e, "selectedDate");
        handleClose();
    };

    const handlePrev = () => {
        const lastDayPrevMonth = moment(monthStart).subtract(1, "month");
        handleState(lastDayPrevMonth, "selectedDate");
    };
    const handleNext = () => {
        const firstDayNextMonth = moment(monthEnd).add(1, "month");
        handleState(firstDayNextMonth, "selectedDate");
    };

    return (
        <Stack justifyContent="center" alignItems="center" direction="row">
            <IconButton onClick={handlePrev}>
                <ArrowLeftIcon />
            </IconButton>
            <Button style={{ padding: 4 }} onClick={handleOpen} aria-label="selected week">
                <Typography variant="h4" textTransform="none">
                    {" "}
                    {`${moment(selectedDate).format("MMMM YYYY")}`}
                </Typography>
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <DateCalendar openTo="month" views={["year", "month"]} value={moment(selectedDate)} onChange={handleChange} 
                
                sx={{height:"auto"}}
                />
            </Popover>
            <IconButton onClick={handleNext}>
                <ArrowRightIcon />
            </IconButton>
        </Stack>
    );
};

export default MonthDateSelector;
