// CONTEXT:
import { SaloonContextProvider } from "src/context/SaloonContext/Context";
import { EmployeeContextProvider } from "src/context/EmployeeContext/Context";
import { ClientContextProvider } from "src/context/ClientContext/Context";
import { MenuContextProvider } from "src/context/MenuContext/Context";
// LAYOUTS:
import { AuthLayout, PrimaryLayout } from "../layouts";
// SCREENS:
import {
  Error404,
  Login,
  ForgetPass,
  VerifyOtp,
  ResetPass,
  Home,
  Profile,
  Saloon,
  SaloonProfile,
  AddEmployee,
  Employee,
  Products,
  AddProduct,
  AddClient,
  Client,
  ClientHistory,
  Service,
  ServiceListing,
  Package,
  PackageListing,
  EmployeeListing,
  Calender,
} from "../screens";

export const routerData = [
  ///////////////////////////////////// AUTH ROUTES ///////////////////////////////////
  {
    element: <Login />,
    isProtected: false,
    layout: AuthLayout,
    role: ["all"],
    exact: true,
    url: "/login",
    fallBack: "/",
  },
  {
    element: <ForgetPass />,
    isProtected: false,
    layout: AuthLayout,
    role: ["all"],
    exact: true,
    url: "/forgetPass",
    fallBack: "/",
  },
  {
    element: <VerifyOtp />,
    isProtected: false,
    layout: AuthLayout,
    role: ["all"],
    exact: true,
    url: "/verifyOtp/:userId",
    fallBack: "/",
  },
  {
    element: <ResetPass />,
    isProtected: false,
    layout: AuthLayout,
    role: ["all"],
    exact: true,
    url: "/resetPass/:otpId",
    fallBack: "/",
  },
  ///////////////////////////////////// AUTH ROUTES ///////////////////////////////////


  ///////////////////////////////////// INITIAL ROUTES ///////////////////////////////////
  {
    element: <Profile />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/profile",
    fallBack: "/login",
  },
  {
    element: <Home />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/",
    fallBack: "/login",
  },
  {
    element: <Error404 />,
    isProtected: false,
    role: ["all"],
    exact: true,
    url: "/*",
    fallBack: "",
  },
  ///////////////////////////////////// INITIAL ROUTES ///////////////////////////////////


  ///////////////////////////////////// PARLOUR ROUTES ///////////////////////////////////
  {
    element: <Saloon />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/add",
    fallBack: "/login",
  },
  {
    element: <Saloon />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/update",
  },
  {
    element: <SaloonProfile />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloonProfile/:saloonID",
    fallBack: "/login",
  },
  ///////////////////////////////////// PARLOUR ROUTES ///////////////////////////////////

  ///////////////////////////////////// EMPLOYEE ROUTES ///////////////////////////////////
  {
    element: <EmployeeListing />,
    isProtected: true,
    // layout: PrimaryLayout,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/staff_members",
    context: EmployeeContextProvider,
    fallBack: "/login",
  },
  {
    element: <AddEmployee />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: EmployeeContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/staff_members/add_member",
    fallBack: "/login",
  },
  {
    element: <Employee />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: EmployeeContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/staff_members/:employeeID",
    fallBack: "/login",
  },
  ///////////////////////////////////// EMPLOYEE ROUTES ///////////////////////////////////

  ///////////////////////////////////// CLIENT ROUTES ///////////////////////////////////
  {
    element: <AddClient />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: ClientContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/addClient",
    fallBack: "/login",
  },
  {
    element: <Client />,
    isProtected: true,
    context: ClientContextProvider,
    // layout: PrimaryLayout,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/client",
    fallBack: "/login",
  },
  {
    element: <Client />,
    context: ClientContextProvider,
    isProtected: true,
    // layout: PrimaryLayout,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/client/:clientID",
    fallBack: "/login",
  },
  {
    element: <ClientHistory />,
    context: ClientContextProvider,
    isProtected: true,
    // layout: PrimaryLayout,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/clientHistory/:clientID",
    fallBack: "/login",
  },
  ///////////////////////////////////// CLIENT ROUTES ///////////////////////////////////

  ///////////////////////////////////// PRODUCTS ROUTES ///////////////////////////////////
  {
    element: <Products />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/products",
    fallBack: "/login",
  },
  {
    element: <AddProduct />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/addProduct",
    fallBack: "/login",
  },
  {
    element: <AddProduct />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: SaloonContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/product/:productID",
    fallBack: "/login",
  },
  ///////////////////////////////////// PRODUCTS ROUTES ///////////////////////////////////

  ///////////////////////////////////// SERVICES ROUTES ///////////////////////////////////

  {
    element: <ServiceListing />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: MenuContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/serviceListing",
    fallBack: "/login",
  },
  {
    element: <Service />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: MenuContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/service",
    fallBack: "/login",
  },
  {
    element: <Service />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: MenuContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/service/:serviceID",
    fallBack: "/login",
  },
  ///////////////////////////////////// SERVICES ROUTES ///////////////////////////////////

  ///////////////////////////////////// PACKAGE ROUTES ///////////////////////////////////
  {
    element: <PackageListing />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: MenuContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/packageListing",
    fallBack: "/login",
  },
  {
    element: <Package />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: MenuContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/package",
    fallBack: "/login",
  },
  {
    element: <Package />,
    isProtected: true,
    // layout: PrimaryLayout,
    context: MenuContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/package/:packageID",
    fallBack: "/login",
  },
  ///////////////////////////////////// PACKAGE ROUTES ///////////////////////////////////

  ///////////////////////////////////// CALENDER ROUTES ///////////////////////////////////
  {
    element: <Calender />,
    isProtected: true,
    // layout: PrimaryLayout,
    // context: MenuContextProvider,
    role: ["admin"],
    exact: true,
    url: "/saloon/:saloonID/calender",
    fallBack: "/login",
  },
];
