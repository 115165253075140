import React from "react";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  ListItemAvatar,
  Avatar,
  ListItemButton,
  Button,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DRAWER_WIDTH, MOBILE_BREAK_POINT, headerHeight } from "../../constant";
import { Logo } from "../../assets";
import SearchInput from "../SearchInput/SearchInput";
import { closeDrawer, setOpenDrawer } from "../../store/reducer";

export default function ClippedDrawer({ routes, showLogo, showDetails, showDrawer, showButton, searchLabel, drawerList }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery(MOBILE_BREAK_POINT);

  return (
    <>
      <Drawer variant={showDrawer ? "permanent" : matches ? "permanent" : "temporary"} sx={drawerStyles.drawer}>
        {!matches && <Toolbar sx={{ mt: `calc(${headerHeight} - 50px)` }} />}

        {/* Render the Logo in the toolbar if 'showLogo' and is true. */}
        {showLogo && (
          <Toolbar sx={drawerStyles.toolbar}>
            <Box sx={drawerStyles.logoBox}>
              <Box component={"img"} src={Logo} sx={drawerStyles.logoImg} onClick={() => navigate('/')} />
            </Box>
          </Toolbar>
        )}

        {/* Render the details in the toolbar if 'showDetails' is true. */}
        {showDetails && (
          <Toolbar sx={[drawerStyles.toolbar, { flexDirection: "column", boxShadow: { md: "7px 4px 44px 0px rgba(0, 0, 0, 0.04)" } }]}>
            <ListItem component={"div"} sx={drawerStyles.listItem}>
              <ListItemIcon sx={drawerStyles.listItem}>{showDetails?.icon}</ListItemIcon>
              <ListItemText primary={showDetails?.title} sx={drawerStyles.listItemText} />
            </ListItem>
            <ListItem component={"span"} disablePadding>
              <ListItemText primary={showDetails?.subTitle} sx={{ fontSize: "12px !important" }} />
            </ListItem>
          </Toolbar>
        )}

        <Toolbar sx={{ mt: 2 }}>
          <SearchInput searchLabel={searchLabel} />
        </Toolbar>

        <Divider sx={drawerStyles.divider} />

        {drawerList}

        {showButton && (
          <Stack sx={{ my: 2, mx: 2 }}>
            <Button
              component={Link}
              to={showButton?.url}
              variant="contained"
              endIcon={showButton?.icon}
              fullWidth
              onClick={() => dispatch(closeDrawer("right"))}
            >
              {showButton?.text}
            </Button>
          </Stack>
        )}
      </Drawer>
    </>
  );
}

const drawerStyles = {
  drawer: {
    width: { md: DRAWER_WIDTH, xs: "100%" },
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: { md: DRAWER_WIDTH, xs: "100%" },
      boxSizing: "border-box",
      boxShadow: " 7px 4px 44px 0px rgba(0, 0, 0, 0.04)",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: headerHeight,
    minHeight: { md: `${headerHeight} !important`, xs: `80px !important` },
  },
  logoBox: {
    maxWidth: "160px",
  },
  logoImg: {
    height: "100%",
    width: "100%",
    cursor: "pointer"
  },
  divider: {
    my: 2,
  },
  list: {
    "& .MuiListItemIcon-root": {
      color: "#000",
    },
  },
  listItem: {
    display: "flex",
    minWidth: "30px",
    p: "0px",
  },
  listItemText: {
    primary: "Single-line item",
    "& .MuiTypography-root": { color: "black", fontSize: { sm: "19px" } },
  },
};
