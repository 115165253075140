import React from "react";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator, timelineItemClasses } from "@mui/lab";
import { Box, Paper, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TypoWithIcon from "../TypoWithIcon/TypoWithIcon";

const TimelineWithCard = () => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        padding: 0,
      }}
    >
      {Array(2)
        .fill("")
        .map((_, key) => (
          <TimelineItem key={key}>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <QueryBuilderIcon />
              </TimelineDot>

              <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            </TimelineSeparator>
            <TimelineContent>
              Monday 18/September/2023 2:46 PM
              <TimelineCard />
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};

export default TimelineWithCard;

const TimelineCard = () => {
  return (
    <Paper component={Stack} sx={{ py: 2, px: 3, maxWidth: "600px", borderRadius: "10px" }} spacing={0.5} elevation={6}>
      <Typography children="Floyd Miles" variant="h4" />
      <Stack direction={{ sm: "row", xs: "column" }} spacing={{ sm: 2, xs: 0 }}>
        <TypoWithIcon icon={<PersonIcon />} text="Jea Fisher" />
        <TypoWithIcon icon={<QueryBuilderIcon />} text="2:55" />
        <TypoWithIcon icon={<QueryBuilderIcon />} text="1 Hour 30 Minutes" />
      </Stack>
      <Box>
        <TypoWithIcon icon={<ReceiptIcon />} text="  Add notes" />
      </Box>
      <Stack direction={{ sm: "row", xs: "column" }} justifyContent={"space-between"} alignItems={{ sm: "flex-end" }}>
        <Stack direction="row" spacing={2}>
          <Typography>
            Fee
            <br />
            <Box component="span" sx={{ fontWeight: "bold" }}>
              1%
            </Box>
          </Typography>
          <Typography>
            Source
            <br />
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Direct
            </Box>
          </Typography>
        </Stack>
        <Typography>Booked On 18/September/2023</Typography>
      </Stack>
    </Paper>
  );
};
