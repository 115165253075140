import { forwardRef } from "react";
import React from "react";

import { Grid, Box } from "@mui/material";

import { CELL_HEIGHT, borderConfig } from "../../constants";

const CellWrapper = forwardRef(({ children, styles, ...props }, ref) => {
  return (
    <Grid
      item
      ref={ref}
      sx={{ minHeight: CELL_HEIGHT, minWidth: "50px", display: "flex", justifyContent: "center", alignItems: "center", ...borderConfig, ...styles }}
      {...props}
    >
      <Box width={"100%"}>{children}</Box>
    </Grid>
  );
});

export default CellWrapper;
