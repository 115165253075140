import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

export const EmployeeContext = createContext("Initial Value");
let data = {
  employeeData: [],
};

export const EmployeeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return <EmployeeContext.Provider value={{ state, dispatch }}>{children}</EmployeeContext.Provider>;
};
