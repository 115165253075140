import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Utils from "src/utils/utils";
import { TimePicker } from "@mui/x-date-pickers";
import { FormControlLabel, Grid, Radio, RadioGroup, Stack } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function SevenDayTimeSelector({ data, onChange = () => { }, requestDisableButton, setFormData = () => { }, formErrors }) {
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleStartTimeChange = (newStartTime, eachDay) => {
    const unixStartTime = moment(newStartTime).unix();
    setFormData((prev) => {
      const modifiedData = prev?.timings?.map((eachItem) => {
        if (eachItem.day === eachDay.day) {
          // requestDisableButton(true);
          return { ...eachItem, startTime: unixStartTime };
        } else {
          requestDisableButton(false);
        }
        return eachItem;
      });
      return { ...prev, timings: modifiedData };
    });
  };

  const handleEndTimeChange = (newStartTime, eachDay) => {
    const unixEndTime = moment(newStartTime).unix();
    setFormData((prev) => {
      const modifiedData = prev?.timings?.map((eachItem) => {
        if (eachItem?.day === eachDay?.day) {
          return { ...eachItem, endTime: unixEndTime };
        }
        return eachItem;
      });
      return { ...prev, timings: modifiedData };
    });
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
      <Box sx={{ minWidth: "150px" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {data.map((each, key) => (
            <Tab
              key={key}
              label={
                <>
                  {Utils.capitalize(each.day)}
                  <Typography>
                    {each.close
                      ? "Off"
                      : (each.startTime ? moment.unix(each.startTime).format("HH:mm") : "0") +
                      "-" +
                      (each.endTime ? moment.unix(each.endTime).format("HH:mm") : "0")}
                  </Typography>
                </>
              }
              {...a11yProps(key)}
            />
          ))}
        </Tabs>
      </Box>
      <Stack>
        {data.map((eachDay, index) => (
          <TabPanel value={value} index={index}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  value={eachDay.close ? "true" : "false"}
                  onChange={(e) =>
                    setFormData((prev) => {
                      const modifiedData = prev?.timings?.map((eachItem) => {
                        if (eachItem.day === eachDay.day) {
                          return {
                            ...eachItem,
                            close: e.target.value === "true" ? true : false,
                            endTime: e.target.value === "true" ? null : 1701702000,
                            startTime: e.target.value === "true" ? null : 1701666000,
                          };
                        }
                        return eachItem;
                      });
                      return { ...prev, timings: modifiedData };
                    })
                  }
                >
                  <FormControlLabel value={false} control={<Radio />} label="Open" />
                  <FormControlLabel value={true} control={<Radio />} label="Close" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <TimePicker
                  label="From"
                  value={Boolean(eachDay.startTime) ? moment(eachDay.startTime * 1000) : null}
                  onChange={(e) => handleStartTimeChange(e, eachDay)}
                  disabled={eachDay.close}
                  ampm={false}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                    },
                  }}
                  maxTime={Boolean(eachDay.endTime) ? moment(eachDay.endTime * 1000) : null}
                />
              </Grid>
              <Grid item xs={12}>
                <TimePicker
                  label="To"
                  value={Boolean(eachDay.endTime) ? moment(eachDay.endTime * 1000) : null}
                  onChange={(e) => handleEndTimeChange(e, eachDay)}
                  disabled={eachDay.close || !Boolean(eachDay.startTime)}
                  ampm={false}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                    },
                  }}
                  minTime={Boolean(eachDay.startTime) ? moment(eachDay.startTime * 1000) : null}
                />
              </Grid>
            </Grid>
            {/* Display error messages  */}
            {(formErrors && Array.isArray(formErrors)) && formErrors.map(error => {
              if (error) {
                return <Typography key={error?.message} color="error">{error?.message}</Typography>
              }
              return null;
            })}
          </TabPanel>
        ))}
      </Stack>
    </Box>
  );
}

const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
