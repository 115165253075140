export const BORDER_HEIGHT = 1;
export const MULTI_DAY_EVENT_HEIGHT = 28;
export const MONTH_NUMBER_HEIGHT = 27;
export const CELL_HEIGHT = 50;
export const MONTH_CELL_HEIGHT = 120;
export const CELL_PADDING_MUI = 1;
export const CELL_PADDING = "6px 8px";
export const CELL_PADDING_X = "6px";
export const CELL_PADDING_Y = "8px";
export const DATE_FORMATE = "";
export const DATE_FORMATE_ONLY = "YYYY-DD-MM";
export const TIME_FORMATE_ONLY = "HH:mm:ss";
export const CELL_BORDER_RADIUS = "3px";

export const TYPE_UNASSIGNED = "unassigned_shift";
export const TYPE_AVAILABLE = "available_shift";
export const TYPE_NORMAL = "shift";

export const mode = "production";

export const borderConfig = {
  border: "1px solid rgba(0, 0, 0, 0.08)",
};
