import apiManager from "src/services/api-manger";

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA": {
      return { ...state, MenuData: action.payload };
    }
    case "SET_SCREEN_TYPE": {
      return { ...state, packageScreen: action.payload };
    }
    default: {
      return state;
    }
  }
};
