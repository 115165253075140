import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TableCell, TableRow, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { ContainerWithTopHeading, ImagePicker, InputField, Header, CenterSubmitBtn, PasswordInputField, SelectBox } from "@components";
import { useNavigate, useParams } from "react-router-dom";
import { MainGridRowSpacing, inputGridSpacing } from "../../../constant";
import { PrimaryLayout, EmployeeLayout } from "../../../layouts";
import apiManager from "../../../services/api-manger";
import { useSnackbar } from "notistack";
import { helper, rules } from "src/validations";
import { setLoader } from "src/store/reducer";
import { useDispatch } from "react-redux";

const AddEmployee = () => {
  const { employeeID, saloonID } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({});
  const [showBtn, setShowBtn] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    saloonId: saloonID,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    role: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setShowBtn(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(setLoader(true));
    setFormErrors({});
    const result = await helper(formData, rules.EmployeeRules());
    if (result) {
      setFormErrors(result);
      dispatch(setLoader(false));
      return;
    }
    const method = employeeID ? "put" : "post";
    const path = method === "put" ? `employees/update/${employeeID}` : `employees/create`;
    try {
      const { data } = await apiManager({ method, path, params: formData });
      enqueueSnackbar(data?.message, { variant: "success" });
      navigate(`/saloon/${saloonID}/staff_members/${data?.data?._id}`);
    } catch (error) {
      setFormErrors(error?.response?.data?.data);
      enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
    } finally {
      setIsLoading(false);
      dispatch(setLoader(false));
    }
  };

  return (
    <>
      <EmployeeLayout>
        <Header
          headerTitle={employee?.firstName && employee?.lastName ? `${employee?.firstName} ${employee?.lastName}` : "Add Employee"}
          headerTitleIcon={<PersonAddIcon />}
        />
        <PrimaryLayout>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid container spacing={MainGridRowSpacing}>
              {/* Logo and Social Accounts */}
              <Grid item xs={12}>
                <ContainerWithTopHeading title={"Personal Information"} centerItems={false} RightBox={true}>
                  <Grid container sx={{ height: "100%" }} spacing={MainGridRowSpacing}>
                    <Grid item lg={3} xs={12} display="flex" justifyContent="center" alignItems="center">
                      <Stack sx={{ width: "100%" }}>
                        <Typography children="Upload Photo" variant="h6" sx={{ my: 2 }} />
                        <ImagePicker width="100%" />
                      </Stack>
                    </Grid>
                    <Grid item lg={9} xs={12} spacing={inputGridSpacing} container justifyContent="center">
                      <Grid item xs={6}>
                        <InputField
                          label="FIRST NAME"
                          fullWidth={true}
                          required
                          size="large"
                          type="text"
                          name={"firstName"}
                          value={formData.firstName}
                          error={formErrors?.firstName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputField
                          label="LAST NAME"
                          fullWidth={true}
                          required
                          size="large"
                          type="text"
                          name={"lastName"}
                          value={formData.lastName}
                          error={formErrors?.lastName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          label="PHONE NUMBER"
                          fullWidth={true}
                          required
                          size="large"
                          type="number"
                          name={"mobile"}
                          value={formData.mobile}
                          error={formErrors?.mobile}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputField
                          label="EMAIL ADDRESS"
                          fullWidth={true}
                          required
                          size="large"
                          type="email"
                          name={"email"}
                          value={formData.email}
                          error={formErrors?.email}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <PasswordInputField
                          label="PASSWORD"
                          fullWidth={true}
                          size="large"
                          name={"password"}
                          value={formData.password}
                          error={formErrors?.password}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectBox
                          label="ROLE"
                          size="large"
                          required
                          name="role"
                          value={formData.role}
                          error={formErrors?.role}
                          onChange={handleChange}
                          items={[
                            {
                              label: "Employee",
                              value: "employee",
                            },
                            {
                              label: "Receptionist",
                              value: "receptionist",
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </ContainerWithTopHeading>
              </Grid>

              {/* Salon Operating Timing */}
            </Grid>
            {showBtn && (
              <CenterSubmitBtn variant="contained" type="submit" key={employeeID} isLoading={isLoading}>
                {employeeID ? "Update" : "Create"}
              </CenterSubmitBtn>
            )}
          </Box>
        </PrimaryLayout>
      </EmployeeLayout>
    </>
  );
};

export default AddEmployee;
