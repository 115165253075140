import React, { useEffect, useRef } from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { Header } from "@components";
import { PrimaryLayout, ClientLayout } from "../../../layouts";
import { AddProfile } from "../../../assets";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Link, useParams } from "react-router-dom";

const AddClient = () => {
  const { saloonID } = useParams();
  const clientLayoutRef = useRef();

  useEffect(() => {
    // Call the getClients function from the ClientLayout component
    if (clientLayoutRef.current) {
      clientLayoutRef.current.getClients();
    }
  }, [clientLayoutRef]);
  return (
    <>
      <ClientLayout ref={clientLayoutRef}>
        <Header />
        <PrimaryLayout>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Avatar sx={{ width: "20%", height: "10%" }} variant="square" src={AddProfile} />
            <Typography sx={{ fontSize: 25, color: "grey.300" }}>Select a Client from side menu or, Create a new one</Typography>
            <Box component={Link} to={`/saloon/${saloonID}/client`}>
              <Button variant="contained" startIcon={<PersonAddIcon />}>
                Create Client
              </Button>
            </Box>
          </Box>
        </PrimaryLayout>
      </ClientLayout>
    </>
  );
};

export default AddClient;
