import React from "react";
import { Box, InputLabel, FormHelperText, TextField, FormControl } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

const InputField = React.forwardRef(
  (
    {
      labelTop = "",
      label = "",
      styles,
      error = "",
      helperText = "",
      icon,
      fullWidth = true,
      value: propsValue,
      onChange: propsOnChange,
      onBlur: propsOnBlur,
      size = "large",
      min = "",
      max = "",
      maxLength,
      minLength,
      ...props
    },
    ref
  ) => {
    const [stateValue, setStateValue] = React.useState("");
    const value = propsValue !== undefined ? propsValue : stateValue;
    const _id = `myInput__${uuidv4()}`;

    const onChange = (event) => {
      if (propsOnChange) {
        propsOnChange(event);
      } else {
        setStateValue(event.target.value);
      }
    };

    const onBlur = (event) => {
      if (propsOnBlur) {
        propsOnBlur(event);
      } else {
        setStateValue(event.target.value);
      }
    };

    const printError = () => {
      if (error !== "") {
        return <FormHelperText sx={{ color: "red", mt: "0 !important" }}>{error}</FormHelperText>;
      }
    };

    const printHelperText = () => {
      if (helperText !== "") {
        return (
          <FormHelperText
            sx={{
              mt: "0 !important",
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: "#6C6A6A",
              fontWeight: 500,
            }}
          >
            {helperText}
            {icon}
          </FormHelperText>
        );
      }
    };
    // console.log("🚀 ~ file: InputFiled.js:29 ~ value:", value)

    return (
      <FormControl sx={styles} fullWidth={fullWidth}>
        {labelTop && (
          <InputLabel
            htmlFor={_id}
            sx={{
              marginBottom: "5px",
              color: "#000",
            }}
          >
            {labelTop}
          </InputLabel>
        )}
        <TextField
          id={_id}
          inputRef={ref}
          error={Boolean(error !== "")}
          label={label}
          fullWidth={fullWidth}
          size={size}
          autoComplete="off"
          value={value}
          onChange={(e) => {
            var value = Number(e.target.value);
            if (max && value !== max && value >= max) return;
            onChange(e);
          }}
          onBlur={onBlur}
          inputProps={{
            min: min,
            // max: max,
            // ...(max && { max }),
            ...(maxLength && { maxLength }),
            ...(minLength && { minLength }),
          }}
          {...props}
        />
        {printHelperText()}
        {printError()}
      </FormControl>
    );
  }
);

export default InputField;
