import { Box } from "@mui/material";

import Nav from "./components/nav/nav";
import useStore from "./hooks/useStore";
import { Wrapper } from "./styles/styles";
import Day from "./views/Day";
import FormDialog from "./views/FormDialog";
import Month from "./views/Month";
import Week from "./views/Week";

const SchedulerComponent = (props) => {
  const { dialog, view, views } = useStore();
  return (
    <Wrapper elevation={6} className="schedulerMainBox">
      <Nav />
      <Box sx={{ position: "relative", overflow: "auto" }}>
        {views?.includes("day") && view === "day" && <Day />}
        {views?.includes("month") && view === "month" && <Month />}
        {views?.includes("week") && view === "week" && <Week />}
      </Box>

      {/* {dialog && <FormDialog />} */}
    </Wrapper>
  );
};

export default SchedulerComponent;
