import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EastIcon from "@mui/icons-material/East";
import { Link, useNavigate } from "react-router-dom";
import { InputField, PasswordInputField } from "@components";
import { useDispatch } from "react-redux";
import apiManager from "../../../services/api-manger";
import { setLoader, setUser } from "../../../store/reducer";
import { useSnackbar } from "notistack";

export default function Login() {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ email: "", password: "", role: "owner" });
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoader(true))
    try {
      let { data } = await apiManager({ method: "post", path: "login", params: { ...formData, email: formData.email.toLowerCase() }, });
      dispatch(setUser(data?.data?.userData));
      localStorage.setItem(process.env.REACT_APP_TOKEN, data?.data?.token);
      enqueueSnackbar(data?.message, { variant: 'success' });
      navigate("/");
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      setFormErrors(error?.response?.data?.data);
    } finally {
      dispatch(setLoader(false));
    }
  };

  return (
    <>
      <Typography
        component="h1"
        variant="h5"
        my={1}
        align="center"
      >
        Login to your account
      </Typography>
      <Box
        component="form"
        autoComplete="off"
        autoCapitalize="off"
        onSubmit={handleSubmit}
        sx={{ mt: 1, maxWidth: "500px" }}
      >
        <InputField
          margin="normal"
          label="Email Address"
          type="email"
          name="email"
          required
          value={formData.email}
          error={formErrors?.email}
          onChange={handleInputChange}
        />
        <PasswordInputField
          margin="normal"
          required
          name="password"
          label="Password"
          placeholder="xxxxxx"
          id="password"
          autoComplete="current-password"
          error={formErrors?.password}
          value={formData.password}
          onChange={handleInputChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          size="large"
          endIcon={<EastIcon />}
        >
          Sign In
        </Button>
        <Button
          fullWidth
          component={Link}
          to="/forgetPass"
        >
          Forget Pass?
        </Button>
      </Box>
    </>
  );
}