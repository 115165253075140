import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

export const SaloonContext = createContext("Initial Value");
let data = {
  saloonData: [],
};

export const SaloonContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return <SaloonContext.Provider value={{ state, dispatch }}>{children}</SaloonContext.Provider>;
};
