import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

export const ClientContext = createContext("Initial Value");
let data = {
  clientData: [],
};

export const ClientContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, data);
  return <ClientContext.Provider value={{ state, dispatch }}>{children}</ClientContext.Provider>;
};
