import React, { useState } from "react";
import { Box, Drawer, Toolbar, List, Divider, ListItemIcon, ListItemText, useMediaQuery, ListItemButton, IconButton, Stack, Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { RIGHT_DRAWER_WIDTH, headerHeight } from "../../constant";
import { nvaLinks } from "./data";
import { closeDrawer, logoutUser } from "../../store/reducer";

export default function AdminRightDrawer({ routes, flag }) {

  const { saloonID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:900px)");
  const { openRightDrawer } = useSelector((state) => state.appReducer);
  const currentRoute = window.location.pathname;

  const logOut = async () => {
    dispatch(closeDrawer("right"));
    localStorage.removeItem(process.env.REACT_APP_TOKEN);
    dispatch(logoutUser());
  };

  const [openCollapse, setOpenCollapse] = useState({});

  const handleCollapse = (label) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const renderLinks = (links) => (
    <List sx={drawerStyles.list}>
      {links.map((link, i) => (
        <React.Fragment key={i}>
          {link.children ? (
            <>
              <ListItemButton onClick={() => handleCollapse(link.label)}>
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.label} />
                {openCollapse[link.label] ? (<ExpandLess />) : (<ExpandMore />)}
              </ListItemButton>
              <Collapse
                in={openCollapse[link.label]}
                timeout="auto"
                unmountOnExit
              >
                {link.children.map((child, j) => (
                  <ListItemButton
                    key={j}
                    component={Link}
                    to={`/saloon/${saloonID}${child.routeName}`}
                    selected={
                      currentRoute ===
                      `/saloon/${saloonID}${child.routeName}`
                    }
                    onClick={() => dispatch(closeDrawer("right"))}
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>{child.icon}</ListItemIcon>
                    <ListItemText primary={child.label} />
                  </ListItemButton>
                ))}
              </Collapse>
            </>
          ) : (
            <ListItemButton
              key={i}
              component={Link}
              to={link.route === '/profile' ? `/profile` : `/saloon/${saloonID}${link.route}`}
              selected={link.route === '/profile' ? currentRoute === `/profile` : currentRoute === `/saloon/${saloonID}${link.route}`}
              // to={link.route === '/' ? `/saloonProfile/${saloonID}` : `/saloon/${saloonID}${link.route}`}
              // selected={link.route === '/' ? currentRoute === `/saloonProfile/${saloonID}` : currentRoute === `/saloon/${saloonID}${link.route}`}
              onClick={() => dispatch(closeDrawer("right"))}
            >
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText primary={link.label} />
            </ListItemButton>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <>
      <Drawer
        sx={drawerStyles.drawer}
        anchor="right"
        open={openRightDrawer}
        variant="persistent"
      >
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <IconButton
            align="left"
            onClick={() => dispatch(closeDrawer("right"))}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider sx={drawerStyles.divider} />
        <Stack sx={{ justifyContent: "space-between", height: "100%" }}>
          <Box>{renderLinks(nvaLinks)}</Box>
          <Box mb={10}>
            <ListItemButton onClick={logOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"logout"} />
            </ListItemButton>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
}

const drawerStyles = {
  drawer: {
    width: RIGHT_DRAWER_WIDTH,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: RIGHT_DRAWER_WIDTH,
      boxSizing: "border-box",
      boxShadow: " 7px 4px 44px 0px rgba(0, 0, 0, 0.04)",
      zIndex: 9999999,
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: headerHeight,
  },
  logoBox: {
    maxWidth: "160px",
  },
  logoImg: {
    height: "100%",
    width: "100%",
  },
  divider: {
    mb: 2,
  },
  list: {
    "& .MuiListItemIcon-root": {
      color: "#000",
    },
  },
};
