import moment from "moment";

export default class Utils {
  static generateId() {
    return Math.ceil(Math.random() * 10000000);
  }

  static matchPassword(pass, c_pass) {
    if (!!pass && c_pass !== pass) {
      return true;
    }
    return false;
  }

  static isValidEmail(value) {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    if (regex.test(value)) {
      return true;
    }
    return false;
  }

  static isNumber(str) {
    if (/^[0-9\b]+$/.test(str)) {
      return true;
    }
    return false;
  }

  static hasOnlyNonNumericCharacters(inputString) {
    const regex = /^[^0-9]+$/;
    if (regex.test(inputString)) {
      return true;
    }
    return false;
  }

  static inRange(value, max) {
    if (value.length < max) {
      return true;
    }
    return false;
  }

  static handleBandID(str, value) {
    if (value.length < str.length) {
      return str
        .replace(/[^\da-zA-Z]/g, "")
        .replace(/(.{2})/g, "$1:")
        .trim();
    }
    return str;
  }

  static capitalize(str) {
    if (!!str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return "";
  }

  static getRowNumber(current_page, per_page, index) {
    return (current_page - 1) * per_page + index + 1;
  }

  static checkWindowWidth(width) {
    const mediaQuery = window.matchMedia(`(max-width: ${width}px)`);
    // Function to update the result based on window size changes
    function updateResult() {
      if (mediaQuery.matches) {
        return true;
      } else {
        return false;
      }
    }
    // Initial check and set up event listener
    updateResult();
    mediaQuery.addListener(updateResult);
  }

  static removeUnderscore(str) {
    if (!!str) {
      return str.replace(/_/g, " ");
    }
    return "";
  }

  static formatWithSuffix(number = 0) {
    let num = Number(number);
    if (num % 1 !== 0 && num < 1000) {
      num = num.toString();
      const decimalIndex = num.indexOf(".");
      if (decimalIndex !== -1) {
        return (num = num.slice(0, decimalIndex + 2)); // Extract only two decimal places
      }
    }
    if (num >= 1000000) {
      num = num / 1000000;
      num = num.toString();
      const decimalIndex = num.indexOf(".");
      if (decimalIndex !== -1) {
        num = num.slice(0, decimalIndex + 3);
      }
      return num + "M";
    } else if (num >= 1000) {
      num = num / 1000;
      num = num.toString();
      const decimalIndex = num.indexOf(".");
      if (decimalIndex !== -1) {
        num = num.slice(0, decimalIndex + 3);
      }
      return num + "K";
    }
    return num;
  }

  static acceptCSV(str) {
    return ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
  }
  static limitStringWithEllipsis(str, limit) {
    if (str.length <= limit) {
      return str;
    }

    return str.substring(0, limit - 3) + "...";
  }

  static formatDuration = (duration) => {
    const durationMoment = moment.duration(duration, "seconds");

    if (duration < 60) {
      return `${durationMoment.seconds()}s`;
    } else {
      return `${durationMoment.minutes()}m`;
    }
  };

  static convertMsToTime(milliseconds) {
    const duration = moment.duration(milliseconds);
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds()) % 60;

    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  }

  static calculateDiscountPercentage = (originalPrice, discountedPrice) => {
    if (originalPrice > 0 && discountedPrice > 0) {
      const discount = originalPrice - discountedPrice;
      const discountPercentage = (discount / originalPrice) * 100;

      // Check if the discountPercentage is a whole number
      return discountPercentage % 1 === 0 ? discountPercentage.toFixed(0) : discountPercentage.toFixed(2);
    } else {
      return 0; // handle invalid input
    }
  };

  static calculateTotalByKey = (items = [], key) => {
    if (!Array.isArray(items)) {
      throw new Error("Input is not an array");
    }

    if (typeof key !== "string") {
      throw new Error("Key must be a string");
    }

    // Use reduce to sum the values based on the specified key
    const total = items.reduce((sum, item) => {
      // Ensure each item is an object and has the specified key
      if (item && typeof item === "object" && key in item) {
        const value = parseFloat(item[key]);
        if (!isNaN(value)) {
          return sum + value;
        }
      }
      return sum;
    }, 0);

    return total.toFixed(2); // Round to 2 decimal places
  };
  static _formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return `${hours} h ${minutes % 60} m`;
    } else if (minutes > 0) {
      return `${minutes} m`;
    } else {
      return `${seconds} s`;
    }
  }
}
