import { AppBar, Box, Button, Stack, CircularProgress } from "@mui/material";
import React from "react";
import { DRAWER_WIDTH } from "src/constant";

const CenterSubmitBtn = ({ children, isLoading, ...rest }) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        top: "auto",
        bottom: 20,
        background: "transparent",
        width: { md: `calc(100% - ${DRAWER_WIDTH}px)`, xs: "100%" },
        ml: { md: `${DRAWER_WIDTH}px`, sm: "0" },
      }}
      elevation={0}
    >
      <Stack justifyContent="center" alignItems="center">
        <Box sx={{ width: "100%", maxWidth: "300px" }}>
          <Button
            {...rest}
            fullWidth
            size="large"
            sx={{
              boxSizing: "border-box",
              outline: "none",
              appearance: "none",
              cursor: "pointer",
              position: "relative",
              height: "40px",
              minWidth: "300px",
              padding: "11px 19px",
              borderRadius: "40px",
              lineHeight: "16px",
              fontSize: "1.2rem",
              border: "1px solid rgb(74, 147, 249)",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 10px 14px 0px",
              color: "rgb(255, 255, 255)",
            }}
          >
            {/* {isLoading ? <CircularProgress /> : children} */}
            {children}
          </Button>
        </Box>
      </Stack>
    </AppBar>
  );
};

export default CenterSubmitBtn;
