import * as React from 'react'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import apiManager from 'src/services/api-manger'

export default function SearchInputMultiSelect({ apiUrl, label, callBack, value, error }) {
  const [open, setOpen] = React.useState(false)
  const [record, setRecord] = React.useState([])
  const [loading, isLoading] = React.useState(false)

  const getData = async () => {
    try {
      isLoading(true)
      const apiResponse = await apiManager({
        method: 'get',
        path: apiUrl,
      })
      setRecord(apiResponse?.data?.data);
    } catch (error) {
    } finally {
      isLoading(false)
    }
  }

  return (
    <>
      <Autocomplete
        multiple
        options={record}
        getOptionLabel={(option) => option?.name}
        open={open}
        onChange={(event, newValue) => {
          callBack(newValue)
        }}
        value={value}
        onOpen={() => {
          getData()
          setOpen(true)
        }}
        loading={loading}
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        onClose={() => {
          setOpen(false)
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} error={Boolean(error)} helperText={error} />
        )}
      />
    </>
  )
}