import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PasswordInputField } from "@components";
import { useDispatch } from "react-redux";
import apiManager from "../../../services/api-manger";
import { setLoader } from "../../../store/reducer";
import { useSnackbar } from "notistack";

export default function ResetPass() {

    const { otpId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ otpId: otpId, newPassword: "", confirmPassword: "", role: "owner" });
    const [formErrors, setFormErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { newPassword, confirmPassword } = formData;
        // Password length validation
        setFormErrors({});
        if (newPassword.length < 6) {
            return setFormErrors((prev) => ({ ...prev, newPassword: 'Should be at least 6 characters long.', }));
        };
        // Password match validation
        if (newPassword !== confirmPassword) {
            return setFormErrors((prev) => ({ ...prev, confirmPassword: 'Passwords do not match.', }));
        };
        try {
            dispatch(setLoader(true))
            setFormErrors({})
            await apiManager({ method: 'post', path: 'restPassword', params: formData })
            enqueueSnackbar('Password reset successfully.', { variant: 'success' });
            navigate('/login');
        } catch (error) {
            if (error?.response?.status === 422) {
                setFormErrors(error?.response?.data?.response?.error);
            };
            if (error?.response?.status !== 422) {
                enqueueSnackbar(error?.response?.data?.error?.message, { variant: 'error' });
            } else {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            };
        } finally {
            dispatch(setLoader(false));
        };
    };

    return (
        <>
            <Typography component="h1" variant="h5" my={1} align="center">
                Reset Password
            </Typography>
            <Box
                component="form"
                autoComplete="off"
                autoCapitalize="off"
                onSubmit={handleSubmit}
                sx={{ mt: 1, maxWidth: "500px" }}
            >
                <PasswordInputField
                    margin="normal"
                    required
                    label="New Password"
                    id="newPassword"
                    placeholder="xxxxxx"
                    name="newPassword"
                    error={formErrors?.newPassword}
                    value={formData.newPassword}
                    onChange={handleInputChange}
                // helperText="Type your new password."
                />
                <PasswordInputField
                    margin="normal"
                    required
                    label="Confirm Password"
                    id="confirmPassword"
                    placeholder="xxxxxx"
                    name="confirmPassword"
                    error={formErrors?.confirmPassword}
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                // helperText="Confirm new password."
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    size="large"
                >
                    Submit
                </Button>
                <Button fullWidth component={Link} to="/login">Go back to login</Button>
            </Box>
        </>
    );
};