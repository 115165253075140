

import Cell from "./Cell";
import CellWrapper from "./CellWrapper";
import { useCellAttributes } from "../../hooks/useCellAttributes";
import Events from "../events/Events";

const RenderCel = ({ date, start, end, resourceParent, data, type, cellId, cellBgColor }) => {
  const props = useCellAttributes({ start, end, resources: resourceParent, type });
  return (
    <CellWrapper xs={12 / 8} p={0.5} styles={{ background: cellBgColor }}>
      <Cell
        droppableData={{
          id: cellId,
          data: {
            resourceId: resourceParent?._id,
            date,
            type,
          },
        }}
        cellProps={props}
      >
        <Events date={date} type={type} resourceParent={resourceParent} eventsData={data} />
      </Cell>
    </CellWrapper>
  );
};

export default RenderCel;
