import { InputField } from "@components";
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import moment from "moment";
import ServicesAutoComplete from "./ServicesAutoComplete";
import { useEffect, useState } from "react";
import { DeleteForever } from "@mui/icons-material";
import Utils from "src/utils/utils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import apiManager from "src/services/api-manger";

const AppointmentCreationDialog = ({ onCreate = () => {}, onRequestDelete = () => {}, data, employees, setData = () => {} }) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [formData, setFormData] = useState({});
  const [options, setOptions] = useState([]);

  const { user } = useSelector((state) => state.appReducer);
  const { saloonID } = useParams();

  const handleConfirm = async () => {
    const { fullName, email, mobileNumber, services, ...restData } = data;
    const params = {
      ...formData,
      services: selectedServices.map((each) => ({ service: each.serviceId, employee: each?.applicableToPerform[0]._id, duration: each?.duration })),
      ...restData,
      creatorId: user?._id,
      saloonId: saloonID,
      color: employees?.find((eachEmp) => eachEmp?._id === data?.resourceId)?.color,
    };
    onCreate(params);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getServicesData = async () => {
    try {
      const { data } = await apiManager({ method: "get", path: `service/all/modified/${saloonID}` });
      setOptions(data.data);
    } catch (error) {
      console.log("ParlourCrud getData error:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (!data) {
      setFormData({});
      setSelectedServices([]);
    } else {
      getServicesData();
      setFormData(data);
      setSelectedServices([]);
    }
  }, [data]);

  useEffect(() => {
    if (options.length && !selectedServices.length) {
      const myData = options.filter((each) =>
        data?.services?.some((eachData) => eachData?.service?._id === each.serviceId || eachData.service === each.serviceId)
      );
      console.log("🚀 ~ useEffect ~ myData:", data?.services);
      setSelectedServices(myData);
    }
  }, [data,options]);

  return (
    <Dialog
      open={Boolean(data)}
      onClose={() => {
        setData(false);
        setFormData({});
        setSelectedServices([]);
      }}
    >
      <DialogTitle>
        Create Appointment
        <Typography>{moment(data?.start).format("ddd d - H:MM a")}</Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "10px !important" }}>
        <Grid container spacing={1}>
          <Grid item xs={12 / 2}>
            <InputField
              label="Full name"
              required
              fullWidth={true}
              type="text"
              size="large"
              name="fullName"
              value={formData?.fullName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12 / 2}>
            <InputField label="Email" fullWidth={true} type="email" size="large" name="email" value={formData?.email} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12 / 2}>
            <InputField
              label="Phone number"
              fullWidth={true}
              type="number"
              size="large"
              name="mobileNumber"
              value={formData?.mobileNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12 / 2}>
            <ServicesAutoComplete
              handleSelected={(event, newValue) => setSelectedServices(newValue)}
              fetchData={data?.edit}
              value={selectedServices}
              data={data}
              options={options}
              getOptions={getServicesData}
            />
          </Grid>
          <Grid container>
            {selectedServices.map((service, index) => (
              <Grid item xs={12}>
                <ServiceCard
                  service={service}
                  key={index}
                  onDelete={(data) => {
                    setSelectedServices((prev) => prev.filter((each) => each.serviceId !== data.serviceId));
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {data?.edit && (
          <Button
            onClick={() => {
              onRequestDelete(data);
              setFormData({});
              setSelectedServices([]);
            }}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        )}
        <Button
          onClick={() => {
            setData(false);
            setFormData({});
            setSelectedServices([]);
          }}
          variant="contained"
          color="error"
        >
          Cancel
        </Button>

        <Button onClick={handleConfirm} variant="contained">
          {data?.edit ? "Update" : "Create"}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentCreationDialog;

const ServiceCard = ({ service, onDelete = () => {} }) => {
  return (
    <Card sx={{ my: 1, width: "100%" }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction={"row"} justifyContent="space-between" alignItems="center">
              <Typography variant="h5" component="h2">
                {service?.parentServiceName ? service?.parentServiceName : service?.name}
              </Typography>
              <IconButton color="error" onClick={() => onDelete(service)}>
                <DeleteForever />
              </IconButton>
            </Stack>
          </Grid>
          {service?.name && (
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                {service?.parentServiceName} ({service?.name})
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Price: {service?.price}$
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              Duration: {Utils._formatDuration(service?.duration)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              With: {service?.applicableToPerform && service?.applicableToPerform[0]?.employee?.firstName}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
