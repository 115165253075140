import { AppBar, Button, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Select, Toolbar, Typography } from "@mui/material";

import DayDateSelector from "./dayDateSelector";
import MonthDateSelector from "./monthDateSelector";
import WeekDateSelector from "./WeekDateSelector";
import { mode } from "../../constants";
import useStore from "../../hooks/useStore";

const Nav = () => {
  const { view, handleState, day, views } = useStore();

  return (
    <AppBar position="static" sx={{ background: "white" }} elevation={0}>
      <Toolbar>
        <Grid container my={2}>
          <Grid item sm={4} xs={12}>
            <Typography variant="h4" color="black"></Typography>
          </Grid>
          <Grid item sm={4} xs={12}>
            {views?.includes("week") && view === "week" && <WeekDateSelector />}
            {views?.includes("month") && view === "month" && <MonthDateSelector />}
            {views?.includes("day") && view === "day" && <DayDateSelector />}
          </Grid>

          <Grid item container sm={4} xs={12} align="right" spacing={1}>
            {mode !== "production" && (
              <>
                <Grid item xs>
                  <FormControl fullWidth size="small">
                    <InputLabel id="employees">Number of hours</InputLabel>
                    <Select fullWidth onChange={(e) => handleState({ ...day, endHour: e.target.value }, "day")} value={String(day?.endHour)}>
                      {createArray(0, 23, 1).map((eachItem) => (
                        <MenuItem key={eachItem} value={Number(eachItem)}>
                          {eachItem}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth size="small">
                    <InputLabel id="employees">Number of steps</InputLabel>
                    <Select
                      fullWidth
                      label="Number of steps"
                      onChange={(e) => handleState({ ...day, step: e.target.value }, "day")}
                      value={String(day?.step)}
                    >
                      {createArray(0, 60, 5).map((eachItem) => (
                        <MenuItem key={eachItem} value={eachItem}>
                          {eachItem}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth size="small">
                    <InputLabel id="employees">Event Resize Interval</InputLabel>
                    <Select
                      fullWidth
                      label="Number of steps"
                      onChange={(e) => handleState({ ...day, eventResizeInterval: e.target.value }, "day")}
                      value={String(day?.eventResizeInterval)}
                    >
                      {createArray(0, 60, 1).map((eachItem) => (
                        <MenuItem key={eachItem} value={eachItem}>
                          {eachItem}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs>
              <ButtonGroup aria-label="outlined primary button group">
                {views?.includes("week") && (
                  <Button variant={view === "week" ? "contained" : "outlined"} onClick={() => handleState("week", "view")}>
                    Week
                  </Button>
                )}
                {views?.includes("month") && (
                  <Button variant={view === "month" ? "contained" : "outlined"} onClick={() => handleState("month", "view")}>
                    Month
                  </Button>
                )}
                {views?.includes("day") && (
                  <Button variant={view === "day" ? "contained" : "outlined"} onClick={() => handleState("day", "view")}>
                    Day
                  </Button>
                )}
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;

function createArray(start, end, step = 1) {
  if (start > end) {
    throw new Error("Start value must be less than or equal to end value");
  }

  if (step <= 0) {
    throw new Error("Step value must be positive");
  }

  let result = [];
  for (let i = start; i <= end; i += step) {
    result.push(i);
  }
  return result;
}
