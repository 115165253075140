import { Box, CardActionArea, Stack, Typography } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useShowToast } from "../../helper/showToast";

const ImagePicker = ({ width = "300px", height = "200px", onRequestImage = () => { } }) => {
  const displayToast = useShowToast();

  const [preview, setPreview] = useState();

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        displayToast(errors[0].message, "error");
      });
    } else {
      setPreview(acceptedFiles);
      onRequestImage(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".svg", ".jpg"],
    },
  });
  return (
    <>
      <input {...getInputProps()} />
      <CardActionArea
        {...getRootProps()}
        component={"label"}
        sx={{
          maxWidth: width,
          width: "100%",
          height: height,
          border: 1,
          borderStyle: "dashed",
          borderColor: "rgba(210, 211, 211, 1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {!isDragActive && preview ? (
          <Box
            component={"img"}
            src={preview[0] ? URL.createObjectURL(preview[0]) : ""}
            alt={preview.name}
            sx={{
              maxWidth: width,
              width: "100%",
              height: height,
            }}
          />
        ) : (
          <Stack
            justifyContent={"center"}
            alignItems="center">
            <ControlPointIcon />
            <Typography variant="body1">
              {isDragActive ? "Drop Here" : "Drop your Logo file here"}
            </Typography>
            <Typography variant="body2">Browse file from your computer</Typography>
          </Stack>
        )}
      </CardActionArea>
    </>
  );
};

export default ImagePicker;
