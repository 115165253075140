import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from "react";
import { LeftDrawer } from "@components";
import { useSnackbar } from "notistack";
import apiManager from "../../services/api-manger";
import { Add } from "@mui/icons-material";
import GroupIcon from '@mui/icons-material/Group';
import { List, ListItem, ListItemButton, ListItemText, Skeleton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { ClientContext } from "src/context/ClientContext/Context";
import { setLoader } from "src/store/reducer";
import { useSelector } from "react-redux";

const ClientLayout = forwardRef(({ children }, ref) => {

  const { saloonInfo } = useSelector((state) => state.appReducer);
  let { state, dispatch } = useContext(ClientContext);
  const { enqueueSnackbar } = useSnackbar();
  const { saloonID, clientID } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  // Expose the getClients function through the ref
  useImperativeHandle(ref, () => ({
    getClients: async () => {
      try {
        if (!state?.clientData?.length) {
          setIsLoading(true);
          dispatch(setLoader(true));
        }
        let { data } = await apiManager({ method: "get", path: `client/get-all/${saloonID}` });
        // setRecords(data?.data);
        dispatch({
          type: "SET_DATA",
          payload: data?.data?.results || data?.data,
        });
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.data, { variant: "error" });
      } finally {
        dispatch(setLoader(false));
        setIsLoading(false);
      }
    },
  }));

  useEffect(() => {
    ref?.current?.getClients();
  }, [ref]);

  const renderClientLayout = () => {
    if (isLoading) {
      return <SkeletonList count={5} />;
    }
    if (!state?.clientData?.length) {
      return <Typography children="No record found" textAlign="center" variant="h5" />;
    }
    return (
      <List>
        {state?.clientData?.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={`/saloon/${saloonID}/client/${item?._id}`} selected={item._id === clientID}>
              <ListItemText primary={item?.fullName} secondary={`Gender (${item?.gender})`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <div>
      <LeftDrawer
        routes={[]}
        flag={true}
        showLogo={false}
        showDrawer={false}
        showButton={{ url: `/saloon/${saloonID}/client`, icon: <Add />, text: "Add Client" }}
        searchLabel={"Search Client"}
        drawerList={renderClientLayout()}
        showDetails={{
          icon: <GroupIcon />,
          title: "Client",
          subTitle: saloonInfo?.name || "Saloon Name",
        }}
      />
      {children}
    </div>
  );
});

const SkeletonList = ({ count }) => {
  const skeletonItems = Array.from({ length: count }, (_, index) => (
    <ListItem key={index}>
      <ListItemText primary={<Skeleton width={150} />} secondary={<Skeleton width={100} />} />
    </ListItem>
  ));

  return <List>{skeletonItems}</List>;
};

export default ClientLayout;
