import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchInput({ searchLabel }) {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        background: "#ECE6F0",
        borderRadius: "100vw",
      }}
      elevation={0}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={`${searchLabel ? searchLabel : "Search Google Maps"}`}
        inputProps={{ "aria-label": `${searchLabel ? searchLabel : "Search Google Maps"}` }}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
