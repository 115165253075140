import React from "react";

import { Avatar, Box, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

import CellWrapper from "./CellWrapper";

const ResourceInfoBox = ({ resource, show = true, bgColor, children }) => {
  return (
    <CellWrapper
      style={{
        minWidth: "250px",
        display: "flex",
        justifyContent: "center",
        background: bgColor,
        maxWidth: "250px",
      }}
    >
      {show && (
        <ListItem dense>
          <ListItemAvatar>
            <Avatar src={resource.img} />
          </ListItemAvatar>
          <ListItemText primary={resource.name} secondary="12pm - 10pm" />
        </ListItem>
      )}
      <Box>{children}</Box>
    </CellWrapper>
  );
};

export default ResourceInfoBox;
