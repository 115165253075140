import React from "react";
import "./style.css";
import { Backdrop } from "@mui/material";
const Loader = () => {
  return (
    <Backdrop sx={{ background: "#48484847", zIndex: (theme) => theme.zIndex.drawer + 9999999 }} open={true}>
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Backdrop>
  );
};

export default Loader;
