import moment from "moment";

import { TYPE_AVAILABLE, TYPE_UNASSIGNED } from "../constants";

export const getOneView = (state) => {
  if (state.month) {
    return "month";
  } else if (state.week) {
    return "week";
  } else if (state.day) {
    return "day";
  }
  throw new Error("No views were selected");
};

export const calcCellHeight = (tableHeight, hoursLength) => {
  return Math.max(tableHeight / hoursLength, 60);
};

export const isToday = (date) => {
  return moment(date).isSame(moment(), "day");
};

export const traversCrossingEvents = (todayEvents, event) => {
  return todayEvents.filter(
    (e) =>
      e.event_id !== event.event_id &&
      (moment(event.start).add(1, "minutes").isBetween(e.start, e.end) ||
        moment(event.end).subtract(1, "minutes").isBetween(e.start, e.end) ||
        moment(e.start).add(1, "minutes").isBetween(event.start, event.end) ||
        moment(e.end).subtract(1, "minutes").isBetween(event.start, event.end))
  );
};

export const differenceInDaysOmitTime = (start, end) => {
  const momentStart = moment(start).startOf("day");
  const momentEnd = moment(end).endOf("day").subtract(1, "second");
  return momentEnd.diff(momentStart, "days");
};

export const filterTodayEvents = (events, today, timeZone) => {
  const list = [];

  for (let i = 0; i < events.length; i++) {
    const event = events[i];

    if (!event.allDay && moment(today).isSame(event.start, "day") && !differenceInDaysOmitTime(event.start, event.end)) {
      list.push(event);
    }
  }

  return list;
};

export const calcMinuteHeight = (cellHeight, step) => {
  return Math.ceil(cellHeight) / step;
};

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getResourcedEvents = (events, resourceId) => {
  const filteredData = events?.filter((eachResource) => {
    if (eachResource?.resourceId === resourceId) {
      return {
        ...eachResource,
        color: eachResource?.color || getRandomColor(),
      };
    }
  });
  return filteredData;
};

export const sortArrayByStartDateTime = (array) => {
  return array.sort((a, b) => {
    const startDateTimeA = moment(a.start);
    const startDateTimeB = moment(b.start);

    // Use the difference between start and end times for comparison
    const durationA = moment.duration(moment(a.end).diff(startDateTimeA));
    const durationB = moment.duration(moment(b.end).diff(startDateTimeB));

    return durationA - durationB;
  });
};

export const filterDataByProvidedDate = (events, today) => {
  return events?.filter((eachEvent) => {
    const formattedDate = moment(eachEvent?.start);
    const sameDate = formattedDate?.isSame(today, "day");
    if (sameDate) {
      return eachEvent;
    }
  });
};

export const filterDataBySlotType = ({ eventsData, date }) => {
  return eventsData?.length ? filterDataByProvidedDate(eventsData, date) : [];
};

export const getParentWidth = (element) => {
  const parentElement = element?.parentElement;

  if (parentElement) {
    const parentWidth = parentElement.clientWidth;
    return parentWidth;
  } else {
    return null;
  }
};

export const calculatePositionAndWidth = (event, containerWidth, numberOfCells, cellDuration) => {
  const start = moment(event.start);
  const end = moment(event.end).add(cellDuration, "minutes"); // Add cell duration instead of 1 hour
  const dayStart = moment(start).startOf("day");
  const offsetMinutes = start.diff(dayStart, "minutes");

  const left = `${(offsetMinutes / (numberOfCells * cellDuration)) * 100}%`; // Adjust calculation for left position
  const leftPx = (parseFloat(left) / 100) * containerWidth;

  const durationMinutes = end.diff(start, "minutes");
  const widthPercentage = (durationMinutes / (numberOfCells * cellDuration)) * 100; // Adjust calculation for width percentage
  const widthPx = (widthPercentage / 100) * containerWidth;

  return { left, width: { percentage: `${widthPercentage}%`, px: `${widthPx}px` }, leftPx };
};

// export const calculatePositionAndWidth = (event, containerWidth, numberOfCells, cellDuration) => {
//   const start = moment(event.start);
//   const end = moment(event.end).add(1, "hour"); // Add cell duration instead of 1 hour
//   const dayStart = moment(start).startOf("day");
//   const offsetMinutes = start.diff(dayStart, "minutes");

//   const left = `${(offsetMinutes / (24 * 60)) * 100}%`; // Adjust calculation for left position
//   const leftPx = (parseFloat(left) / 100) * containerWidth;

//   const durationMinutes = end.diff(start, "minutes");
//   const widthPercentage = (durationMinutes / (24 * 60)) * 100; // Adjust calculation for width percentage
//   const widthPx = (widthPercentage / 100) * containerWidth;

//   return { left, width: { percentage: `${widthPercentage}%`, px: `${widthPx}px` }, leftPx };
// };

export const calculateWidthByTime = (dayWidth, durationMinutes, numberOfCells, cellDuration) => {
  const widthPercentage = (durationMinutes / (numberOfCells * cellDuration)) * 100;
  const widthPx = (widthPercentage / 100) * dayWidth;
  return widthPx;
};

export const findEventByDate = (date, events, key) => {
  return events.filter((eachEvent) => moment(eachEvent[key]).isSame(date));
};

export const generateDateRange = (start, end, activeEvent, step) => {
  let result = [];
  let current = moment(start);
  const endMoment = moment(end);
  const { start: activeEventStart, end: activeEventEnd } = activeEvent;
  const eventInDiff = moment.duration(moment(activeEventStart))?._data?.minutes;

  console.log("🚀 ~ generateDateRange ~ eventInDiff % step:", eventInDiff % step);

  let i = 0;
  while (current.isBefore(endMoment)) {
    let myDiff = moment.duration(endMoment.diff(current)).asMinutes();
    if (i === 0) {
      result.push(current.format(""));
    }

    let interval = myDiff > 60 ? 60 : myDiff;
    current.add(interval, "minutes");
    result.push(current.format(""));
    i++;
  }

  // result[0] = moment(result[0])
  //   .subtract(eventInDiff % step)
  //   .format();
  // result[result.length - 1] = moment(result[result.length - 1])
  //   .subtract(eventInDiff % step)
  //   .format();

  result = result.map((eachDate, i) => {
    if (i === 0) {
      return moment(eachDate)
        .subtract(eventInDiff % step, "m")
        .format();
    } else if (i === result.length - 1) {
      return moment(eachDate)
        .subtract(eventInDiff % step, "m")
        .format();
    }
    return eachDate;
  });

  return result;
};

// export const generateDateRange = (start, end, activeEvent) => {
//   let result = [];
//   let current = moment(start);
//   const endMoment = moment(end);
//   const { start: activeEventStart, end: activeEventEnd } = activeEvent;
//   const eventInDiff = moment.duration(moment(activeEventStart))?._data?.minutes;

//   let i = 0;
//   while (current.isBefore(endMoment)) {
//     let myDiff = moment.duration(endMoment.diff(current)).asMinutes();
//     if (i === 0) {
//       result.push(current.format(""));
//     }

//     let interval = myDiff > 60 ? 60 : myDiff;
//     current.add(interval, "minutes");
//     result.push(current.format(""));
//     i++;
//   }
//   result = result.map((eachDate, i) => {
//     if (i === 0 && eventInDiff === 30) {
//       return moment(eachDate).subtract(30, "m").format();
//     } else if (i === result.length - 1 && eventInDiff) {
//       return moment(eachDate).subtract(30, "m").format();
//     }
//     return eachDate;
//   });
//   return result;
// };

export const checkIsOver = (activeEvent, onOverCell, date) => {
  if (activeEvent) {
    const { start: activeEventStart, end: activeEventEnd } = activeEvent;
    const diffInDate = moment(activeEventEnd).diff(activeEventStart, "minutes");
    const dateWithDiff = moment(onOverCell).add(diffInDate, "minutes");
    const dateArray = generateDateRange(onOverCell, dateWithDiff, activeEvent);
    return dateArray.some(
      (eachDate) =>
        moment(eachDate).isSame(date) ||
        moment(eachDate).isSame(moment(date).subtract(30, "minutes")) ||
        moment(eachDate).isSame(moment(date).add(30, "minutes"))
    );
  }
};

export const filterEventsData = (data, startDate, endDate, resourceId, activeEvent) => {
  const filteredData = data?.filter((eachEvent) => {
    const formattedStartDate = moment(eachEvent?.start);
    const formattedEndDate = moment(eachEvent?.end);
    const resourceIdCondition = resourceId ? eachEvent.resourceId === resourceId : true;

    const matchStartWithEnd = startDate && endDate ? formattedStartDate.isSame(endDate) : true;
    const matchStartWithEndMin30 = startDate && endDate ? formattedStartDate.clone().subtract(30, "minute").isSame(endDate) : true;
    const matchEndWithStart = startDate && endDate ? formattedEndDate.isSame(startDate) : true;
    const matchStartWithStart = startDate && endDate ? formattedStartDate.isSame(startDate) : true;
    const matchEndWithEnd = startDate && endDate ? formattedEndDate.isSame(endDate) : true;

    const isWithinOrSame =
      (formattedStartDate.isSameOrAfter(startDate) && formattedStartDate.isSameOrBefore(endDate)) ||
      (formattedEndDate.isSameOrAfter(startDate) && formattedEndDate.isSameOrBefore(endDate));

    if (isWithinOrSame && activeEvent.event_id !== eachEvent.event_id) {
      return eachEvent;
    }

    // if (
    //   (matchStartWithEnd ||
    //     matchEndWithStart ||
    //     (matchEndWithEnd && matchStartWithStart) ||
    //     matchEndWithEnd ||
    //     matchStartWithStart ||
    //     matchStartWithEndMin30 ||
    //     isWithinOrSame) &&
    //   activeEvent.event_id !== eachEvent.event_id
    // ) {
    //   return eachEvent;
    // }
  });

  return filteredData || [];
};

// export function manipulateArrayForCellActive(start, end, arr, onOverCell, step) {
//   const durationInMinutes = moment.duration(moment(end).diff(moment(start))).asMinutes();

//   const uniqueSet = new Set();
//   const filteredArray = arr
//     .filter((item) => {
//       const dateString = item.id;
//       const timeRegex = /\d{2}:\d{2}:\d{2}/;
//       const match = dateString.match(timeRegex);
//       if (match) {
//         const time = match[0];
//         if (!uniqueSet.has(time)) {
//           uniqueSet.add(time);
//           // Return the desired value instead of returning true
//           return true;
//         }
//       }
//       return false;
//     })
//     .map((item) => item.data.droppableContainer.data.current.date);

//   // return filteredArray?.length ? filterDatesByMinutes(filteredArray, durationInMinutes, step) : [];

//   return filteredArray;
// }

export function manipulateArrayForCellActive(start, end, arr, onOverCell, step) {
  let durationInMinutes = moment.duration(moment(end).diff(moment(start))).asMinutes();
  const dateArry = [];

  const dateWithDiffAdded = moment(onOverCell).add(durationInMinutes, "minutes");

  // do {
  //   dateArry.push(moment(onOverCell).add(durationInMinutes, "minutes"));
  //   durationInMinutes -= step;
  // } while (durationInMinutes >= 0);

  return [];
}

export const eachMinuteOfInterval = (start, end, step = 1) => {
  const minutes = [];
  let current = moment(start).startOf("minute");

  while (current.isSameOrBefore(end)) {
    minutes.push(current.clone());
    current.add(step, "minutes");
  }

  return minutes;
};

function filterDatesByMinutes(dateArray, numMinutes, step) {
  let filteredDates = [];
  if (dateArray.length === 0) {
    return filteredDates;
  }

  // Parse date strings using Moment.js

  let diff = numMinutes + step;

  let i = 0;
  do {
    if (diff) {
      filteredDates.push(dateArray[i]);
    }
    i++;
    diff = Math.max(0, diff - step);
  } while (diff !== 0);

  return filteredDates;
}

export function isTimeInRange(timeToCheck, startTime, endTime, step = 25, startDiff, endDiff) {
  const adjustedStart = moment(timeToCheck).add(startDiff ? step : 0, "minutes");
  const adjustedEnd = moment(timeToCheck).subtract(endDiff ? step : 0, "minutes");

  return adjustedStart.isSameOrAfter(startTime) && adjustedEnd.isSameOrBefore(endTime);
}

export const convertRawDataInToResourceData = (data, resourceFiled) => {
  const resources = data.map((each) => ({
    name: each[resourceFiled.name],
    _id: each[resourceFiled.id],
    img: each[resourceFiled.img],
    color: each[resourceFiled.color],
  }));
  return resources;
};
